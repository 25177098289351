import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import AllImages from "../../Views/Dashboard/Components/Blogs/others/Images/Views/AllImages";
// import AllImages from '../../Views/Dashboard/Components/Images/All images'
// import AllImages from "../../Views/Dashboard/Components/Blogs/others/Images/Views/AllIimages";

const AllImagesModal = ({ showAllImagesModal, setShowAllImagesModal }) => {
  const handelCutModal = () => {
    setShowAllImagesModal(false);
  };
  return (
    <>
      <Modal
        isOpen={showAllImagesModal}
        size={"xl"}
        className="ImageUpoader-modal"
      >
        <ModalBody>
          <div className="closeButton">
            <button className="closemodal" onClick={handelCutModal}>
              X
            </button>
            <AllImages showAllImagesModal={showAllImagesModal} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AllImagesModal;
