import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AllUserByNutriIdComp from "../../../../../../../Components/AllUserByNutriIdComp";
import { getAllUserByNurtiIDApi } from "../../Constants/api";
import { NutriLiveHeading } from "../../Constants/table";

const AllUserByNutriId = () => {

  const { nutriId } = useParams()
  const [nutriUser, setNutriUser] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;

  const getAllUserByNurtiIDData = async (params) => {
    try {
      setIsLoading(true)
      const { data } = await getAllUserByNurtiIDApi(params)
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      let tempArr = data?.data?.map((item) => {
        return {
          userName: item?.userId?.firstName,
          email:item?.userId?.email,
          contact:item?.userId?.phoneNumber,
          appDate: item?.timeSlot?.date,
          endDate: item?.expDate,
          appTime: item?.timeSlot?.time,
          plan: item?.planId?.plan_description,
          price: item?.planId?.plan_display_price,
          title:item?.nutriId?.title,
          advisorName:item?.supportAdvisorId?.name,
          userId: item?._id,
          meetingLink : item?.meetingLink
        };
      });
      console.log(tempArr, 'tempArr');
      setNutriUser(tempArr)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }


  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllUserByNurtiIDData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  useEffect(() => {
    getAllUserByNurtiIDData({ id: nutriId, page: 1, limit: 10 })
  }, [])

  return (
    <>
      <AllUserByNutriIdComp
        tableData={nutriUser}
        tableHeading={NutriLiveHeading}
        handleTablePageChange={handleTablePageChange}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        nutriId={nutriId}
      />
    </>
  );
};

export default AllUserByNutriId;
