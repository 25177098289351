import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../Table"; 
import { Card, CardBody, Col, Row } from "reactstrap";

const EditUserMasterComp = ({
  tableData = [],
  pageHeading = "Master Class Users",
  tableHeading = [],
  handleTablePageChange,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  sessionsId
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  // const handleUserEdit = (userId) => {
  //   history.push("/dashboard/master-class/users/edit/?sessionId="  +
  //       sessionsId);
  // };

  // const viewContentFormatter = (cell, row) => {
  //   return (
  //     <div style={{ textAlign: "center" }} key={row.userId}>
  //       <i
  //         className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
  //         onClick={() => handleUserEdit(row.userId, row.sessionsId)}
  //       />
  //     </div>
  //   );
  // };

  useEffect(() => {
    tableHeading.push({
      text: "Action",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      // formatter: viewContentFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      const result = tableData.map((el, i) => {
        return {
          ...el,
          name: el?.FirstName,
          lastName:el?.LastName,
          email: el?.userEmail,
          contact: el?.userContact,
          role: el?.userRole,
          targetDate: el?.startDate?.slice(0, 10),
          time:el?.time?.slice(11, 16),
          sNo: i + 1 + (page - 1) * sizePerPage,
        };
      });
      setRowData(result);
    }
  }, [tableData]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditUserMasterComp;
