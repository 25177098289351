import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const QuoteInfo = ({ isQuoteInfoOpen, toggleQuoteInfo, quote, isPriority}) => {

  const handleClose = () => {
    toggleQuoteInfo();
  };

  return (
    <Modal size="lg" isOpen={isQuoteInfoOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Quote Information</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="9">
              <FormGroup>
                <Label htmlFor="quote">Quote</Label>
                <AvField
                  name="quote"
                  placeholder="Quote"
                  type="text"
                  className="form-control"
                  value={quote}
                  id="quote"
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="3" style={{ marginTop: "35px" }}>
              <FormGroup>
                <div className="custom-control custom-switch" dir="ltr">
                  <Input
                    type="checkbox"
                    className="custom-control-input"
                    checked={isPriority}
                    name="isPriority"
                    id="customSwitchDate"
                  ></Input>
                  <Label
                    className="custom-control-label"
                    htmlFor="customSwitchDate"
                  >
                    Special Quote
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default QuoteInfo;
