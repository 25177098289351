import axios from "axios";

import baseDomain, {
  dashboardApi,
  userAnalyticsDataApi,
  revenueAnalyticsDataApi,
} from "../../../../Constants/apiRoutes";

export const getDashboardDataReq = () => {
  return axios.get(`${baseDomain}${dashboardApi}`);
};

export const getDashboardDataByDateReq = (startDate, endDate) => {
  return axios.get(`${baseDomain}${dashboardApi}?startDate=${startDate}&endDate=${endDate}`);
};

export const getUserAnalyticsDataReq = ({ startDate, endDate }) => {
  return axios.get(
    `${baseDomain}${userAnalyticsDataApi}?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getRevenueAnalyticsDataReq = ({ startDate, endDate }) => {
  return axios.get(
    `${baseDomain}${revenueAnalyticsDataApi}?startDate=${startDate}&endDate=${endDate}`
  );
};
