export const getImgRatioHvW = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(this.height / this.width);
    };
    img.onerror = function (error) {
      console.error(error);
      reject("Problem while loading image");
    };
    img.src = file;
  });
