import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditAppointmentComp = ({
  setSelectedDate,
  selectedDate,
  setAdName,
  adName,
  setAdvisor,
  advisor,
  formData,
  setFormData,
  allTime,
  setAllTime,
  firstName,
  updateAppointment,
  setShowUnPublishAlert
}) => {
  const handleDate = (e) => {
    console.log(e);
    setSelectedDate(e);
    setFormData({
      ...formData,
      date: selectedDate.toString(),
    });
  };

  let advisorName =
    advisor &&
    advisor?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.name,
      };
    });

    let timeOptions = [
      {
        key: 0,
        value: "00",
        label: "12AM",
      },
      {
        key: 1,
        value: "01",
        label: "1AM",
      },
      {
        key: 2,
        value: "02",
        label: "2AM",
      },
      {
        key: 3,
        value: "03",
        label: "3AM",
      },
      {
        key: 4,
        value: "04",
        label: "4AM",
      },
      {
        key: 5,
        value: "05",
        label: "5AM",
      },
      {
        key: 6,
        value: "06",
        label: "6AM",
      },
      {
        key: 7,
        value: "07",
        label: "7AM",
      },
      {
        key: 8,
        value: "08",
        label: "8AM",
      },
      {
        key: 9,
        value: "09",
        label: "9AM",
      },
      {
        key: 10,
        value: "10",
        label: "10AM",
      },
      {
        key: 11,
        value: "11",
        label: "11AM",
      },
      {
        key: 12,
        value: "12",
        label: "12AM",
      },
      {
        key: 13,
        value: "13",
        label: "1PM",
      },
      {
        key: 14,
        value: "14",
        label: "2PM",
      },
      {
        key: 15,
        value: "15",
        label: "3PM",
      },
      {
        key: 16,
        value: "16",
        label: "4PM",
      },
      {
        key: 17,
        value: "17",
        label: "5PM",
      },
      {
        key: 18,
        value: "18",
        label: "6PM",
      },
      {
        key: 19,
        value: "19",
        label: "7PM",
      },
      {
        key: 20,
        value: "20",
        label: "8PM",
      },
      {
        key: 21,
        value: "21",
        label: "9PM",
      },
      {
        key: 22,
        value: "22",
        label: "10PM",
      },
      {
        key: 23,
        value: "23",
        label: "11PM",
      },
    ];
  return (
    <>
      <Row style={{height : '80vh'}}>
        <Col lg={12}>
          <Card>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Edit User Appointment</h3>
                </div>
              </Col>
            </Row>
            <CardBody>
              <AvForm className="form-horizontal" onValidSubmit={() => setShowUnPublishAlert(true)}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="firstName">User Name</Label>
                      <AvField
                        name="firstName"
                        placeholder="Enter name"
                        value={firstName}
                        className="form-control"
                        id="firstName"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="supportAdvisorId">Co-ordinator Name</Label>
                      <Select
                        value={adName}
                        isMulti={false}
                        onChange={(select) => setAdName(select)}
                        options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Co-ordinator Name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="date">Select Date</Label>
                      <DatePicker
                        type="number"
                        className="date_picker"
                        placeholderText="Select Date"
                        // selected={new Date(selectedDate)}
                        selected={selectedDate}
                        value={selectedDate}
                        form={formData}
                        setField={setFormData}
                        keyName="date"
                        dateFormat="dd/MM/yyyy"
                        // dateFormat="yyyy-MM-dd"
                        onChange={(e)=>handleDate(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="time">Time</Label>
                      <Select
                        value={allTime}
                        onChange={(select) => setAllTime(select)}
                        options={timeOptions}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        placeholder={allTime?.label}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  // disabled={!isDataUpdated ? true : false}
                  // onClick={updateAppointment}
                >
                  {"Save Changes"}
                </Button>
              </AvForm>
              <br />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditAppointmentComp;
