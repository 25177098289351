import React, { useLayoutEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

import { getGraphConfig } from "../../Analysis.helpers";

const AnalysisCardGraph = ({ percent, color }) => {
  const [graphSettings, setGraphSettings] = useState({
    series: null,
    options: null,
  });

  const { series, options } = graphSettings;

  useLayoutEffect(() => {
    const settings = getGraphConfig({ percent, color });
    setGraphSettings(settings);
  }, [percent, color]);

  return (
    <div className="mb-3">
      {series && options && (
        <div id="radialchart-1" className="apex-charts">
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height="60"
          />
        </div>
      )}
    </div>
  );
};

export default AnalysisCardGraph;
