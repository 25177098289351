import axios from "axios";

import baseDomain, {
  tagAPI,
  categoryAPI,
  subcategoryAPI,
  tagsBinAPI,
  categoryBinAPI,
  subcategoryBinAPI,
  restoreTagAPI,
  searchTagAPI,
  mediaTagCatAndSubCatAPI,
  searchMediaTagAPI,
  publishMediaAPI,
  allAudioVideo
} from "../../../../Constants/apiRoutes";

export const getAllTags = () => {
  return axios.get(`${baseDomain}${tagAPI}`);
};

export const searchMediaTag = (  text ) => {
  
  return axios.get(
    `${baseDomain}${searchMediaTagAPI}/${text.searchBar}?objectType=${text.objectType}`
  );
};

export const getMediaTags = ({ objectType, page, limit }) => {
  return axios.get(
    `${baseDomain}${mediaTagCatAndSubCatAPI}/get?objectType=${objectType}&page=${page}&limit=${limit}`
  );
};
export const getMediaMapping = ({ objectType, page, limit  }) => {
  return axios.get(
    `${baseDomain}${mediaTagCatAndSubCatAPI}/get?objectType=${objectType}&page=${page}&limit=${limit}`
  );
};
export const mediaMappingCreateApi = (catId, refIds)=>{
  console.log({"first":catId, "Second":refIds});
  return axios.put( `${baseDomain}${mediaTagCatAndSubCatAPI}/categorymapping/${catId}`,refIds)

}

export const getTagsBin = ({ page, limit }) => {
  // return axios.get(`${baseDomain}${tagsBinAPI}?page=${page}&limit=${limit}`);
};
export const getTagsUnPublished = ({ objectType, page, limit }) => {
  console.log({ first: objectType, second: page, third: limit });
  return axios.get(
    `${baseDomain}${mediaTagCatAndSubCatAPI}/bin?objectType=${objectType}&page=${page}&limit=${limit}`
  );
};

export const getCategoryBin = ({ page, limit }) => {
  return axios.get(
    `${baseDomain}${categoryBinAPI}?page=${page}&limit=${limit}`
  );
};

export const getSubcategoryBin = ({ page, limit }) => {
  return axios.get(
    `${baseDomain}${subcategoryBinAPI}?page=${page}&limit=${limit}`
  );
};

export const unPublishMedia = (tagID) => {
  return axios.delete(`${baseDomain}${mediaTagCatAndSubCatAPI}/${tagID}`);
};

export const publishMedia = (tagID) => {
  return axios.put(
    `${baseDomain}${mediaTagCatAndSubCatAPI}${publishMediaAPI}/${tagID}`
  );
};
// export const editApi = (id)=>{
//   console.log(id,"hagsdvchgaud")
//   return axios.patch(`${baseDomain}${mediaTagCatAndSubCatAPI}/${id}`);
// }


export const allMediaApi = () =>{
  return axios.get(
    `${baseDomain}${mediaTagCatAndSubCatAPI}`
  )
}