import React from "react";
import { Row, Col, Card, CardBody, Button, Label, FormGroup } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";

const EditTimeSlotsComp = ({
  formData,
  setFormData,
  allTime,
  setAllTime,
  allIsBooked,
  setAllIsBooked,
  selectedDate,
  setSelectedDate,
  addTimeNFree,
  timeAdvisorData,
  removeTimeNFree,
  onConfirm,
  // onCancel,
  setShowAlert,
  handleSubmitTimeSlots,
  setShowUnPublishAlert,
}) => {
  let freeOption = [
    {
      key: "true",
      value: true,
      label: "Not Available",
    },
    {
      key: "false",
      value: false,
      label: "Available",
    },
  ];

  let timeOptions = [
    {
      key: 0,
      value: '00',
      label: '12AM',
    },
    {
      key: 1,
      value: '01',
      label: '1AM',
    },
    {
      key: 2,
      value: '02',
      label: '2AM',
    },
    {
      key: 3,
      value: '03',
      label: '3AM',
    },
    {
      key: 4,
      value: '04',
      label: '4AM',
    },
    {
      key: 5,
      value: '05',
      label: '5AM',
    },
    {
      key: 6,
      value: '06',
      label: '6AM',
    },
    {
      key: 7,
      value: '07',
      label: '7AM',
    },
    {
      key: 8,
      value: '08',
      label: '8AM',
    },
    {
      key: 9,
      value: '09',
      label: '9AM',
    },
    {
      key: 10,
      value: '10',
      label: '10AM',
    },
    {
      key: 11,
      value: '11',
      label: '11AM',
    },
    {
      key: 12,
      value: '12',
      label: '12PM',
    },
    {
      key: 13,
      value: '13',
      label: '1PM',
    },
    {
      key: 14,
      value: '14',
      label: '2PM',
    },
    {
      key: 15,
      value: '15',
      label: '3PM',
    },
    {
      key: 16,
      value: '16',
      label: '4PM',
    }, {
      key: 17,
      value: '17',
      label: '5PM',
    },
    {
      key: 18,
      value: '18',
      label: '6PM',
    },
    {
      key: 19,
      value: '19',
      label: '7PM',
    },
    {
      key: 20,
      value: '20',
      label: '8PM',
    },
    {
      key: 21,
      value: '21',
      label: '9PM',
    },
    {
      key: 22,
      value: '22',
      label: '10PM',
    },
    {
      key: 23,
      value: '23',
      label: '11PM',
    }
  ];

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Edit Time Slot for Support Advisor</h3>
                  </div>
                </Col>
              </Row>

              <AvForm
                className="form-horizontal"
                onValidSubmit={() => setShowUnPublishAlert(true)}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="time">Time</Label>
                      <Select
                        value={allTime}
                        onChange={(select) => setAllTime(select)}
                        options={timeOptions}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        placeholder="Select Time"
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col>
                    <FormGroup>
                      <Label htmlFor="isBooked">Availability</Label>
                      <Select
                        value={allIsBooked}
                        onChange={(select) => setAllIsBooked(select)}
                        options={freeOption}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        placeholder="Please Select"
                      />
                    </FormGroup>
                  </Col> */}
                  <Button
                    color="primary"
                    className="h-25 mt-4"
                    onClick={addTimeNFree}
                  >
                    <i className="fa fa-plus "></i> Add
                  </Button>
                </Row>
                <Row>
                  {timeAdvisorData && (
                    timeAdvisorData
                      .slice() // Create a copy of timeAdvisorData to avoid mutating the original array
                      .sort((entry1, entry2) => {
                        // Extract time strings (hh:mm) from each entry and compare
                        const time1 = entry1.time.slice(11, 16); // Extract "HH:mm" from ISO datetime string
                        const time2 = entry2.time.slice(11, 16); // Extract "HH:mm" from ISO datetime string
                        return time1.localeCompare(time2); // Compare times
                      })
                      .map((ele, idx) => (
                        <div key={idx} style={{ marginRight: "5px", width: "100%" }}>
                          <div className="ml-3">
                            <div>
                              Time&nbsp;:&nbsp;{`${ele.time.slice(11, 16)}`}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Available&nbsp;:&nbsp; Yes</div>
                              <div>
                                <Button
                                  onClick={() => {
                                    setShowAlert(true);
                                    removeTimeNFree(ele);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "10px",
                                    fontWeight: "600",
                                  }}
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  )}


                </Row>

                <Button color="primary" className="w-100 mt-4" type="submit">
                  Edit Time Slot for Support Advisor
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditTimeSlotsComp;
