import axios from "axios";
import baseDomain, {
  allBlogTags,
  createBlogApi,
  createBlogTagApi,
  deleteBlogApi,
  deletedBlogsApi,
  getBlogReqApi,
  publishBlogApi,
  publishToUnpublishBlogApi,
  restoreBlogApi,
  searchBlogApi,
  unPublishBlogApi,
  unpublishToPublishBlogApi,
  updateBlogApi,
} from "../../../../Constants/apiRoutes";

const cmsBaseDomain = "https://www.cms-dev-be.theyogainstituteonline.org/v1";

//done

export const getPublishedBlog = ({ page, limit }) => {
  return axios.get(
    `${baseDomain}${publishBlogApi}?page=${page}&limit=${limit}`
  );
};
// done
export const createBlogReq = (payload) => {
  
  return axios.post(`${baseDomain}${createBlogApi}`, payload);
};

// done
export const getUnpublishedBlog = ({ page, limit }) => {
  return axios.get(
    `${baseDomain}${unPublishBlogApi}?page=${page}&limit=${limit}`
  );
};
// done
export const blogBin = (page, limit) => {
  return axios.get(
    `${baseDomain}${deletedBlogsApi}/?page=${page}&limit=${limit}`
  );
};
// done
export const publishBlog = (blogID) => {
  return axios.put(`${baseDomain}${unpublishToPublishBlogApi}/${blogID}`);
};
// done
export const unpublishBlog = (blogID) => {
  return axios.put(`${baseDomain}${publishToUnpublishBlogApi}/${blogID}`);
};
// done
export const getBlogReq = (blogId) => {
 
  return axios.get(`${baseDomain}${getBlogReqApi}/${blogId}`);
};
// done
export const editBlogReq = (blogId,payload) => {
  return axios.put(`${baseDomain}${updateBlogApi}/${blogId}`, payload);
};
// done
export const deleteBlog = (blogId) => {
  
  return axios.put(`${baseDomain}${deleteBlogApi}/${blogId}`);
};
// done
export const restoreBlogreq = (blogRestore) => {
  return axios.put(`${baseDomain}${restoreBlogApi}/${blogRestore}`);
};
// done
export const getAllTags = () => {
  return axios.get(`${baseDomain}${allBlogTags}`);
};

// done
export const createTag = (payload) => {
  return axios.post(`${baseDomain}${createBlogTagApi}`, payload);
};
// done
export const searchBlogsPublished = (best) => {
  return axios.get(`${baseDomain}${searchBlogApi}/${best}`);
};
export const searchBlogsunPublished = (page, search) => {
  return axios.get(
    `${cmsBaseDomain}/post/searchunPublishedPost/?title=${search}&page=${page}&limit=${10}`
  );
};
export const addMediaToPlaylist = (mediaId) => {
  return axios.put(`${baseDomain}${addMedia}/${mediaId}`);
}
export const removeMediaToPlaylist = (mediaId,payload) => {
  return axios.put(`${baseDomain}${removeMedia}/${mediaId}`,payload);
}
