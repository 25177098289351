import axios from "axios";
const domain = "https://back-end-preprod.nispand.com/v2";

export const createBookingSession = (payload) => {
  return axios.post(`${domain}/yogaClassSession`, payload);
};

export const getAllBookingSessions = ({ page, limit }) => {
  return axios.get(
    `${domain}/yogaClassSession/getAllYogaClass?isDisabled=false&isPublished=true&page=${page}&limit=${limit}`);
};
export const getAllBookingSessionsUnpublished = ({ page, limit }) => {
  return axios.get(
    `${domain}/yogaClassSession/getAllYogaClass?isDisabled=false&isPublished=false&page=${page}&limit=${limit}`);
};
export const updateBookingSessions = (sessionsId, payload) => {
  return axios.put(`${domain}/yogaClassSession/${sessionsId}`, payload);
};

export const getAllBookingSessionById = (sessionsId) => {
  return axios.get(`${domain}/yogaClassSession/${sessionsId}`);
};
export const getAllFaq = () => {
  return axios.get(`${domain}/faq/getAllFaq/COURSE`);
};
export const publishSession = (sessionsId) => {
  return axios.put(`${domain}/yogaClassSession/published/${sessionsId}`);
};
export const UnpublishSession = (sessionsId) => {
  return axios.put(`${domain}/yogaClassSession/UnPublished/${sessionsId}`);
};


