import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  PublishedNutriDietTableHeading,
  UnPublishedNutriDietTableHeading,
} from "../../Constants/constant";
import {
  getPublishedNutriDietAPI,
  getUnPublishedNutriDietAPI,
  UpdateUnPublishNutriDietAPI,
  UpdatePublishNutriDietAPI,
} from "../../nutridiets.Api";
import NutriDietPublished from "../../../../../../Components/NutriDietPublished";
import NutriDietUnPublished from "../../../../../../Components/NutriDietUnPublished";

const AllNutriDiet = () => {
  const [PublishedNutriDiet, setPublishedNutriDiet] = useState([]);
  const [UnPublishedNutriDiet, setUnPublishedNutriDiet] = useState([]);
  const [publishID, setPublishID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = paginationValue;

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 10,
  });
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue;

  const history = useHistory();

  const getPublishedNutriDietData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getPublishedNutriDietAPI(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      console.log(data.data);

      setPublishedNutriDiet(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getUnPublishedNutriDietData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getUnPublishedNutriDietAPI(queryParams);
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setUnPublishedNutriDiet(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(unpublishedPaginationValue,'unpublishedPaginationValue');

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getPublishedNutriDietData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getUnPublishedNutriDietData({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };

  const handleEditNutriDiet = (id) => {
    console.log(id, "vvvv");
    history.push({
      pathname: "/dashboard/nutri-diets/edit/" + id,
      state: { from: id },
    });
  };

  const handlePublishNutriDietClick = async (id) => {
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishNutriDietClick = async (id) => {
    setPublishID(id);
    setShowPublishAlert(true);
  };

  // const handleViewNutriDiet = (refId) => {
  //   history.push("/dashboard/NutriDiet/view/" + refId);
  // };

  const publishNutriDietAction = async () => {
    await UpdateUnPublishNutriDietAPI(publishID);
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
    const filteredNutriDiet = PublishedNutriDiet.filter(
      ({ _id }) => _id !== publishID
    );
    setPublishedNutriDiet(filteredNutriDiet);
    setPublishID("");
  };

  const UnpublishNutriDietAction = async () => {
    await UpdatePublishNutriDietAPI(publishID);
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
    const filteredNutriDiet = UnPublishedNutriDiet.filter(
      ({ _id }) => _id !== publishID
    );
    setUnPublishedNutriDiet(filteredNutriDiet);
    setPublishID("");
  };

  useEffect(() => {
    getPublishedNutriDietData({ page: 1, limit: 10 });
    getUnPublishedNutriDietData({ page: 1, limit: 10 });
  }, []);

  return (
    <div>
      <NutriDietPublished
        tableData={PublishedNutriDiet}
        handleEditNutriDiet={handleEditNutriDiet}
        tableHeading={PublishedNutriDietTableHeading}
        setPublishedNutriDiet={setPublishedNutriDiet}
        handlePublishNutriDiet={handlePublishNutriDietClick}
        handleTablePageChange={handleTablePageChange}
        // handleViewNutriDiet={handleViewNutriDiet}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />

      <NutriDietUnPublished
        tableData={UnPublishedNutriDiet}
        tableHeading={UnPublishedNutriDietTableHeading}
        setUnPublishedNutriDiet={setUnPublishedNutriDiet}
        handleEditNutriDiet={handleEditNutriDiet}
        // handleViewNutriDiet={handleViewNutriDiet}
        handleUnPublishNutriDiet={handleUnPublishNutriDietClick}
        handleTablePageChange={handleUnpublishedTablePageChange}
        isLoading={isLoading}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        unpublishedTotalSize={unpublishedTotalSize}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishNutriDietAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          NutriDiet has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, publish it!"
          reverseButtons={true}
          onConfirm={UnpublishNutriDietAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Nutri Diet has been published
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default AllNutriDiet;
