import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUploader from "../../../../../../../../Components/FileUploader";
import { uploadImages } from "../../api";
import { useHistory } from "react-router-dom";
import { creatingStates } from "../../Constants";
import Loader from "../../../../../../../../Components/Loader";
import { uploadFile } from "../../../Uploads/upload.Helper";
import AllImages from "../AllImages";

const ImageUploads = ({ showModal }) => {
  const history = useHistory();
  const [isEditForm, setIsEditForm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
  });
  const [pageImage, setPageImage] = useState(null);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isError, errorMessage, errorType } = error;
  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [showUploadAlert, setShowUploadAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadConfirmed, setShowUploadConfirmed] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const { firstName } = formData;
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!pageImage)
        return setError({
          isError: true,
          errorMessage: "Please select a image",
          errorType: "warning",
        });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      const imageThumbnailUrl = await uploadFile(
        pageImage,
        "PG-image",
        setThumbnailUploadPercentage,
        30
      );

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const { data } = await uploadImages({
        name: firstName,
        image: imageThumbnailUrl,
      });


      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });

      setImageUploaded(true);
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);

      setError({
        isError: true,
        errorMessage: "Unable to create Image please try again later",
        errorType: "danger",
      });
    }
    setIsLoading(false);
    setShowUploadConfirmed(true);
  };

  return (
    <>
      {showModal && !imageUploaded ? (
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  {!isEditForm && (
                    <Col xs={12}>
                      <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h3 className="m-0">Upload image</h3>
                      </div>
                    </Col>
                  )}
                </Row>
                <AvForm className="form-horizontal">
                  <Row>
                    <Col ls={4}>
                      <FormGroup>
                        <Label htmlFor="firstName">Name</Label>
                        <AvField
                          name="firstName"
                          placeholder="Enter name"
                          value={firstName}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Name is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="name"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
                <Row>
                  <Col style={{ position: "relative" }}>
                    {isLoading ? (
                      <div className="absol">
                        <Loader
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate( " - (50 % " ,") - (50 % ")"),
                          }}
                        />
                      </div>
                    ) : (
                      <FileUploader
                        file={pageImage}
                        setFile={setPageImage}
                        dragText="Drop Image here"
                        heading="Select Image (In Jpeg)"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                      />
                    )}
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  disabled={false}
                  onClick={() => handleSubmit()}
                >
                  Upload image
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
      {showUploadAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Upload it!"
          reverseButtons={true}
          onConfirm={() => handleSubmit()}
          onCancel={() => setShowUploadAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUploadConfirmed ? (
        <SweetAlert
          success
          title="Image uploaded"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowUploadAlert(false);
            setShowUploadConfirmed(false);
            setIsLoading(false);
            !showModal ? history.push("/dashboard/images/all") : null;
          }}
        >
          Image uploaded
        </SweetAlert>
      ) : null}

      {showModal ? imageUploaded ? <AllImages /> : null : null}
    </>
  );
};

export default ImageUploads;
