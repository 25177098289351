import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
  Modal,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const StocksModal = ({
  isOpen,
  isLoading,
  toggleModal,
  formData,
  setFormData,
  monthlyPlanCount,
  annuallyPlanCount,
  amountPaid,
  currency,
  isFieldsChanged
}) => {

  const handleClose = () => {
    toggleModal(false)
  }

  let debounce
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
    clearTimeout(debounce);
    debounce = setTimeout(
      isFieldsChanged({
        ...formData,
        [e.target.name]: e.target.value,
      }),
      500
    );
  };

  const handleOpen = () => {
    toggleModal(true);

  }

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <Row>
        <Col lg={12}>
          <Card>
            {!isLoading && (
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h3 className="m-0">
                      </h3>
                    </div>
                  </Col>
                </Row>
                <AvForm
                  className="form-horizontal">
                  <Row>
                    <Col ls={12}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">
                          Annual stocks
                        </Label>
                        <AvField
                          name="annuallyPlanCount"
                          placeholder="Annual stocks"
                          type="number"
                          value={annuallyPlanCount}
                          onChange={handelInput}
                          className="form-control"
                          id="user_identifier"
                          autocomplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col ls={6}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">
                          Monthly stocks
                        </Label>
                        <AvField
                          name="monthlyPlanCount"
                          placeholder="Monthly stocks"
                          type="number"
                          value={monthlyPlanCount}
                          onChange={handelInput}
                          className="form-control"
                          id="user_identifier"
                          autocomplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col ls={6}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Amount Paid</Label>
                        <AvField
                          name="amountPaid"
                          placeholder="Amount paid by user"
                          type="number"
                          value={amountPaid}
                          onChange={handelInput}
                          className="form-control"
                          id="user_identifier"
                          autocomplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
      <ModalFooter>
        <Button
          type="button"
          color="light"
          className="waves-effect"
          onClick={handleOpen}
        >
          Yes
        </Button>
        <Button
          type="button"
          color="light"
          className="waves-effect"
          onClick={handleClose}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StocksModal;
