import React, { useState, useEffect } from "react";
import EditProgramForm from "../../../../../../Components/EditProgramForm";
import { getAllMusic, getAllProgramsById, updatePrograms } from "../../programs.Apis";
import { useParams } from "react-router-dom";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import Loader from "../../../../../../Components/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditAllPrograms = () => {
  const { programId } = useParams();
  const history = useHistory()
  // console.log(programId, "ssdssdsdsds");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [programData, setProgramData] = useState({
    title: "",
    description: "",
    mediaIds: [],
    assetImage: "",
  });
  const [programData1, setProgramData1] = useState({
    title: "",
    description: "",
    mediaIds: [],
    assetImage: "",
  });
  const [programData2, setProgramData2] = useState({
    title: "",
    description: "",
    mediaIds: [],
    assetImage: "",
  });
  const [coverImage, setCoverImage] = useState("")
  const [coverImage1, setCoverImage1] = useState("")
  const [coverImage2, setCoverImage2] = useState("")

  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [isLoading, setIsLoading] = useState(true);

  const [mediaSelectA, setMediaSelectA] = useState([])
  const [mediaSelectA1, setMediaSelectA1] = useState([])
  const [mediaSelectA2, setMediaSelectA2] = useState([])

  const [mediaSelectB, setMediaSelectB] = useState([])
  const [mediaSelectB1, setMediaSelectB1] = useState([])
  const [mediaSelectB2, setMediaSelectB2] = useState([])

  const [mediaSelectC, setMediaSelectC] = useState([])
  const [mediaSelectC1, setMediaSelectC1] = useState([])
  const [mediaSelectC2, setMediaSelectC2] = useState([])

  const [mediaData, setMediaData] = useState([])


  // const { title, description } = programData;



  const getAllSlotDataById = async () => {
    try {
      setIsLoading(true);

      const { data } = await getAllProgramsById(programId);
      const programDataForTimeSlot = data.data.timeSlot[0].programData;
      const programDataForTimeSlot1 = data.data.timeSlot[1].programData;
      const programDataForTimeSlot2 = data.data.timeSlot[2].programData;


      setProgramData({
        title: programDataForTimeSlot.title,
        description: programDataForTimeSlot.description,
        // assetImage: programDataForTimeSlot.assetImage,
        mediaIds: programDataForTimeSlot.mediaIds,
      });
      setCoverImage(programDataForTimeSlot.assetImage)
      setMediaSelectA({
        key: programDataForTimeSlot.mediaIds[0]._id,
        value: programDataForTimeSlot.mediaIds[0]._id,
        label: programDataForTimeSlot.mediaIds[0].title,
      })
      setMediaSelectA1({
        key: programDataForTimeSlot.mediaIds[1]._id,
        value: programDataForTimeSlot.mediaIds[1]._id,
        label: programDataForTimeSlot.mediaIds[1].title,
      })
      setMediaSelectA2({
        key: programDataForTimeSlot.mediaIds[2]._id,
        value: programDataForTimeSlot.mediaIds[2]._id,
        label: programDataForTimeSlot.mediaIds[2].title,
      })

      setProgramData1({
        title: programDataForTimeSlot1.title,
        description: programDataForTimeSlot1.description,
        // assetImage: programDataForTimeSlot1.assetImage,
        mediaIds: programDataForTimeSlot1.mediaIds,
      })
      setCoverImage1(programDataForTimeSlot1.assetImage)
      setMediaSelectB({
        key: programDataForTimeSlot1.mediaIds[0]._id,
        value: programDataForTimeSlot1.mediaIds[0]._id,
        label: programDataForTimeSlot1.mediaIds[0].title,
      })
      setMediaSelectB1({
        key: programDataForTimeSlot1.mediaIds[1]._id,
        value: programDataForTimeSlot1.mediaIds[1]._id,
        label: programDataForTimeSlot1.mediaIds[1].title,
      })
      setMediaSelectB2({
        key: programDataForTimeSlot1.mediaIds[2]._id,
        value: programDataForTimeSlot1.mediaIds[2]._id,
        label: programDataForTimeSlot1.mediaIds[2].title,
      })

      setProgramData2({
        title: programDataForTimeSlot2.title,
        description: programDataForTimeSlot2.description,
        // assetImage: programDataForTimeSlot2.assetImage,
        mediaIds: programDataForTimeSlot2.mediaIds,
      })
      setCoverImage2(programDataForTimeSlot2.assetImage)
      setMediaSelectC({
        key: programDataForTimeSlot2.mediaIds[0]._id,
        value: programDataForTimeSlot2.mediaIds[0]._id,
        label: programDataForTimeSlot2.mediaIds[0].title,
      })
      setMediaSelectC1({
        key: programDataForTimeSlot2.mediaIds[1]._id,
        value: programDataForTimeSlot2.mediaIds[1]._id,
        label: programDataForTimeSlot2.mediaIds[1].title,
      })
      setMediaSelectC2({
        key: programDataForTimeSlot2.mediaIds[2]._id,
        value: programDataForTimeSlot2.mediaIds[2]._id,
        label: programDataForTimeSlot2.mediaIds[2].title,
      })

    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getAllMusicMediaId = async () => {
    try {
      const { data } = await getAllMusic()
      setMediaData(data.data)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllSlotDataById();
    getAllMusicMediaId()
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault()

    try {
      let coverUrl = coverImage;
      let coverUrl1 = coverImage1;
      let coverUrl2 = coverImage2;

      // let blogUrl = null;


      if (coverImage && typeof coverImage !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        coverUrl = await uploadFile(
          coverImage,
          "IMAGE",
          setContentUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      if (coverImage1 && typeof coverImage1 !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        coverUrl1 = await uploadFile(
          coverImage1,
          "IMAGE",
          setContentUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      if (coverImage2 && typeof coverImage2 !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        coverUrl2 = await uploadFile(
          coverImage2,
          "IMAGE",
          setContentUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      await updatePrograms(programId, {
        timeSlot: [
          {
            time: "4:00 AM",
            programData: {
              title: programData.title,
              description: programData.description,
              assetImage: coverUrl,
              order: "1",
              mediaIds: [mediaSelectA.value,mediaSelectA1.value,mediaSelectA2.value]
            }
          },
          {
            time: "12:00 PM",
            programData: {
              title: programData1.title,
              description: programData1.description,
              assetImage: coverUrl1,
              order: "2",
              mediaIds: [mediaSelectB.value,mediaSelectB1.value,mediaSelectB2.value]
            }
          },
          {
            time: "5:00 PM",
            programData: {
              title: programData2.title,
              description: programData2.description,
              assetImage: coverUrl2,
              order: "3",
              mediaIds: [mediaSelectC.value,mediaSelectC1.value,mediaSelectC2.value]
            }
          },
        ]
      })


    } catch (error) {
      console.log(error);
    }
    // window.location.reload()
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  }
  console.log(mediaSelectA.value, 'asdfasdf')

  const handleMediaSelectChangeA = (selectedOption) => {
    setMediaSelectA(selectedOption);
  };
  const handleMediaSelectChangeA1 = (selectedOption) => {
    setMediaSelectA1(selectedOption);
  };
  const handleMediaSelectChangeA2 = (selectedOption) => {
    setMediaSelectA2(selectedOption);
  };
  const handleMediaSelectChangeB = (selectedOption) => {
    setMediaSelectB(selectedOption);
  };
  const handleMediaSelectChangeB1 = (selectedOption) => {
    setMediaSelectB1(selectedOption);
  };
  const handleMediaSelectChangeB2 = (selectedOption) => {
    setMediaSelectB2(selectedOption);
  };
  const handleMediaSelectChangeC = (selectedOption) => {
    setMediaSelectC(selectedOption);
  };
  const handleMediaSelectChangeC1 = (selectedOption) => {
    setMediaSelectC1(selectedOption);
  };
  const handleMediaSelectChangeC2 = (selectedOption) => {
    setMediaSelectC2(selectedOption);
  };
  
  const handleChange = (e) => {
    setProgramData({
      ...programData,
      [e.target.name]: e.target.value
    })
  }
  const handleChange1 = (e) => {
    setProgramData1({
      ...programData1,
      [e.target.name]: e.target.value
    })
  }
  const handleChange2 = (e) => {
    setProgramData2({
      ...programData2,
      [e.target.name]: e.target.value
    })
  }

  const mediaOptions = mediaData.map(item => ({
    value: item._id,
    label: item.title
  }));

  return (
    <>
      {isLoading ? (<Loader />) : <EditProgramForm
        time="4:00 AM"
        time1="12:00 PM"
        time2="5:00 PM"
        title={programData.title}
        description={programData.description}
        mediaIds={programData.mediaIds}

        mediaSelecta={mediaSelectA}
        handleMediaSelectChangeA={handleMediaSelectChangeA}
        mediaSelecta1={mediaSelectA1}
        handleMediaSelectChangeA1={handleMediaSelectChangeA1}
        mediaSelecta2={mediaSelectA2}
        handleMediaSelectChangeA2={handleMediaSelectChangeA2}

        mediaSelectb={mediaSelectB}
        handleMediaSelectChangeB={handleMediaSelectChangeB}
        mediaSelectb1={mediaSelectB1}
        handleMediaSelectChangeB1={handleMediaSelectChangeB1}
        mediaSelectb2={mediaSelectB2}
        handleMediaSelectChangeB2={handleMediaSelectChangeB2}

        mediaSelectc={mediaSelectC}
        handleMediaSelectChangeC={handleMediaSelectChangeC}
        mediaSelectc1={mediaSelectC1}
        handleMediaSelectChangeC1={handleMediaSelectChangeC1}
        mediaSelectc2={mediaSelectC2}
        handleMediaSelectChangeC2={handleMediaSelectChangeC2}

        mediaData={mediaOptions}
        title1={programData1.title}
        description1={programData1.description}
        title2={programData2.title}
        description2={programData2.description}
        coverImage={coverImage}
        setCoverImage={setCoverImage}
        coverImage1={coverImage1}
        setCoverImage1={setCoverImage1}
        coverImage2={coverImage2}
        setCoverImage2={setCoverImage2}
        handleUpdateProgram={handleUpdate}
        handleChange={handleChange}
        handleChange1={handleChange1}
        handleChange2={handleChange2}

      />}
      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={() => {
            handleUpdate()

          }}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}
      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Changes Saved"
          onConfirm={() => {

            setShowDeleteAlert(false);
            setShowDeleteConfirm(false)
            history.push("/dashboard/programs/all")
          }}
        ></SweetAlert>
      ) : null}
    </>
  );
};

export default EditAllPrograms;