import AllMusic from "../Components/AllMusic";
import CreateMusic from "../Components/CreateMusic";
import EditMusic from "../Components/EditMusic";
import MusicPlayer from "../Components/MusicPlayer";
import MusicBin from "../Components/MusicBin";
import AlternateMusic from '../Components/AlternateMusic';

export const MusicRoutes = [
  {
    Component: AllMusic,
    path: '/all',
    exact: true,
    id: 'dashboardMediaAll',
    routePath: '/dashboard/media/all',
    name: 'All Media',
  },
  {
    Component: CreateMusic,
    path: '/create',
    exact: true,
    id: 'dashboardMediaCreate',
    routePath: '/dashboard/media/create',
    name: 'Add Media',
  },
  {
    Component: MusicBin,
    path: '/bin',
    exact: true,
    id: 'dashboardMediaBin',
    routePath: '/dashboard/media/bin',
    name: 'Media Bin',
  },
  {
    Component: EditMusic,
    path: '/edit/:musicID',
    exact: true,
    id: 'dashboardMediaEdit',
  },
  {
    Component: MusicPlayer,
    path: '/view/:musicID',
    exact: true,
    id: 'dashboardMediaView',
  },
  {
    Component: AlternateMusic,
    path: '/alternate/:musicID',
    exact: true,
    id: 'dashboardMediaAlternate',
  },
];
