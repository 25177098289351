import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";

const UserInfoModal = ({
  firstName,
  lastName,
  companyName,
  email,
  contact,
  country,
  handleEditUserClick,
  isUserInfoOpen,
  toggleUserInfoModal,
  rowId,
}) => {

  const handleClose = () => {
    toggleUserInfoModal();
  };

  return (
    <>
      <Modal size="lg" isOpen={isUserInfoOpen} backdrop="static">
        <AvForm className="needs-validation">
          <ModalHeader>Users</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="name">First Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control"
                    value={firstName}
                    id="name"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="name">Last Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control"
                    value={lastName}
                    id="name"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="description">Company name</Label>
                  <AvField
                    name="description"
                    type="text"
                    className="form-control"
                    value={companyName}
                    id="description"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="objectType">Email</Label>
                  <AvField
                    name="objectType"
                    type="text"
                    className="form-control"
                    value={email}
                    id="objectType"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="objectType">Contact No.</Label>
                  <AvField
                    name="objectType"
                    type="text"
                    className="form-control"
                    value={contact}
                    id="objectType"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="objectType">Country</Label>
                  <AvField
                    name="objectType"
                    type="text"
                    className="form-control"
                    value={country}
                    id="objectType"
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
          {rowId !== true ? (
              <Button
                type="button"
                onClick={handleEditUserClick}
                color="light"
                className="waves-effect"
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
            <Button
              type="button"
              onClick={handleClose}
              color="light"
              className="waves-effect"
            >
              Close
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  );
};

export default UserInfoModal;
