import React, { useEffect, useState } from "react";
import UploadingMediaLoader from "../../../../../../Components/MediaMappingLoader";
import MediaMappingLoader from "../../../../../../Components/MediaMappingLoader";
import MappingForm from "../../../../../../Components/MediaMappingForm";
import MediaMappingForm from "../../../../../../Components/MediaMappingForm";
import { creatingStatesForMapping } from "../../Tag.Helpers";
import { getMediaMapping, mediaMappingCreateApi } from "../../Tag.Apis";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";

const CreateMapping = () => {
  const [formData, setFormData] = useState({
    category: "",
    subcategories: [],
  });
  const history =  useHistory()
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [categoryData, setcategoryData] = useState([]);
  const [catCount, setCatCount]= useState('')
  const [subCatCount, setSubCatCount]= useState('')

  const getCategoryData = async (queryParams) => {
    try {
      const { data } = await getMediaMapping(queryParams);
      setcategoryData(data.data,'category')
      setCatCount(data.count)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategoryData({ objectType: "CATEGORY" , page:1,limit:catCount});
  }, [catCount]);

  const [subCategorySelect, setSubCategorySelect] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [created, setCreated] = useState(false);

  const getSubcategoryData = async (queryParams) => {
    try {
      const { data } = await getMediaMapping(queryParams);
      setSubCategories(data.data);
      setSubCatCount(data.count)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSubcategoryData({ objectType: "SUBCATEGORY" , page:1,limit:subCatCount});
  }, [subCatCount]);

  const subCategoriesData = subCategories.map((item) => {
    return {
      key: item._id,
      label: item.name,
      value: item.name,
    };
  });


  const handelInputCategory = (event) => {
    const categoryValue = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      category: categoryValue,
    }));
  };

  const handleSubCategorySelect = (selectedOptions) => {
    const subcategories = selectedOptions&& selectedOptions.length>0&& selectedOptions.map((option) => option.key);
    setFormData((prevFormData) => ({
      ...prevFormData,
      subcategories: subcategories,
    }));
    setSubCategorySelect(selectedOptions);
  };

  const handleSubmit = async () => {
    const { category, subcategories } = formData;

    try {
      const { data } = await mediaMappingCreateApi(category, {
        refIds: subcategories,
      });
    } catch (error) {
      console.error(error);
    }

    // Perform additional actions with category and subcategories data
  };

  return (
    <>
      <MediaMappingForm
        categoryData={categoryData}
        subCategorySelect={subCategorySelect}
        subCategoriesData={subCategoriesData}
        handelInputCategory={handelInputCategory}
        handleSubCategorySelect={handleSubCategorySelect}
        handleSubmit={handleSubmit}
        isError={isError}
        errorMessage={errorMessage}
        errorType={errorType}
        setCreated={setCreated}
      />

      {created ? (
        <SweetAlert
          success
          title="Created"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setCreated(false)
            history.push('/dashboard/media-properties/mapping')
          }}
        />
      ) : null}
    </>
  );
};

export default CreateMapping;
