import React, { useEffect, useState } from "react";
import reactSelect from "react-select";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CKEditor } from "ckeditor4-react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";

const FaqForm = ({
  heading,
  getData,
  error,
  setShowPostFaqAlert,
  publishHandle,
  planDescription,
  handleInput,
  handleFaqtype,
  faqType,
  setContent,
  descrip,
  setDescrip,
}) => {
  const rawContentState = convertToRaw(descrip.getCurrentContent());

  const markup = draftToHtml(
    rawContentState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );
  setContent(markup);
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{heading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal">
              <FormGroup>
                <Label htmlFor="description">Title</Label>
                <AvField
                  name="title"
                  placeholder="Enter Question"
                  value={planDescription.title}
                  onChange={handleInput}
                  type="text"
                  errorMessage="Title is required"
                  className="form-control"
                  id="title"
                />
                {error === 1 && (
                  <small className="text-danger ">Please set a title.</small>
                )}
              </FormGroup>
              <Row>
                <Col xs={3}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">Tags</Label>
                      <Input
                        name="tag"
                        placeholder="Select Type"
                        type="select"
                        value={planDescription.tag}
                        onChange={handleInput}
                        className="form-control"
                        id="languageSelect"
                      >
                        <option value="">Select tag</option>
                        {getData?.map((item) => {
                          if (item?.objectType === "FAQTAG") {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          }
                        })}
                      </Input>
                      {error === 2 && (
                        <small className="text-danger ">
                          Please select tag.
                        </small>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">Categories</Label>
                      <Input
                        name="category"
                        placeholder="Select Type"
                        type="select"
                        value={planDescription.category}
                        onChange={handleInput}
                        className="form-control"
                        id="languageSelect"
                      >
                        <option value="">Select category</option>

                        {getData?.map((item) => {
                          if (item?.objectType === "FAQCATEGORY") {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          }
                        })}
                      </Input>
                      {error === 3 && (
                        <small className="text-danger ">
                          Please select category.
                        </small>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">FAQ type</Label>
                      <select
                        name="Faq-type"
                        placeholder="Select Type"
                        type="select"
                        value={faqType}
                        onChange={handleFaqtype}
                        className="form-control"
                        id="languageSelect"
                      >
                        <option value="">Select faqType</option>
                        <option value="GENERAL">GENERAL</option>
                        <option value="COURSE">COURSE</option>
                        <option value="NLIVE">NLIVE</option>
                      </select>
                      {error === 5 && (
                        <small className="text-danger ">
                          Please select FAQ type.
                        </small>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </AvForm>
            <Row>
              <Col>
                <Label htmlFor="description">Description</Label>
                <Editor
                  editorState={descrip}
                  toolbarClassName="toolbarClass"
                  wrapperClassName="wrapperClass"
                  editorClassName="editorClass"
                  onEditorStateChange={setDescrip}
                />
              </Col>
            </Row>
            {error === 4 && (
              <small className="text-danger ">Please enter description.</small>
            )}

            <Button
              color="primary"
              className="w-100 "
              type="submit"
              onClick={publishHandle}
            >
              Create FAQ
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FaqForm;
