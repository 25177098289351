import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LiveSessionEditComp from "../../../../../../Components/LiveSessionEditComp";
import {
  getCourseByIdApi,
  updateCourseApi,
  getAllSupportAdvisorApi,
  getAllLessonApi,
  getAllFaq,
  getLiveSessionById,
  updateLiveSessionById,
} from "../../Constants/api";
import { creatingStates } from "../../../Blogs/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";

const EditLiveSession = () => {
  const { liveSessionId } = useParams();
  const history = useHistory();

  const [formData, setFormData] = useState({
    title: "",
    imgAsset: "",
    supportAdvisor: "",
    whatWillYouLearn: [],
    calories: "",
    intensity: "",
    lessons: [],
    Faqs: [],
    outCome: [],
    gettings: [],
    assetUrl: "",
    startDate:"",
    endDate:""
  });
  const [lessonsData, setLessonsData] = useState([]);
  const [lessonName, setLessonName] = useState([]);

  const [advisor, setAdvisor] = useState([]);
  const [sessionData, getSessionData] = useState([]);
  const [sessionsById, getSessionsById] = useState("");
  const [adName, setAdName] = useState([]);
  const [learnTitle, setLearnTitle] = useState("");
  const [learnImg, setLearnImg] = useState("");
  const [learnTitleTwo, setLearnTitleTwo] = useState("");
  const [learnImgTwo, setLearnImgTwo] = useState("");
  const [learnTitleThree, setLearnTitleThree] = useState("");
  const [learnImgThree, setLearnImgThree] = useState("");
  const [faq, setFaq] = useState([]);
  const [faqData, setFaqData] = useState("");
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [content, setContent] = useState(null);
  const [headerImg, setHeaderImg] = useState("");
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });

  const [meetLink, setMeetLink] = useState("")

  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { title, imgAsset, supportAdvisor, calories, intensity, assetUrl, startDate, endDate } = formData;
  const [selectedDate,setSelectedDate] = useState("")
  const [selectDate,setSelectDate] = useState("")

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const getAllLessonData = async () => {
    const { data } = await getAllLessonApi();
    setLessonsData(data?.data);
  };

  const getAllFaqData = async () => {
    const { data } = await getAllFaq();
    setFaqData(data?.faqs);
  };

  useEffect(() => {
    getAllFaqData();
    getAllLessonData();
  }, []);

  function htmlDecode(input) {
    console.log(input,"ceck");
    var e = document.createElement("div");
    e.innerHTML = input;
    console.log(e.innerHTML,"check")
    return e.firstChild.nodeValue
  }

  const getSessionByIdData = async () => {
    try {
      const { data } = await getLiveSessionById(liveSessionId);
     
      getSessionData(data?.data);
      setFormData({
        title: data?.data?.title,
        startDate:data?.data?.startDate,
        endDate:data?.data?.endDate
      });
      if (!selectedDate) {
          setSelectedDate(data?.data?.startDate);
        }
        if (!selectDate) {
          setSelectDate(data?.data?.endDate);
        }
      setHeaderImg(data?.data?.imgAsset);
      setLearnImg(data?.data?.whatWillYouLearn[0]?.imgAsset);
      setLearnTitle(data?.data?.whatWillYouLearn[0]?.title);
      setLearnTitleTwo(data?.data?.whatWillYouLearn[1]?.title);
      setLearnImgTwo(data?.data?.whatWillYouLearn[1]?.imgAsset);
      setLearnTitleThree(data?.data?.whatWillYouLearn[2]?.title);
      setLearnImgThree(data?.data?.whatWillYouLearn[2]?.imgAsset);
      if (data?.data?.description) {
        setContent(data?.data?.description);
      }
      setMeetLink(data?.data?.assetUrl)

      const res = await getAllSupportAdvisorApi();
      const res2 = await getAllFaq();
      setAdvisor(res?.data?.data);
      const advisorByID = res?.data?.data;
      const advisorName = data?.data?.supportAdvisorId._id;
      const filAdvsiorId = advisorByID?.filter((i) => i._id === advisorName);
      const advName = filAdvsiorId[0].name;
      const faqName = data?.data?.faqId;
      const faqID = res2?.data?.faqs;
      const filFaq = faqID?.filter((ele) => ele?._id === faqName);
      getSessionsById(data?.data);
      setAdName({
        key: data?.data?.supportAdvisorId,
        label: advName,
        value: data?.data?.supportAdvisorId,
      });
      setFaq([...faq,{
        key: faqName?._id,
        label: faqName?.title,
        value: faqName?.title,
      }]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      let headerImgUrl = headerImg;
      let learnImgUrl = learnImg;
      let learnImgTwoUrl = learnImgTwo;
      let learnImgThreeUrl = learnImgThree;

      // setShowUnPublishAlert(true);
      if (headerImg && typeof headerImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        headerImgUrl = await uploadFile(
          headerImg,
          "COURSES-IMAGE-HEADER",
          setThumbnailUploadPercentage,
          20
        );
      } else setContentUploadPercentage(20);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImg && typeof learnImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgUrl = await uploadFile(
          learnImg,
          "COURSES-IMAGE-LEARN1",
          setThumbnailUploadPercentage,
          30
        );
      } else setContentUploadPercentage(30);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImgTwo && typeof learnImgTwo !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgTwoUrl = await uploadFile(
          learnImgTwo,
          "COURSES-IMAGE-LEARN2",
          setThumbnailUploadPercentage,
          40
        );
      } else setContentUploadPercentage(40);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      if (learnImgThree && typeof learnImgThree !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        learnImgThreeUrl = await uploadFile(
          learnImgThree,
          "COURSES-IMAGE-LEARN3",
          setThumbnailUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
      const adjustedStartDate = new Date(formData?.startDate);
      adjustedStartDate.setDate(adjustedStartDate.getDate() );
      const adjustedEndDate = new Date(formData?.endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate());
      

      await updateLiveSessionById(liveSessionId, {
        title: formData.title,
        description: content,
        imgAsset: headerImgUrl,
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        supportAdvisorId: formatMultiSelectData(adName),
        
        whatWillYouLearn: [
          {
            imgAsset: learnImgUrl,
            title: learnTitle,
          },
          {
            imgAsset: learnImgTwoUrl ? learnImgTwoUrl : "",
            title: learnTitleTwo ? learnTitleTwo : "",
          },
          {
            imgAsset: learnImgThreeUrl ? learnImgThreeUrl : "",
            title: learnTitleThree ? learnTitleThree : "",
          },
        ],
        faqId: faq.key,
        assetUrl: meetLink
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeSession = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getSessionByIdData();
  }, []);

  return (
    <>
      {content && (
        <LiveSessionEditComp
          title={title}
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          adName={adName}
          setAdName={setAdName}
          learnImg={learnImg}
          setLearnImg={setLearnImg}
          learnTitle={learnTitle}
          setLearnTitle={setLearnTitle}
          learnImgTwo={learnImgTwo}
          setLearnImgTwo={setLearnImgTwo}
          learnTitleTwo={learnTitleTwo}
          setLearnTitleTwo={setLearnTitleTwo}
          learnImgThree={learnImgThree}
          setLearnImgThree={setLearnImgThree}
          learnTitleThree={learnTitleThree}
          setLearnTitleThree={setLearnTitleThree}
          content={content}
          setContent={setContent}
          advisor={advisor}
          setAdvisor={setAdvisor}
          setFaqData={setFaqData}
          faqData={faqData}
          faq={faq}
          setFaq={setFaq}
          handleSubmit={handleSubmit}
          handleChangeSession={handleChangeSession}
          showUnPublishAlert={showUnPublishAlert}
          setShowUnPublishAlert={setShowUnPublishAlert}
          meetLink={meetLink}
          setMeetLink={setMeetLink}
          selectDate={selectDate}
          selectedDate={selectedDate}
          setSelectDate={setSelectDate}
          setSelectedDate={setSelectedDate}
          formData={formData}
          setFormData={setFormData}
        />
      )}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmit();
            setShowUnPublishAlert();
          }}
          onCancel={() => setShowUnPublishAlert()}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/live-sessions/all");
          }}
        >
          Session has been edited
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditLiveSession;