import axios from "axios";
import {
  baseDomain,
  stage,
  getAdvisorsData,
  getAdvisorsDataById,
  updateAdvisor,
  createAdvisor,
} from "../../../../Constants/apiRoutes";

export const getAllAdvisors = ({page,limit}) => {
  return axios.get(`${baseDomain}${stage}${getAdvisorsData}?page=${page}&limit=${limit}`);
};
export const getAllAdvisorsById = (advisorId) => {
  return axios.get(`${baseDomain}${stage}${getAdvisorsDataById}/${advisorId}`);
};

export const deleteAdvisorById = (advisorId) => {
  return axios.delete(`${baseDomain}${stage}${getAdvisorsDataById}/${advisorId}`);
};

export const updateAdvisors = (advisorId, payload) => {
  return axios.put(
    `${baseDomain}${stage}${updateAdvisor}/${advisorId}`,
    payload
  );
}

export const createAdvisorApi = (data) => {
  return axios.post(`${baseDomain}${stage}${createAdvisor}`,data)
}; 

