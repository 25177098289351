export const PublishedSeriesTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  // {
  //   text: 'Description',
  //   dataField: 'description',
  // },
  {
    text: 'Media Type',
    dataField: 'mediaType',
  }
]

export const UnPublishedSeriesTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  // {
  //   text: 'Description',
  //   dataField: 'description',
  // },
  {
    text: 'Media Type',
    dataField: 'mediaType',
  }
]

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
}
 