import React, { useState, useEffect } from "react";

import MusicForm from "../../../../../../Components/MusicForm";
import UploadingMusicLoader from "../../../../../../Components/UploadingMusicLoader";

// import { uploadVideoFile, getAudioDuration } from "../../helper";
import { uploadFile,getAudioDuration  } from "../../../Music/Music.Helpers";
import { fileTypes , creatingStates} from "../../constant";
import { getMediaApi } from "../../../Tag/Tag.Apis";
import { createMusicReq, transCodingApi } from "../../api";
import { formatMultiSelectData } from "../../helper";

const CreateVedio = () => {
  const [getCategory, setGetCategory] = useState("");
  const [getSubCategory, setGetSubCategory] = useState("");
  const [getTags, setGetTags] = useState("");
  const [getCategoryCount, setGetCategoryCount] = useState("");
  const [getSubCategoryCount, setGetSubCategoryCount] = useState("");
  const [getTagsCount, setGetTagsCount] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailColor, setThumbnailColor] = useState(null);

  const [img115x150, setImg115x150] = useState(null);
  const [img115x150UploadPercent, setImg115x150UploadPercent] = useState(0);

  const [img145x345, setImg145x345] = useState(null);
  const [img145x345UploadPercent, setImg145x345UploadPercent] = useState(0);

  const [img180x160, setImg180x160] = useState(null);
  const [img180x160UploadPercent, setImg180x160UploadPercent] = useState(0);

  const [img155x135, setImg155x135] = useState(null);
  const [img155x135UploadPercent, setImg155x135UploadPercent] = useState(0);

  const [video, setVideo] = useState(null);

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [audioUploadPercentage, setAudioUploadPercentage] = useState(0);

  const [formData, setFormData] = useState({
    title: "",
    language: "en",
    description: "",
    isFree: true,
    UserRecommendationMood: "Select Mood",
    UserRecommendationStress: "Select Stress Level",
    UserRecommendationBPM: "Select BPM",
    objectType: "VIDEO",
  });
  const {
    title,
    description,
    isFree,
    language,
    objectType,
    // UserRecommendationAge,
    // UserRecommendationMaritalStatus,
    UserRecommendationMood,
    // UserRecommendationBPM,
    UserRecommendationStress,
  } = formData;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [UserRecommendationAge, setUserRecommendationAge] = useState([]);
  const [UserRecommendationMaritalStatus, setUserRecommendationMaritalStatus] =
    useState([]);
  const [UserRecommendationBPM, setUserRecommendationBPM] = useState([]);
  const [
    UserRecommendationFocusOnPresentMoment,
    setUserRecommendationFocusOnPresentMoment,
  ] = useState([]);
  const [
    UserRecommendationExperienceStressAnxiety,
    setUserRecommendationExperienceStressAnxiety,
  ] = useState([]);
  const [UserRecommendationSleep, setUserRecommendationSleep] = useState([]);
  const [UserRecommendationHoursOfSleep, setUserRecommendationHoursOfSleep] =
    useState([]);
  const [
    UserRecommendationIrritatedOrAngry,
    setUserRecommendationIrritatedOrAngry,
  ] = useState([]);
  const [
    UserRecommendationFrequentLethargy,
    setUserRecommendationFrequentLethargy,
  ] = useState([]);
  const [
    UserRecommendationCommitmentAtWork,
    setUserRecommendationCommitmentAtWork,
  ] = useState([]);
  const [
    UserRecommendationIssuesInRelationship,
    setUserRecommendationIssuesInRelationship,
  ] = useState([]);
  const [UserRecommendationLowSelfEsteem, setUserRecommendationLowSelfEsteem] =
    useState([]);
  const [
    UserRecommendationHoursSpendOnSocialMedia,
    setUserRecommendationHoursSpendOnSocialMedia,
  ] = useState([]);
  const [UserRecommendationGender, setUserRecommendationGender] = useState([]);
  const [moods, setMoods] = useState([]);
  const [stressLevel, setStressLevel] = useState([]);
  const [allTags, setAllTags] = useState({
    tagsData: [],
    categoryData: [],
    subCategoryData: [],
  });
  const [level,setLevel] = useState("")
  const moodsData = {
    moodData: [
      { key: "HAPPY", label: "HAPPY", value: "HAPPY" },
      { key: "STRESSED", label: "STRESSED", value: "STRESSED" },
      { key: "CANT_SLEEP", label: "CANT_SLEEP", value: "CANT_SLEEP" },
      { key: "CALM", label: "CALM", value: "CALM" },
      { key: "LOW", label: "LOW", value: "LOW" },
      { key: "ALL", label: "ALL", value: "ALL" },
    ],
    stressLevelData: [
      { key: "FROM_1_TO_3", label: "FROM_1_TO_3", value: "FROM_1_TO_3" },
      { key: "FROM_4_TO_6", label: "FROM_4_TO_6", value: "FROM_4_TO_6" },
      { key: "FROM_7_TO_10", label: "FROM_7_TO_10", value: "FROM_7_TO_10" },
      { key: "ALL", label: "ALL", value: "ALL" },
    ],
  };
  const ageData = [
    { key: "BELOW_14", label: "BELOW_14", value: "BELOW_14" },
    { key: "FROM_14_TO_16", label: "FROM_14_TO_16", value: "FROM_14_TO_16" },
    { key: "FROM_17_TO_30", label: "FROM_17_TO_30", value: "FROM_17_TO_30" },
    { key: "FROM_31_TO_50", label: "FROM_31_TO_50", value: "FROM_31_TO_50" },
    { key: "ABOVE_50", label: "ABOVE_50", value: "ABOVE_50" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];
  const maritalData = [
    { key: "MARRIED", label: "MARRIED", value: "MARRIED" },
    { key: "UNMARRIED", label: "UNMARRIED", value: "UNMARRIED" },
    { key: "SEPARATED", label: "SEPARATED", value: "SEPARATED" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const bpmData = [
    {
      key: "FROM_110_TO_120",
      label: "FROM_90_TO_110",
      value: "FROM_110_TO_120",
    },
    { key: "ABOVE_120", label: "ABOVE_110", value: "ABOVE_120" },
    { key: "BELOW_110", label: "BELOW_90", value: "BELOW_110" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const focusOnPresentMomentData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];
  const experienceStressAnxietyData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];
  const sleepData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];
  const sleepHoursData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const irritatedOrAngryData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];
  const lethargyData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];
  const commitWorkData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const issueRelationshipData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const esteemData = [
    { key: "NEVER", label: "NEVER", value: "NEVER" },
    { key: "SOMETIMES", label: "SOMETIMES", value: "SOMETIMES" },
    { key: "OFTEN", label: "OFTEN", value: "OFTEN" },
    { key: "ALMOST_ALWAYS", label: "ALMOST_ALWAYS", value: "ALMOST_ALWAYS" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const hoursOnSocialData = [
    { key: "FROM_0_TO_2", label: "FROM_0_TO_2", value: "FROM_0_TO_2" },
    { key: "FROM_2_TO_4", label: "FROM_2_TO_4", value: "FROM_2_TO_4" },
    { key: "FROM_4_TO_6", label: "FROM_4_TO_6", value: "FROM_4_TO_6" },
    { key: "ABOVE_6", label: "ABOVE_6", value: "ABOVE_6" },
  ];

  const genderData = [
    { key: "MALE", label: "MALE", value: "MALE" },
    { key: "FEMALE", label: "FEMALE", value: "FEMALE" },
    { key: "OTHER", label: "OTHER", value: "OTHER" },
    { key: "ALL", label: "ALL", value: "ALL" },
  ];

  const { tagsData, categoryData, subCategoryData } = allTags;
  const { moodData, stressLevelData } = moodsData;

  // Handle form IP
  const handelInput = (e) => {
    if (e.target.name === "isFree")
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // handle Change Tags
  const handleCategoriesSelect = (selectedCategories) => {
    setError({
      ...error,
      isError: false,
    });
    setCategories(selectedCategories);
  };
  const handleSubCategoriesSelect = (selectedSubCategories) =>
    setSubCategories(selectedSubCategories);

  const handleLevelSelect = (event) => {
      setLevel(event.target.value);
    };

  const handleTagsSelect = (selectedTags) => setTags(selectedTags);

  const handleMoodsSelect = (selectedMoods) => setMoods(selectedMoods);

  const handleAgeSelect = (selectAge) => setUserRecommendationAge(selectAge);

  const handleMaritalSelect = (selectMarital) =>
    setUserRecommendationMaritalStatus(selectMarital);

  const handleBpmSelect = (selectBpm) => setUserRecommendationBPM(selectBpm);

  const handleStressLevelSelect = (selectedStressLevel) =>
    setStressLevel(selectedStressLevel);

  const handlefocusOnPresentMomentSelect = (
    selectfocusOnPresentMomentSelect
  ) => {
    setUserRecommendationFocusOnPresentMoment(selectfocusOnPresentMomentSelect);
  };
  const handleExperienceStressAnxiety = (selectExperienceStressAnxiety) => {
    setUserRecommendationExperienceStressAnxiety(selectExperienceStressAnxiety);
  };
  const handleUserSleep = (selectUserSleep) => {
    setUserRecommendationSleep(selectUserSleep);
  };
  const handleUserSleepHours = (selectUserSleepHours) => {
    setUserRecommendationHoursOfSleep(selectUserSleepHours);
  };

  const handleIrritatedOrAngry = (select) => {
    setUserRecommendationIrritatedOrAngry(select);
  };

  const handleLethargy = (select) => {
    setUserRecommendationFrequentLethargy(select);
  };

  const handleCommitWork = (select) => {
    setUserRecommendationCommitmentAtWork(select);
  };

  const handleIssueInRelationship = (select) => {
    setUserRecommendationIssuesInRelationship(select);
  };

  const handleEsteem = (select) => {
    setUserRecommendationLowSelfEsteem(select);
  };
  const handleHoursOnSocialMedia = (select) => {
    setUserRecommendationHoursSpendOnSocialMedia(select);
  };

  const handleGenderSelect = (select) => {
    setUserRecommendationGender(select);
  };

  useEffect(() => {
    for (let i in moods) {
      if (moods[i].label === "ALL") {
        if (moods.length !== 1) {
          setMoods([{ key: "ALL", label: "ALL", value: "ALL" }]);
        }
      }
    }
  }, [moods]);
  useEffect(() => {
    for (let i in stressLevel) {
      if (stressLevel[i].label === "ALL") {
        if (stressLevel.length !== 1) {
          setStressLevel([{ key: "ALL", label: "ALL", value: "ALL" }]);
        }
      }
    }
  }, [stressLevel]);

  useEffect(() => {
    for (let i in UserRecommendationAge) {
      if (UserRecommendationAge[i].label === "ALL") {
        if (UserRecommendationAge.length !== 1) {
          setUserRecommendationAge([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationAge]);

  useEffect(() => {
    for (let i in UserRecommendationMaritalStatus) {
      if (UserRecommendationMaritalStatus[i].label === "ALL") {
        if (UserRecommendationMaritalStatus.length !== 1) {
          setUserRecommendationMaritalStatus([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationMaritalStatus]);

  useEffect(() => {
    for (let i in UserRecommendationBPM) {
      if (UserRecommendationBPM[i].label === "ALL") {
        if (UserRecommendationBPM.length !== 1) {
          setUserRecommendationBPM([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationBPM]);

  useEffect(() => {
    for (let i in UserRecommendationFocusOnPresentMoment) {
      if (UserRecommendationFocusOnPresentMoment[i].label === "ALL") {
        if (UserRecommendationFocusOnPresentMoment.length !== 1) {
          setUserRecommendationFocusOnPresentMoment([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationFocusOnPresentMoment]);

  useEffect(() => {
    for (let i in UserRecommendationExperienceStressAnxiety) {
      if (UserRecommendationExperienceStressAnxiety[i].label === "ALL") {
        if (UserRecommendationExperienceStressAnxiety.length !== 1) {
          setUserRecommendationExperienceStressAnxiety([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationExperienceStressAnxiety]);

  useEffect(() => {
    for (let i in UserRecommendationSleep) {
      if (UserRecommendationSleep[i].label === "ALL") {
        if (UserRecommendationSleep.length !== 1) {
          setUserRecommendationSleep([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationSleep]);

  useEffect(() => {
    for (let i in UserRecommendationHoursOfSleep) {
      if (UserRecommendationHoursOfSleep[i].label === "ALL") {
        if (UserRecommendationHoursOfSleep.length !== 1) {
          setUserRecommendationHoursOfSleep([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationHoursOfSleep]);

  useEffect(() => {
    for (let i in UserRecommendationIrritatedOrAngry) {
      if (UserRecommendationIrritatedOrAngry[i].label === "ALL") {
        if (UserRecommendationIrritatedOrAngry.length !== 1) {
          setUserRecommendationIrritatedOrAngry([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationIrritatedOrAngry]);

  useEffect(() => {
    for (let i in UserRecommendationFrequentLethargy) {
      if (UserRecommendationFrequentLethargy[i].label === "ALL") {
        if (UserRecommendationFrequentLethargy.length !== 1) {
          setUserRecommendationFrequentLethargy([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationFrequentLethargy]);

  useEffect(() => {
    for (let i in UserRecommendationCommitmentAtWork) {
      if (UserRecommendationCommitmentAtWork[i].label === "ALL") {
        if (UserRecommendationCommitmentAtWork.length !== 1) {
          setUserRecommendationCommitmentAtWork([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationCommitmentAtWork]);

  useEffect(() => {
    for (let i in UserRecommendationIssuesInRelationship) {
      if (UserRecommendationIssuesInRelationship[i].label === "ALL") {
        if (UserRecommendationIssuesInRelationship.length !== 1) {
          setUserRecommendationIssuesInRelationship([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationIssuesInRelationship]);

  useEffect(() => {
    for (let i in UserRecommendationLowSelfEsteem) {
      if (UserRecommendationLowSelfEsteem[i].label === "ALL") {
        if (UserRecommendationLowSelfEsteem.length !== 1) {
          setUserRecommendationLowSelfEsteem([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationLowSelfEsteem]);

  useEffect(() => {
    for (let i in UserRecommendationGender) {
      if (UserRecommendationGender[i].label === "ALL") {
        if (UserRecommendationGender.length !== 1) {
          setUserRecommendationGender([
            { key: "ALL", label: "ALL", value: "ALL" },
          ]);
        }
      }
    }
  }, [UserRecommendationGender]);

  const [allCatData, setAllCatData] = useState([]);
  const getAllMediaCategory = async (params) => {
    const { data } = await getMediaApi(params);
    setAllCatData(data.data);
    setGetCategory(
      data.data.map((item) => {
        return {
          key: item._id,
          label: item.name,
          value: item._id,
        };
      })
    );
    setGetCategoryCount(data.count);
  };

  useEffect(() => {
    getAllMediaCategory({
      objectType: "CATEGORY",
      page: 1,
      limit: getCategoryCount,
    });
  }, [getCategoryCount]);
  const [selectedSubCat, setSelectedSubCat] = useState([])
  const getAllMediaSubCategory = async (params) => {
    const { data } = await getMediaApi(params);
    setSelectedSubCat(
      data.data.map(item=>{
        return{
          key: item._id,
          label: item.name,
          value: item._id,
        }
      })
    )
    // setGetSubCategory(
    //   data.data.map((item) => {
    //     return {
    //       key: item._id,
    //       label: item.name,
    //       value: item._id,
    //     };
    //   })
    // );
    setGetSubCategoryCount(data.count);
  };

  useEffect(() => {
    getAllMediaSubCategory({
      objectType: "SUBCATEGORY",
      page: 1,
      limit: getSubCategoryCount,
    });
  }, [getSubCategoryCount]);

  const getAllMediaTags = async (params) => {
    const { data } = await getMediaApi(params);
    setGetTags(
      data.data.map((item) => {
        return {
          key: item._id,
          label: item.name,
          value: item._id,
        };
      })
    );
    setGetTagsCount(data.count);
  };

  useEffect(() => {
    getAllMediaTags({ objectType: "TAG", page: 1, limit: getTagsCount });
  }, [getTagsCount]);

  // reset page
  const reset = () => {
    setThumbnail(null);
    setVideo(null);
    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      description: "",
      isFree: true,
      language: "en",
      objectType: "VIDEO",
      // UserRecommendationAge: "Select Age Group",
      // UserRecommendationMaritalStatus: "Select Marital Status",
      UserRecommendationMood: "Select Mood",
      UserRecommendationStress: "Select Stress Level",
      // UserRecommendationBPM: "Select BPM",
    });
    setMoods([]);
    setUserRecommendationAge([]);
    setUserRecommendationMaritalStatus([]);
    setUserRecommendationBPM([]);
    setUserRecommendationFocusOnPresentMoment([]);
    setUserRecommendationExperienceStressAnxiety([]);
    setUserRecommendationSleep([]);
    setUserRecommendationHoursOfSleep([]);
    setUserRecommendationIrritatedOrAngry([]);
    setUserRecommendationFrequentLethargy([]);
    setUserRecommendationCommitmentAtWork([]);
    setUserRecommendationIssuesInRelationship([]);
    setUserRecommendationLowSelfEsteem([]);
    setUserRecommendationHoursSpendOnSocialMedia([]);
    setUserRecommendationGender([]);
    setStressLevel([]);
    setCategories([]);
    setSubCategories([]);
    setTags([]);
    setThumbnailUploadPercentage(0);
    setAudioUploadPercentage(0);
    setThumbnailColor(null);
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  const filteredSubCat = () => {
    if (categories !== "") {
      const filteredCategory =
        allCatData &&
        allCatData.length > 0 &&
        allCatData?.filter((item) => item?._id === categories?.key);
        console.log(filteredCategory,'filteredCategory');
      const newSubCategory =
      selectedSubCat &&
      selectedSubCat.length > 0 &&
      selectedSubCat?.filter((subcategory) =>{
        return filteredCategory[0]?.refIds?.includes(subcategory?.key)}
    
        );
        console.log(newSubCategory,'filteredCategory');
      setGetSubCategory(newSubCategory);
      
     
    }
    console.log(getSubCategory,'getSubCategory');
  };
  useEffect(() => {
    filteredSubCat();
  }, [categories]);

  // Create Music
  const handleSubmit = async () => {
    if (!thumbnail || !video)
      return setError({
        isError: true,
        errorMessage: "Please select thumbnail and Audio",
        errorType: "warning",
      });
    if (moods.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select Moods.",
        errorType: "warning",
      });
    if (stressLevel.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select Stress Levels.",
        errorType: "warning",
      });
    if (categories.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select Category",
        errorType: "warning",
      });
    if (UserRecommendationGender.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select Gender",
        errorType: "warning",
      });
    if (UserRecommendationAge.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select Age",
        errorType: "warning",
      });
    if (UserRecommendationMaritalStatus.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select Marital Status",
        errorType: "warning",
      });
    if (UserRecommendationBPM.length === 0)
      return setError({
        isError: true,
        errorMessage: "Please select BPM Status",
        errorType: "warning",
      });

    try {
      let img115x150Url = null;
      let img145x345Url = null;
      let img180x160Url = null;
      let img155x135Url = null;
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      if (img115x150)
        img115x150Url = await uploadFile(
          img115x150,
          fileTypes.IMG_115X150,
          setImg115x150UploadPercent,
          5
        );
      else setImg115x150UploadPercent(5);

      if (img145x345)
        img145x345Url = await uploadFile(
          img145x345,
          fileTypes.IMG_145X345,
          setImg145x345UploadPercent,
          5
        );
      else setImg145x345UploadPercent(5);

      if (img180x160)
        img180x160Url = await uploadFile(
          img180x160,
          fileTypes.IMG_180X160,
          setImg180x160UploadPercent,
          5
        );
      else setImg180x160UploadPercent(5);

      if (img155x135)
        img155x135Url = await uploadFile(
          img155x135,
          fileTypes.IMG_155X135,
          setImg155x135UploadPercent,
          5
        );
      else setImg155x135UploadPercent(5);

      const thumbnailUrl = await uploadFile(
        thumbnail,
        fileTypes.TRACK_IMAGE,
        setThumbnailUploadPercentage,
        10
      );

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });
      const audioDuration = await getAudioDuration(video);
      const audioUrl = await uploadFile(
        video,
        "TRACK_VIDEO",
        setAudioUploadPercentage,
        50
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
      const {data} = await createMusicReq({
        title,
        description,
        isFree,
        language,
        objectType,
        // UserRecommendationGender,
        UserRecommendationAge: formatMultiSelectData(UserRecommendationAge),
        UserRecommendationMaritalStatus: formatMultiSelectData(
          UserRecommendationMaritalStatus
        ),
        UserRecommendationGender: formatMultiSelectData(
          UserRecommendationGender
        ),
        UserRecommendationBPM: formatMultiSelectData(UserRecommendationBPM),
        UserRecommendationFocusOnPresentMoment: formatMultiSelectData(
          UserRecommendationFocusOnPresentMoment
        ),
        UserRecommendationExperienceStressAnxiety: formatMultiSelectData(
          UserRecommendationExperienceStressAnxiety
        ),
        UserRecommendationSleep: formatMultiSelectData(UserRecommendationSleep),
        UserRecommendationHoursOfSleep: formatMultiSelectData(
          UserRecommendationHoursOfSleep
        ),
        UserRecommendationIrritatedOrAngry: formatMultiSelectData(
          UserRecommendationIrritatedOrAngry
        ),
        UserRecommendationFrequentLethargy: formatMultiSelectData(
          UserRecommendationFrequentLethargy
        ),
        UserRecommendationCommitmentAtWork: formatMultiSelectData(
          UserRecommendationCommitmentAtWork
        ),
        UserRecommendationIssuesInRelationship: formatMultiSelectData(
          UserRecommendationIssuesInRelationship
        ),
        UserRecommendationLowSelfEsteem: formatMultiSelectData(
          UserRecommendationLowSelfEsteem
        ),
        UserRecommendationHoursSpendOnSocialMedia: formatMultiSelectData(
          UserRecommendationHoursSpendOnSocialMedia
        ),
        thumbnailColorPalette: thumbnailColor || "#000000",
        UserRecommendationMood: formatMultiSelectData(moods),
        UserRecommendationStress: formatMultiSelectData(stressLevel),
        level: level,
        
        categorie: categories.value,
        subCategories: formatMultiSelectData(subCategories) ? formatMultiSelectData(subCategories) : [],
        tags: formatMultiSelectData(tags),
        assetURL: audioUrl,
        oriURL: audioUrl,
        assetImage: thumbnailUrl,
        runtime: audioDuration,
        img115x150: img115x150Url,
        img145x345: img145x345Url,
        img180x160: img180x160Url,
        img155x135: img155x135Url,
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });

      console.log(data?.data?.assetURL?.split("TRACK_VIDEO"),'TRACK_VIDEO')
      await transCodingApi({
        assetURL:data?.data?.assetURL,
        title:data?.data?.title,
        description:data?.data?.description,
        assetImage:data?.data?.assetImage,
        id: data?.data?._id,
        type:'MEDIA'
      })
    } catch (error) {
      console.log(error);
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setAudioUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Music please try again later",
        errorType: "danger",
      });
    }
  };

  return (
    <div>
      {isCreating ? (
        <UploadingMusicLoader
          currentStep={creatingStage}
          thumbnailuploadPercentage={
            thumbnailuploadPercentage +
            img115x150UploadPercent +
            img145x345UploadPercent +
            img155x135UploadPercent +
            img180x160UploadPercent
          }
          audioUploadPercentage={audioUploadPercentage}
          reset={reset}
          mediaVideo = {true}
          
        />
      ) : (
        <MusicForm
          handelInput={handelInput}
          title={title}
          language={language}
          objectType={objectType}
          description={description}
          level={level}
          handleLevelSelect={handleLevelSelect}
          UserRecommendationGender={UserRecommendationGender}
          handleGenderSelect={handleGenderSelect}
          genderData={genderData}
          UserRecommendationAge={UserRecommendationAge}
          ageData={ageData}
          handleAgeSelect={handleAgeSelect}
          UserRecommendationMaritalStatus={UserRecommendationMaritalStatus}
          maritalData={maritalData}
          handleMaritalSelect={handleMaritalSelect}
          UserRecommendationMood={UserRecommendationMood}
          UserRecommendationBPM={UserRecommendationBPM}
          bpmData={bpmData}
          handleBpmSelect={handleBpmSelect}
          UserRecommendationStress={UserRecommendationStress}
          handleMoodsSelect={handleMoodsSelect}
          moods={moods}
          handleStressLevelSelect={handleStressLevelSelect}
          stressLevel={stressLevel}
          handleCategoriesSelect={handleCategoriesSelect}
          UserRecommendationFocusOnPresentMoment={
            UserRecommendationFocusOnPresentMoment
          }
          focusOnPresentMomentData={focusOnPresentMomentData}
          handlefocusOnPresentMomentSelect={handlefocusOnPresentMomentSelect}
          UserRecommendationExperienceStressAnxiety={
            UserRecommendationExperienceStressAnxiety
          }
          experienceStressAnxietyData={experienceStressAnxietyData}
          handleExperienceStressAnxiety={handleExperienceStressAnxiety}
          UserRecommendationSleep={UserRecommendationSleep}
          sleepData={sleepData}
          handleUserSleep={handleUserSleep}
          UserRecommendationHoursOfSleep={UserRecommendationHoursOfSleep}
          sleepHoursData={sleepHoursData}
          handleUserSleepHours={handleUserSleepHours}
          UserRecommendationIrritatedOrAngry={
            UserRecommendationIrritatedOrAngry
          }
          irritatedOrAngryData={irritatedOrAngryData}
          handleIrritatedOrAngry={handleIrritatedOrAngry}
          UserRecommendationFrequentLethargy={
            UserRecommendationFrequentLethargy
          }
          lethargyData={lethargyData}
          handleLethargy={handleLethargy}
          UserRecommendationCommitmentAtWork={
            UserRecommendationCommitmentAtWork
          }
          commitWorkData={commitWorkData}
          handleCommitWork={handleCommitWork}
          UserRecommendationIssuesInRelationship={
            UserRecommendationIssuesInRelationship
          }
          issueRelationshipData={issueRelationshipData}
          handleIssueInRelationship={handleIssueInRelationship}
          UserRecommendationLowSelfEsteem={UserRecommendationLowSelfEsteem}
          esteemData={esteemData}
          handleEsteem={handleEsteem}
          UserRecommendationHoursSpendOnSocialMedia={
            UserRecommendationHoursSpendOnSocialMedia
          }
          hoursOnSocialData={hoursOnSocialData}
          handleHoursOnSocialMedia={handleHoursOnSocialMedia}
          categories={categories}
          handleSubCategoriesSelect={handleSubCategoriesSelect}
          subCategories={subCategories}
          handleTagsSelect={handleTagsSelect}
          tagsData={tagsData}
          tags={tags}
          moodData={moodData}
          stressLevelData={stressLevelData}
          categoryData={categoryData}
          subCategoryData={subCategoryData}
          setThumbnail={setThumbnail}
          thumbnail={thumbnail}
          video={video}
          setVideo={setVideo}
          handleSubmit={handleSubmit}
          isError={isError}
          errorMessage={errorMessage}
          errorType={errorType}
          isFree={isFree}
          setThumbnailColor={setThumbnailColor}
          thumbnailColor={thumbnailColor}
          img115x150={img115x150}
          setImg115x150={setImg115x150}
          img145x345={img145x345}
          setImg145x345={setImg145x345}
          img155x135={img155x135}
          setImg155x135={setImg155x135}
          img180x160={img180x160}
          setImg180x160={setImg180x160}
          getCategory={getCategory}
          setGetCategory={setGetCategory}
          getSubCategory={getSubCategory}
          setGetSubCategory={setGetSubCategory}
          getTags={getTags}
          setGetTags={setGetTags}
          getCategoryCount={getCategoryCount}
          setGetCategoryCount={setGetCategoryCount}
          getSubCategoryCount={getSubCategoryCount}
          setGetSubCategoryCount={setGetSubCategoryCount}
          getTagsCount={getTagsCount}
          setGetTagsCount={setGetTagsCount}
          subCat={false}
          mediaVideo= {true}
          editMediaVideo={true}
        />
      )}
    </div>
  );
};

export default CreateVedio;
