import AllNutriLive from "../Components/AllNutriLive";
import AllUserByNutriId from "../Components/AllUserByNutri";
import EditNutriLive from "../Components/EditNutriLive";
import AllHomeSessions from "../Components/HomeSessions/AllHomeSessions";
import AllUserByHomeSessions from "../Components/HomeSessions/AllUserByHomeSess";
import EditHomeSessions from "../Components/HomeSessions/EditHomeSessions";

const NutriLiveRoutes = [ 
  
  {
    Component: AllNutriLive,
    path: '/nutri-clinic/all',
    exact: true,
    id: 'dashboardNutriLiveAll',
    routePath: '/dashboard/n-live/nutri-clinic/all',
    name: 'All Nutri Class',
  },
  {
    Component: EditNutriLive,
    path: '/nutri-clinic/edit/:userId',
    exact: true,
    id: 'dashboardNutriLiveEdit',
  },
  {
    Component: AllUserByNutriId,
    path: '/nutri-clinic/all-nutri-user/:nutriId',
    exact: true,
    id: 'dashboardNutriLiveEditUser',
  },
  {
    Component: AllHomeSessions,
    path: '/booking-sessions/all',
    exact: true,
    id: 'dashboardNutriLiveAll',
    routePath: '/dashboard/n-live/booking-sessions/all',
    name: 'All Booking Sessions',
  },
  {
    Component: EditHomeSessions,
    path: '/booking-sessions/edit/:userId',
    exact: true,
    id: 'dashboardNutriLiveEdit',
  },
  {
    Component: AllUserByHomeSessions,
    path: '/booking-sessions/all-booking-sessions-user/:sessionsId',
    exact: true,
    id: 'dashboardNutriLiveEditUser',
  },
]

export default NutriLiveRoutes