import AllPrograms from "../Components/AllPrograms";
import EditAllPrograms from "../Components/EditPrograms";

export const ProgramRoutes = [
  
  {
    Component: AllPrograms,
    path: "/all",
    exact: true,
    id: "dashboardProgramAll",
    routePath: "/dashboard/programs/all",
    name:"Today's Programs"
  },
  {
    Component: EditAllPrograms,
    path: '/edit/:programId',
    exact: true,
    id: 'dashboardProgramEdit',
  },

]


