import React from "react";
import { useState, useEffect } from "react";
import PubSessionComp from "../../../../../../Components/AllPubSessionComp";
import UnPubSessionComp from "../../../../../../Components/AllUnPubSessionComp";
import {
  PubSessionTableHeading,
  UnPubSessionTableHeading,
} from "../../Constants/constants";
import {
  getAllBookingSessions,
  getAllBookingSessionsUnpublished,
  publishSession,
  UnpublishSession,
} from "../../Constants/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SweetAlert from "react-bootstrap-sweetalert";

const AllSessions = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [publishedSession, setPublishedSession] = useState("");
  const [unpublishedSession, setUnpublishedSession] = useState("");
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;
  const [unpublishPage, setUnpublishPage] = useState({
    unpublishPageCurrentPage: 1,
    unpublishPageTotalSize: 10,
  });

  const { unpublishPageCurrentPage, unpublishPageTotalSize } = unpublishPage;

  const [publishID, setPublishID] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);

  const getAllPublishedBookingSessions = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllBookingSessions(params);
      setPublishedSession(data?.data);
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllUnPublishedBookingSessions = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllBookingSessionsUnpublished(params);
      setUnpublishedSession(data?.data);
      setUnpublishPage({
        unpublishPageCurrentPage: params?.page,
        unpublishPageTotalSize: data?.count,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePublishSessionClick = async (id) => {
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishSessionClick = async (id) => {
    setPublishID(id);
    setShowPublishAlert(true);
  };
  const handleTablePageChange = (type,{ page, sizePerPage }) => {
    console.log(page, sizePerPage, "fjhfjhfhfjh");
    getAllPublishedBookingSessions({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };
  const handleTablePageChangeUnpublish = (type,{ page, sizePerPage }) => {
    getAllUnPublishedBookingSessions({ page, limit: sizePerPage });
    setUnpublishPage({
      ...unpublishPage,
      unpublishPageCurrentPage: page,
    });
  };
  const publishSessionAction = async () => {
    await UnpublishSession(publishID);
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
    const filteredSeries = publishedSession.filter(
      ({ _id }) => _id !== publishID
    );
    setPublishedSession(filteredSeries);
    setPublishID("");
  };

  const UnPublishSessionAction = async () => {
    await publishSession(publishID);
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
    const filteredSeries = unpublishedSession.filter(
      ({ _id }) => _id !== publishID
    );
    setUnpublishedSession(filteredSeries);
    setPublishID("");
  };

  useEffect(() => {
    getAllPublishedBookingSessions({ page: 1, limit: 10 });
    getAllUnPublishedBookingSessions({ page: 1, limit: 10 });
  }, []);

  const handleEditSeries = (id) => {
    history.push("/dashboard/booking-session/edit/" + id);
  };
  return (
    <>
      <PubSessionComp
        tableData={publishedSession}
        tableHeading={PubSessionTableHeading}
        handleTablePageChange={handleTablePageChange}
        handlePublishSessionClick={handlePublishSessionClick}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        handleEditSeries={handleEditSeries}
      />
      <UnPubSessionComp
        tableData={unpublishedSession}
        tableHeading={UnPubSessionTableHeading}
        handleTablePageChangeUnpublish={handleTablePageChangeUnpublish}
        handleUnPublishSessionClick={handleUnPublishSessionClick}
        isLoading={isLoading}
        page={unpublishPageCurrentPage}
        sizePerPage={10}
        totalSize={unpublishPageTotalSize}
        handleEditSeries={handleEditSeries}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishSessionAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Sessions has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,publish it!"
          reverseButtons={true}
          onConfirm={UnPublishSessionAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowPublishConfirm(false);
            window.location.reload();
          }}
        >
          Sessions has been published
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllSessions;
