import React, { useState, useEffect } from "react";

import PlanCard from "../../../../../Components/PlanCard";
import { getAllPlanReq, searchPlanReq } from "../Plans.Apis";
import ViewPlanModal from "../../../../../Modal/ViewPlan";
import { viewPlan } from "../../../../../Modal/ViewPlan/ViewPlan.Api";

const AllPlans = () => {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [planID, setPlanID] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [modalData, setModalData] = useState({});
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
  });
  const { currentPage, totalSize } = paginationValue;

  const getAllPlans = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getAllPlanReq(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setAllPlans(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("\x1b[34m%s\x1b[0m", "AllPlans.js error", error);
    }
  };

  useEffect(() => {
    getAllPlans({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const toggleViewPlanModal = () => setIsOpen(!isOpen);

  const getSearchData = async (text) => {
    try {
      const { data } = await searchPlanReq(text);
      setCurrentSearch(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllPlans({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleViewPlan = async (id) => {
    setShow(false);
    try {
      setIsLoading(true);
      const { data } = await viewPlan(id);
      setModalData(data.data);
      setIsLoading(false);
      setIsEditable(false);
      toggleViewPlanModal();
    } catch {}
  };

  const handleEditPlan = async (id) => {
    try {
      setIsLoading(true);
      setPlanID(id);
      const { data } = await viewPlan(id);
      setModalData(data.data);
      setIsEditable(true);
      setIsLoading(false);
      toggleViewPlanModal();
    } catch {}
  };

  const populatePlansData = (plan) => {
    setAllPlans([plan, ...allPlans.filter(({ _id }) => _id !== plan._id)]);
  };

  const select_music = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };
  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };
  const selector1 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#1cbb8c",
    borderRadius: "50%",
    marginRight: "1rem",
  };
  const selector3 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "red",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <ViewPlanModal
          planID={planID}
          isOpen={isOpen}
          toggleModal={toggleViewPlanModal}
          isEditable={isEditable}
          data={modalData}
          setData={setModalData}
          isLoading={isLoading}
          populatePlansData={populatePlansData}
        />
        <h2 style={{ margin: "0px" }}>All Plans</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Plans"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_music}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleViewPlan(item._id)}
                      >
                        {item && item.isDisabled === false && (
                          <div style={selector1}></div>
                        )}
                        {item && item.isDisabled === true && (
                          <div style={selector3}></div>
                        )}
                        <div>{item ? item.plan_identifier : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <PlanCard
        tableData={allPlans}
        setTableData={setAllPlans}
        tableHeading={"All Plans"}
        handleViewPlan={handleViewPlan}
        handleEditPlan={handleEditPlan}
        isLoading={isLoading}
        toggleModal={toggleViewPlanModal}
        data={modalData}
        setData={setModalData}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />
    </>
  );
};

export default AllPlans;
