import axios from "axios";
// import baseDomain, { generateCred } from '../../../../Constants/apiRoutes'
import baseDomain, {
  generateCred,
  secureStsCredApi,
  secureStsVerifyApi,
} from "../../../../../../Constants/apiRoutes";

export const getSecureStsCred = (params) => {
  return axios.get(`${baseDomain}${secureStsCredApi}`, {
    params,
  });
};

export const regenerateSecureStsToken = () => {
  return axios.get(`${baseDomain}${secureStsVerifyApi}`);
};
