import React from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import { MediaPropertiesRouts } from "./Constants/routes";

const MediaProperties = () => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {MediaPropertiesRouts.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/media-properties/tags" />
      </Switch>
    </>
  );
};

export default MediaProperties;
