import React, { useState, useEffect } from "react";
import CouponForm from "../../../../../Components/CouponForm";
import { getAllPlanReq } from "../../Plan/Plans.Apis";
import { useLocation } from "react-router-dom";

const CreateCoupon = () => {
  const [plans, setPlans] = useState([]);
  const location = useLocation();
  const [data,setData] = useState({
    heading:"Create Coupon",
    couponData:null,
    buttonText:"Create Coupon",
    update:false,
  });

  useEffect(()=>{
    if(location.state){
      const d= location.state
      setData({
        ...data,
        heading:d.heading,
        couponData:d.couponData,
        buttonText:d.buttonText,
        update:d.update
      })
    }
  },[location.state])
  

  useEffect(() => {
    getAllPlans();
  }, []);
  const getAllPlans = async () => {
    const {
      data: { data },
    } = await getAllPlanReq({ page: 1, limit: 20 });
    setPlans(data);
  };


  const {heading,couponData,buttonText,update} = data ;

  return (
    <CouponForm
      plansList={plans}
      pageHeading={heading}
      couponData={couponData}
      buttonText={buttonText}
      update={update}
    />
  );
};

export default CreateCoupon;
