import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import React, { useState } from "react";

const YtVideoForm = ({
  createVideoHandle,
  formData,
  setFormData,
  setCreateAlert,
}) => {
  const [error, setError] = useState(0);
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    formData.title === ""
      ? setError(1)
      : formData.thumbnailPic === ""
      ? setError(2)
      : formData.url === ""
      ? setError(3)
      : setCreateAlert(true);
  };
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Add Videos</h3>
                  </div>
                </Col>
              </Row>
              <AvForm className="form-horizontal">
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter video title"
                        value={formData.title}
                        onChange={handelInput}
                        type="text"
                        errorMessage="Enter title"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="videoTitle"
                      />
                    </FormGroup>
                   
                    {error === 1 && (
                  <p style={{ fontSize: "small", color: "red" }}>
                    Please enter video title
                  </p>
                )}
                    <FormGroup>
                      <Label htmlFor="title">Add video thumbnail url</Label>
                      <AvField
                        name="thumbnailPic"
                        placeholder="Enter video thumbnail url"
                        value={formData.thumbnailPic}
                        onChange={handelInput}
                        type="text"
                        errorMessage="Thumbnail url is required"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="video_thumbnail"
                      />
                    </FormGroup>
                    {error === 2 && (
                  <p style={{ fontSize: "small", color: "red" }}>
                    Please enter video thumbnail url
                  </p>
                )}
                  
                  </Col>
                </Row>

                <FormGroup>
                  <Label htmlFor="title">Add video embedded url</Label>
                  <AvField
                    name="url"
                    placeholder="Enter video url"
                    value={formData.url}
                    onChange={handelInput}
                    type="text"
                    errorMessage="Video url is required"
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id="video_url"
                  />
                </FormGroup>
                {error === 3 && (
                  <p style={{ fontSize: "small", color: "red" }}>
                    Please enter video url
                  </p>
                )}
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  // type="submit"
                  // disabled={isDisabledBtn}
                  onClick={handleSubmit}
                >
                  Add video
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default YtVideoForm;
