import axios from "axios";

import baseDomain, { mediaTagCatAndSubCatAPI } from "../../Constants/apiRoutes";

// export const editTag = (data, tagID) => {
//   return axios.put(`${baseDomain}${tagAPI}/${tagID}`, data);
// };
export const editApi = (id, data) => {
  console.log(data, "yyyyyyyyyyyyyyy", id);
  return axios.put(`${baseDomain}${mediaTagCatAndSubCatAPI}/${id}`, data);
};
export const createMediaDropdownListApi = ({page,limit}) => {
  return axios.get(`${baseDomain}/media/getAllAudioVideo?page=${page}&limit=${limit}`);
};

export const editMappingApi = (ID, data)=>{
  return axios.put(`${baseDomain}/mediatags/categorymapping/${ID}`, data)
}
