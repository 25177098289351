import AllPlaylists from "../Components/All playlist";
import CreatePlaylist from "../Components/Create playlist";
import EditPlaylist from "../Components/Edit playlist";

export const PlaylistRoutes =[
  {
    Component: AllPlaylists,
    path: "/all",
    exact: true,
    id: "dashboardPlaylistAll",
    routePath: "/dashboard/playlist/all",
    name: "All playlist"
  },
  {
    Component: CreatePlaylist,
    path: "/create",
    exact: true,
    id: "dashboardPlaylistCreate",
    routePath: "/dashboard/playlist/create",
    name: "Create playlist"
  },
  {
    Component: EditPlaylist,
    path : "/editplaylist/:playlistId",
    exact: true,
    id: "dashboardEditPlaylist",
  }

]