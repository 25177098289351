import axios from "axios"
import { baseDomain, stage } from "../../../../../Constants/apiRoutes"

export const getAllAppointmentApi = ({ page, limit}) =>{
  return axios.get(`${baseDomain}${stage}/nutriclinic/appointments?page=${page}&limit=${limit}`)
}

export const updateAppointmentApi = (id, payload) =>{
  return axios.put(`${baseDomain}${stage}/nutriclinic/updateNutriAppointment/${id}`, payload)
}

// export const deleteAppointment = (id) => {
//   return axios.delete(`${baseDomain}${stage}/nutriClinic/delete/${id}`)
// }

export const getUserAppointmentByID = (id) =>{
  return axios.get(`${baseDomain}${stage}/nutriclinic/appointment/${id}`)
}