export const sleepSessionTab = [
  {
    text: "Order.No.",
    dataField: "orderNo",
  },

  {
    text: "Questions",
    dataField: "question",
  },
];
export const sleepSessionTabUnpublish = [
  {
    text: "Order.No",
    dataField: "orderNo"
  },

  {
    text: "Questions",
    dataField: "question"
  }
]