import React, { useEffect, useState } from "react";
import AllNutriLiveComp from "../../../../../../../Components/AllNutriLiveComp";
import { getAllNutriClassApi, getCSVData } from "../../Constants/api";
import { allNutriClassiHeading } from "../../Constants/table";

const AllNutriLive = () => {
 

  const [nutriClass, setNutriClass] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = publishPage;
  const [csvData,setCsvData] = useState([])

  const getAllNutriClassData = async(params) =>{
    try {
      setIsLoading(true)
      const {data} = await getAllNutriClassApi(params)
      setNutriClass(data?.data)
      console.log(data?.data,'getAllNutriClassApi');
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      setIsLoading(false)
    } catch (error) {
      console.log(error,'error')
    }
  }

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllNutriClassData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  useEffect(()=>{
    getAllNutriClassData({page:1 , limit:10})
    getAllUsersDataCsv()
  },[])

  const getAllUsersDataCsv = async (params) => {
    try {
      const { data } = await getCSVData(params);
      setCsvData(data?.data)
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  };
console.log(csvData,'printData')

const handleAllExportToCSV = () => {
  const extractLearningTitles = (learningArray) => {
      return learningArray.map(learning => learning.title).join(', ');
  };

  const csvDataAll = csvData.map((item) => ({
      AppointmentDate: item.timeSlot.date.substring(0, 10),
      AppointmentTime: item.timeSlot.time.substring(11, 16),
      Id: item._id,
      UserId: item.userId._id,
      UserFirstName: item.userId.firstName,
      UserLastName: item.userId.lastName,
      UserPhoneNumber: item.userId.phoneNumber,
      UserEmail: item.userId.email,
      SupportAdvisorId: item.supportAdvisorId._id,
      SupportAdvisorName: item.supportAdvisorId.name,
      SupportAdvisorDesignation: item.supportAdvisorId.designation,
      SupportAdvisorRating: item.supportAdvisorId.rating,
      SupportAdvisorExperience: item.supportAdvisorId.experience,
      SupportAdvisorSpeciality: item.supportAdvisorId.speciality.join('; '),
      SupportAdvisorNoOfPatients: item.supportAdvisorId.noOfPatients,
      SupportAdvisorAdvisorType: item.supportAdvisorId.advisorType,
      SupportAdvisorIsDisabled: item.supportAdvisorId.isDisabled,
      SupportAdvisorCreatedAt: item.supportAdvisorId.createdAt.substring(0, 10),
      SupportAdvisorUpdatedAt: item.supportAdvisorId.updatedAt.substring(0, 10),
      IapId: item.IAPId,
      PlanId: item.planId._id,
      PlanDescription: item.planId.plan_description,
      PlanDisplayPrice: item.planId.plan_display_price,
      PlanIdentifier: item.planId.plan_identifier,
      NutriId: item.nutriId._id,
      NutriTitle: item.nutriId.title,
      NutriImage: item.nutriId.imgAsset,
      NutriFAQId: item.nutriId.faqId.join('; '),
      NutriIsPublished: item.nutriId.isPublished,
      NutriCreatedAt: item.nutriId.createdAt.substring(0, 10),
      NutriUpdatedAt: item.nutriId.updatedAt.substring(0, 10),
      Type: item.type,
      ExpiryDate: item.expDate.substring(0, 10),
      CreatedAt: item.createdAt.substring(0, 10),
      UpdatedAt: item.updatedAt.substring(0, 10),
  }));

  return csvDataAll;
};


  return (
    <>
      <AllNutriLiveComp
         tableData={nutriClass}
         tableHeading={allNutriClassiHeading}
         handleTablePageChange={handleTablePageChange}
         isLoading={isLoading}
         page={currentPage}
         sizePerPage={10}
         totalSize={totalSize}
         handleAllExportToCSV={handleAllExportToCSV}
      />
    </>
  );
};

export default AllNutriLive;
