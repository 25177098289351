export const PubSessionTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  

]

export const UnPubSessionTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  
 
]