import axios from "axios";

import baseDomain, {
    couponAPI,
    createCouponPost,
    deleteCouponApi,
    updateCouponApi
} from "../../../../Constants/apiRoutes";

export const getAllCouponReq = () => {
    return axios.get(`${baseDomain}${couponAPI}`);
};


export const createCouponReq = (payload) => {
    return axios.post(`${baseDomain}${createCouponPost}`, payload);
};

export const deleteCoupon = (couponId) => {
    return axios.delete(`${baseDomain}${deleteCouponApi}/${couponId}`);
};
export const updateCouponReq = (couponId, payload) => {
    return axios.put(`${baseDomain}${updateCouponApi}/${couponId}`,payload);
};