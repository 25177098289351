import axios from "axios";

import baseDomain, {
  userApi,
  searchUserAPI,
  userAllAPI,
} from "../../../../Constants/apiRoutes";

export const getUser = (params) => {
  return axios.get(`${baseDomain}${userApi}`, { params });
};

export const searchUser = (text) => {
  return axios.get(`${baseDomain}${searchUserAPI}/${text}`);
};

export const disableUser = (userID) => {
  return axios.delete(`${baseDomain}${userApi}/${userID}`);
};

export const GetAllUsers = () => {
  return axios.get(`${baseDomain}${userAllAPI}`);
};

export const GetAllUsersByDate = (startDate, endDate) => {
  return axios.get(`${baseDomain}${userApi}?startDate=${startDate}&endDate=${endDate}&paginate=false`);
};

export const getAllUsersDataByDateReq = (startDate, endDate ) => {
  return axios.get(`${baseDomain}${userApi}?startDate=${startDate}&endDate=${endDate}`);
};
