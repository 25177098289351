import OSS from "ali-oss";

import { getSecureStsCred, regenerateSecureStsToken } from "./upload.Apis";

const getOssClient = ({
  securityToken,
  accessKeyId,
  accessKeySecret,
  ossBucketName,
  ossBucketRegion,
}) => {
  const client = new OSS({
    region: ossBucketRegion,
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket: ossBucketName,
    // Refresh the temporary access credential.
    refreshSTSToken: async () => {
      const { data } = await regenerateSecureStsToken();
      return {
        accessKeyId: data.data.accessKeyId,
        accessKeySecret: data.data.accessKeySecret,
        stsToken: data.data.securityToken,
      };
    },
  });

  return client;
};

export const formatMultiSelectData = (data) => {
  const formatedData = data.map(({ value }) => value);
  return formatedData;
};

// AUDIO_IMAGE
export const uploadFile = (file, type, onProgress, percentageW) =>{
  ; 
  return new Promise(async (resolve, reject) => {
    try {
      let uploadFile = file;
      const { data } = await getSecureStsCred({
        name: uploadFile.name,
        type,
      });

      const client = getOssClient(data.data);
    
      // const { url } = await client.put(data.data.fileLocation, uploadFile); // example for simple upload

      const { name, bucket } = await client.multipartUpload(
        data.data.fileLocation,
        uploadFile,
        {
          progress: (p) => {
            onProgress(p * percentageW);
          },
          partSize: 1024 * 1024 * 1, // this will create a 1MB chunk of file
          parallel: 3,
        }
      );
      resolve(
        `https://${bucket}.${data.data.ossBucketRegion}.aliyuncs.com/${name}`
      );
    } catch (error) {
      reject({
        error: true,
        errorMessage: "Unable to upload data try again later",
      });
    }
  });
}

export const formatArray = (arr) => {
  let updatedArray = [];
  for (let i in arr) {
    updatedArray.push({ key: arr[i], label: arr[i], value: arr[i] });
  }
  return updatedArray;
};
