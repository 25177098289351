import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";

const DiscoverUnPublished = ({
  tableData = [],
  tableHeading = [],
  handleViewDiscoverNispand,
  handleEditDiscoverNispand,
  setUnPublishedDiscoverNispand,
  handleUnPublishDiscoverNispand,
  handleTablePageChange,
  page,
  sizePerPage,
  isLoading,
  unpublishedTotalSize,
}) => {
  const [rowData, setRowData] = useState([]);
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        {/* <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewDiscoverNispand(row._id)}
        /> */}
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditDiscoverNispand(row)}
        />
      </div>
    );
  };

  const viewDiscoverNispandFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleUnPublishDiscoverNispand(row._id)}
        >
          Publish
        </Button>
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewContentFormatter,
    });
    tableHeading.push({
      text: "Publish DiscoverNispand",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewDiscoverNispandFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = {
          ...el,
          name: el.title,
          description:
            el.description?.replace(/<\/?p>/g, "").length > 50
              ? el.description?.replace(/<\/?p>/g, "").slice(0, 50) + "..."
              : el.description?.replace(/<\/?p>/g, ""),
          mediaType: el.objectType,
        };
        
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">UnPublished Discover</h3>
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  ></div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={unpublishedTotalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DiscoverUnPublished;
