import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import MusicCard from "../../../../../../Components/MusicCard";
import CreatedMusic from "../../../../../../Components/CreatedMusic";
import {
  PublishedMusicTableHeading,
  CreatedMusicTableHeading,
} from "../../Music.Constants";

import {
  // getAllMusicReq,
  deleteMusicReq,
  publishMusic,
  unpublishMusic,
  createdMusicReq,
  publishedMusicReq,
  searchMusic,
} from "../../Music.Apis";
import { useHistory } from "react-router-dom";

const AllMusic = () => {
  const [ errorRes, setErrorRes] = useState('')
  const [showErr, setShowErr] = useState(false)
  const [createdMusics, setCreatedMusics] = useState([]);
  const [publishedMusics, setPublishedMusics] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [publishID, setPublishID] = useState("");
  const [showUnpublishAlert, setShowUnpublishAlert] = useState(false);
  const [showUnpublishConfirm, setShowUnpublishConfirm] = useState(false);
  const [unpublishID, setUnpublishID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdPaginationValue, setCreatedPaginationValue] = useState({
    createdCurrentPage: 1,
  });
  const { createdCurrentPage, createdTotalSize } = createdPaginationValue;

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
  });
  const { currentPage, totalSize } = paginationValue;
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);

  const history = useHistory();

  // Get all music data
  const getCreatedMusicData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await createdMusicReq(queryParams);
      setCreatedPaginationValue({
        createdCurrentPage: queryParams.page,
        createdTotalSize: data.count,
      });
      setCreatedMusics(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCreatedMusicData({ page: 1, limit: 10 , objectType : 'AUDIO' });
    // eslint-disable-next-line
  }, []);

  const getPublishedMusicData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await publishedMusicReq(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setPublishedMusics(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getPublishedMusicData({ page: 1, limit: 10 , objectType : 'AUDIO' });
    // eslint-disable-next-line
  }, []);

  const getSearchData = async (text) => {
    try {
      const { data } = await searchMusic(text);
      setCurrentSearch(data.data);
    } catch (error) {}
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleEditMusicClick = (musicID) => {
    history.push("/dashboard/media/edit/" + musicID);
  };

  const handleViewMusicClick = (musicID) => {
    history.push("/dashboard/media/view/" + musicID);
  };

  const handleAlternateMusic = (musicID) => {
    history.push("/dashboard/media/alternate/"+ musicID);
  };

  const handleUnpublishMusicClick = async (musicID) => {
    setUnpublishID(musicID);
    setShowUnpublishAlert(true);
  };

  const unpublishMusicAction = async () => {
    try {
      await unpublishMusic(unpublishID);
    const filteredMusics = publishedMusics.filter(
      ({ _id }) => _id !== unpublishID
    );
    setPublishedMusics(filteredMusics);
    setUnpublishID("");
    setShowUnpublishAlert(false);
    setShowUnpublishConfirm(true);
    } catch (error) {
    console.log(error.response.data.error, "error.response.data.error");
    setErrorRes(error.response.data.error);
    setShowErr(true)
      
    }
  };

  const handlePublishMusicClick = async (musicID) => {
    setPublishID(musicID);
    setShowPublishAlert(true);
  };

  const publishMusicAction = async () => {
    await publishMusic(publishID);
    const filteredMusics = createdMusics.filter(({ _id }) => _id !== publishID);
    setCreatedMusics(filteredMusics);
    setPublishID("");
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
  };

  const handleDeleteMusicClick = (musicID) => {
    setDeleteID(musicID);
    setShowDeleteAlert(true);
  };

  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getPublishedMusicData({ page, limit: sizePerPage, objectType : 'AUDIO' });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleCreatedTablePageChange = (type, { page, sizePerPage }) => {
    getCreatedMusicData({ page, limit: sizePerPage, objectType : 'AUDIO' });
    setCreatedPaginationValue({
      ...createdPaginationValue,
      createdCurrentPage: page,
    });
  };

  const deleteMusicAction = async () => {
    await deleteMusicReq(deleteID);
    const filteredMusics = createdMusics.filter(({ _id }) => _id !== deleteID);
    setCreatedMusics(filteredMusics);
    setDeleteID("");
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  };

  const select_music = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  const selector1 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#1cbb8c",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  const selector2 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#fcb92c",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  const selector3 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "red",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <h2 style={{ margin: '0px' }}>All Media</h2>
        <div style={{ position: 'relative' }}>
          <input
            name='search'
            value={search}
            placeholder='Search Media'
            style={{
              width: '300px', 
              backgroundColor: 'transparent',
              outline: 'none',
              border: 'none',
              borderBottom: '1px solid black',
              padding: '5px',
            }}
            onChange={handleChange}
            onBlur={() => hideComponent()}
            autoComplete='off'
          />
          {show === true && (
            <>
              <div style={select_music}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleViewMusicClick(item._id)}
                      >
                        {item &&
                          item.isDisabled === false &&
                          item.isPublished === true && (
                            <div style={selector1}></div>
                          )}
                        {item &&
                          item.isDisabled === false &&
                          item.isPublished === false && (
                            <div style={selector2}></div>
                          )}
                        {item &&
                          item.isDisabled === true &&
                          item.isPublished === false && (
                            <div style={selector3}></div>
                          )}
                        <div>{item ? item.title : '-'}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <MusicCard
        tableData={publishedMusics}
        tableHeading={PublishedMusicTableHeading}
        handleViewMusic={handleViewMusicClick}
        handleUnpublishMusic={handleUnpublishMusicClick}
        handleTablePageChange={handlePublishedTablePageChange}
        handleAlternateMusic={handleAlternateMusic}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showUnpublishAlert ? (
        <SweetAlert
          title='Are you sure?'
          warning
          showCancel
          confirmBtnBsStyle='success'
          cancelBtnBsStyle='danger'
          confirmBtnText='Yes, Unpublish it!'
          reverseButtons={true}
          onConfirm={unpublishMusicAction}
          onCancel={() => setShowUnpublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnpublishConfirm ? (
        <SweetAlert
          success
          title='Unpublished!'
          confirmBtnBsStyle='primary'
          onConfirm={() => {
            setShowUnpublishAlert(false);
            setShowUnpublishConfirm(false);
            getCreatedMusicData({ page: 1, limit: 10 });
          }}
        >
          File has been unpublished
        </SweetAlert>
      ) : null}

      <CreatedMusic
        tableData={createdMusics}
        tableHeading={CreatedMusicTableHeading}
        handleEditMusic={handleEditMusicClick}
        handleViewMusic={handleViewMusicClick}
        handleDeleteMusic={handleDeleteMusicClick}
        handlePublishMusic={handlePublishMusicClick}
        handleTablePageChange={handleCreatedTablePageChange}
        page={createdCurrentPage}
        sizePerPage={10}
        totalSize={createdTotalSize}
        isLoading={isLoading}
      />

      {showPublishAlert ? (
        <SweetAlert
          title='Are you sure?'
          warning
          showCancel
          confirmBtnBsStyle='success'
          cancelBtnBsStyle='danger'
          confirmBtnText='Yes, Publish it!'
          reverseButtons={true}
          onConfirm={publishMusicAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
          onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title='Published!'
          confirmBtnBsStyle='primary'
          onConfirm={() => {
            setShowPublishAlert(false);
            setShowPublishConfirm(false);
            getPublishedMusicData({ page: 1, limit: 10 });
          }}
        >
          File has been published
        </SweetAlert>
      ) : null}

      {showDeleteAlert ? (
        <SweetAlert
          title='Are you sure?'
          warning
          showCancel
          confirmBtnBsStyle='success'
          cancelBtnBsStyle='danger'
          confirmBtnText='Yes, Delete it!'
          reverseButtons={true}
          onConfirm={deleteMusicAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title='Deleted!'
          confirmBtnBsStyle='primary'
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllMusic;
