import MediaTags from "../Components/MediaTags";
import MediaCategories from "../Components/MediaCategories";
import MediaSubCategories from "../Components/MediaSubCategories"
import Mapping from "../Components/Mapping";
import CreateMapping from "../Components/CreateMapping";

export const MediaPropertiesRouts = [
  {
    Component: MediaTags,
    path: "/tags",
    exact: true,
    id: "dashboardMediaTags",
    routePath: "/dashboard/media-properties/tags",
    name: "Media Tags",
  },
  {
    Component: MediaCategories,
    path: "/categories",
    exact: true,
    id: "dashboardMediaCategories",
    routePath: "/dashboard/media-properties/categories",
    name: "Media Categories",
  },
  {
    Component: MediaSubCategories,
    path: "/subcategories",
    exact: true,
    id: "dashboardMediaSubCategories",
    routePath: "/dashboard/media-properties/subcategories",
    name: "Media SubCategories",
  },
{
  Component: Mapping,
  path: "/mapping",
  exact: true,
  id: "dashboardMediaMapping",
  routePath: "/dashboard/media-properties/mapping",
  name: "All Mapping",
},
{
  Component: CreateMapping,
  path: "/create-mapping",
  exact: true,
  id: "dashboardMediaMappingCreate",
  routePath: "/dashboard/media-properties/create-mapping",
  name: "Create Mapping",
},
  
];