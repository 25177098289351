import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { DiscoverTableHeading } from "../../Views/Dashboard/Components/Discover/Discover.Constants";

import Table from "../Table";

const DiscoverCard = ({
  tableData = [],
  toggleModal,
  tableHeading,
  isLoading
}) => {
  const history = useHistory();


  const viewImageFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button color="danger" className="waves-effect waves-light" style={{ width: "100%" }} onClick={() => window.open(row.image, '_blank')} >
          View
        </Button>
      </div>
    );
  };

  const viewBackgroundImageFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button color="danger" className="waves-effect waves-light" style={{ width: "100%" }} onClick={() => window.open(row.backgroundImage, '_blank')} >
          View
        </Button>
      </div>
    );
  };

  const viewSeriesBackgroundImageFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button color="danger" className="waves-effect waves-light" style={{ width: "100%" }} onClick={() => window.open(row.seriesBackgroundImage, '_blank')} >
          View
        </Button>
      </div>
    );
  };

  // const viewDiscoverFormatter = (cell, row) => {

  //   const handleToggle = (id) => {
  //     toggleModal(id)
  //   }

  //   return (
  //     <div style={{ textAlign: "center" }} key={row._id}>
  //       <Button color="success" className="waves-effect waves-light" style={{ width: "100%" }} onClick={() => handleToggle(row.id)} >
  //         View
  //       </Button>
  //     </div>
  //   );
  // }

  useEffect(() => {
    DiscoverTableHeading.push({
      text: "Image",
      dataField: `image`,
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewImageFormatter,
    });
    DiscoverTableHeading.push({
      text: "Background Image",
      dataField: "backgroundImage",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewBackgroundImageFormatter,
    });
    DiscoverTableHeading.push({
      text: "Series Background Image",
      dataField: "seriesBackgroundImage",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewSeriesBackgroundImageFormatter,
    });
    // DiscoverTableHeading.push({
    //   text: "View Discover",
    //   dataField: "id",
    //   isDummyField: true,
    //   headerStyle: { textAlign: "center", width: "10%" },
    //   formatter: viewDiscoverFormatter,
    // });
    return () => {
      DiscoverTableHeading.pop();
      DiscoverTableHeading.pop();
      DiscoverTableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);


  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{tableHeading}</h3>
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  >
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/discover/create")}
                    >
                      Add Discover
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={tableData}
              pagination={false}
              columns={DiscoverTableHeading}
              isLoading={isLoading}
              page={1}
              sizePerPage={tableData.length}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DiscoverCard;
