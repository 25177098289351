import React from "react";
import { useState, useEffect } from "react";
import { getStoryById, updateStory } from "../../Story.Apis";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import EditStoriesComp from "../../../../../../Components/EditStoriesComp";
import { creatingStates } from "../../../Blogs/Constants";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import SweetAlert from "react-bootstrap-sweetalert";

const EditStories = () => {
  const history = useHistory();
  const { storyId } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    textColour:""
  });
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [thumbnailColor, setThumbnailColor] = useState(null);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { title, description, textColour } = formData;
  const [storyImg, setStoryImg] = useState("");
  const [updateStoryConfirm, setUpdateStoryConfirm] = useState(false);

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const removeHash = (color) => {
    if (color) {
      return color.replace("#", "");
    } else {
      return ""; 
    }
  };

  const getStoryId = async () => {
    try {
      const { data } = await getStoryById(storyId);
      setFormData({
        title: data.data.title,
        description: data.data.description,
        textColour:removeHash(data.data.textColour)
      });
      setStoryImg(data.data.assetURL);
      setThumbnailColor(data.data.thumbnailColorPalette);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStoryId();
  }, []);

  const updateStoriesData = async () => {
    try {
      let headerImgUrl = storyImg;
      if (storyImg && typeof storyImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        headerImgUrl = await uploadFile(
          storyImg,
          "STORY-IMAGE",
          setThumbnailUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
      await updateStory(storyId, {
        title: formData?.title,
        description: formData?.description,
        textColour:"#" + formData?.textColour,
        assetURL: headerImgUrl,
        thumbnailColorPalette: thumbnailColor

      });
      setUpdateStoryConfirm(true);
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <EditStoriesComp
        title={title}
        description={description}
        textColour={textColour}
        setStoryImg={setStoryImg}
        storyImg={storyImg}
        handelInput={handelInput}
        updateStoryHandle={updateStoriesData}
        setThumbnailColor={setThumbnailColor}
        thumbnailColor={thumbnailColor}
      />
      {updateStoryConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/stories/all");
            setUpdateStoryConfirm(false);
          }}
        >
          Story has been updated.
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditStories;
