import AllAppointment from "../Views/AllAppointment";
import EditAppointment from "../Views/EditAppointment";

const AppointmentRoutes = [ 
  {
    Component: AllAppointment,
    path: '/all',
    exact: true,
    id: 'dashboardAppointmentAll',
    routePath: '/dashboard/user-appointment/all',
    name: 'All Appointment',
  },
  {
    Component: EditAppointment,
    path: '/edit/:appointmentId',
    exact: true,
    id: 'dashboardAppointmentEdit',
  },
]

export default AppointmentRoutes