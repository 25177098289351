import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  PublishedLessonTableHeading,
  UnPublishedLessonTableHeading,
} from "../../Constants/constant";
import {
  getPublishedLessonsAPI,
  getUnPublishedLessonsAPI,
  UpdateUnPublishLessonAPI,
  UpdatePublishLessonAPI,
} from "../../lessons.Api";
import LessonPublished from "../../../../../../Components/LessonPublished";
import LessonUnPublished from "../../../../../../Components/LessonUnPublished";

const AllLessons = () => {
  const [PublishedLesson, setPublishedLesson] = useState([]);
  const [UnPublishedLesson, setUnPublishedLesson] = useState([]);
  const [publishID, setPublishID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = paginationValue;

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 10,
  });
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue;

  const history = useHistory();

  const getPublishedLessonData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getPublishedLessonsAPI(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
     
      setPublishedLesson(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getUnPublishedLessonData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getUnPublishedLessonsAPI(queryParams);
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setUnPublishedLesson(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(unpublishedPaginationValue,'unpublishedPaginationValue');

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getPublishedLessonData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getUnPublishedLessonData({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };

  const handleEditLesson = (id) => {
    console.log(id, "vvvv");
    history.push({
      pathname: "/dashboard/lessons/edit/" + id,
      state: { from: id },
    });
  };

  const handlePublishLessonClick = async (id) => {
    
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishLessonClick = async (id) => {
    
    setPublishID(id);
    setShowPublishAlert(true);
  };

  // const handleViewLesson = (refId) => {
  //   history.push("/dashboard/Lesson/view/" + refId);
  // };

  const publishLessonAction = async () => {
    await UpdateUnPublishLessonAPI(publishID);
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
    const filteredLesson = PublishedLesson.filter(
      ({ _id }) => _id !== publishID
    );
    setPublishedLesson(filteredLesson);
    setPublishID("");
  };

  const UnpublishLessonAction = async () => {
    await UpdatePublishLessonAPI(publishID);
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
    const filteredLesson = UnPublishedLesson.filter(
      ({ _id }) => _id !== publishID
    );
    setUnPublishedLesson(filteredLesson);
    setPublishID("");
  };

  useEffect(() => {
    getPublishedLessonData({ page: 1, limit: 10 });
    getUnPublishedLessonData({ page: 1, limit: 10 });
  }, []);

  return (
    <div>
      
      <LessonPublished
        tableData={PublishedLesson}
        handleEditLesson={handleEditLesson}
        tableHeading={PublishedLessonTableHeading}
        setPublishedLesson={setPublishedLesson}
        handlePublishLesson={handlePublishLessonClick}
        handleTablePageChange={handleTablePageChange}
        // handleViewLesson={handleViewLesson}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />

      <LessonUnPublished
        tableData={UnPublishedLesson}
        tableHeading={UnPublishedLessonTableHeading}
        setUnPublishedLesson={setUnPublishedLesson}
        handleEditLesson={handleEditLesson}
        // handleViewLesson={handleViewLesson}
        handleUnPublishLesson={handleUnPublishLessonClick}
        handleTablePageChange={handleUnpublishedTablePageChange}
        isLoading={isLoading}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        unpublishedTotalSize={unpublishedTotalSize}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishLessonAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Lesson has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,publish it!"
          reverseButtons={true}
          onConfirm={UnpublishLessonAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Lesson has been published
        </SweetAlert>
      ) : null}
    </div>
  );
};

export default AllLessons;
