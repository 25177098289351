import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import NotificationsCard from "../../../../../../Components/NotificationsCard";
import CreateNotificationModel from "../../../../../../Modal/CreateNotifications";
import NotiInfo from "../../../../../../Modal/NotiInfo";

import {
  getNotification,
  deleteNotificationReq,
  searchNotification,
} from "../../Notifications.Apis";

import { notificationHeading } from "../../Notifications.Constants";

const AllNotifications = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isNotiInfoOpen, setIsNotiInfoOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [showSendConfirm, setShowSendConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  });
  const { currentPage, totalSize } = paginationValue;
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [notiInfo, setNotiInfo] = useState({
    title: "",
    description: "",
    objectType: "",
  });

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);
  const toggleNotiInfo = () => setIsNotiInfoOpen(!isNotiInfoOpen);

  const getData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getNotification(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setNotifications(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const getSearchData = async (text) => {
    try {
      const { data } = await searchNotification(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) {}
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleNotiInfo = (item) => {
    setNotiInfo({
      title: item.title,
      description: item.description,
      objectType: item.objectType,
    });
    toggleNotiInfo();
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteID(id);
    setShowDeleteAlert(true);
  };

  const deleteNotiAction = async () => {
    await deleteNotificationReq(deleteID);
    const filteredNotifications = notifications.filter(
      ({ _id }) => _id !== deleteID
    );
    setNotifications(filteredNotifications);
    setDeleteID("");
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  };

  const populateCreatedNotification = (notification) => {
    setNotifications([notification, ...notifications]);
  };

  const select_noti = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "max-content",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>All Notifications</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search Notification"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            autoComplete="off"
            onBlur={hideComponent}
          />
          {show === true && (
            <>
              <div style={select_noti}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleNotiInfo(item)}
                      >
                        <div>{item ? item.title : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <NotificationsCard
        openModel={toggleCreateModel}
        tableData={notifications}
        tableHeading={notificationHeading}
        setNotifications={setNotifications}
        showSendConfirm={showSendConfirm}
        setShowSendConfirm={setShowSendConfirm}
        handleDeleteNoti={handleDeleteClick}
        allNoti={true}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      <NotiInfo
        title={notiInfo.title}
        description={notiInfo.description}
        objectType={notiInfo.objectType}
        isNotiInfoOpen={isNotiInfoOpen}
        toggleNotiInfoModal={toggleNotiInfo}
      />

      <CreateNotificationModel
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        populateNotification={populateCreatedNotification}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={deleteNotiAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
          }}
        >
          File has been deleted
        </SweetAlert>
      ) : null}

      {showSendConfirm ? (
        <SweetAlert
          success
          title="Sent!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowSendConfirm(false);
          }}
        >
          Notification has been sent
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllNotifications;
