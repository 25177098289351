import AdvisorTime from '../Components/AdvisorDate'
import AllTimeSlots from '../Components/AllTimeSlots'
import CreateTimeSlots from '../Components/CreateTimeSlots'
import EditTimeSlot from '../Components/EditTimeSlots'

const TimeSlotsRoutes = [
  {
    Component: AllTimeSlots,
    path: '/all',
    exact: true,
    id: 'dashboardTimeSlotsAll',
    routePath: '/dashboard/time-slots/all',
    name: 'All Time Slots',
  },
  {
    Component: CreateTimeSlots,
    path: '/create',
    exact: true,
    id: 'dashboardTimeSlotsCreate',
    routePath: '/dashboard/time-slots/create',
    name: 'Create Time Slots',
  },
  {
    Component: EditTimeSlot,
    path: '/edit/:editID',
    exact: true,
    id: 'dashboardTimeSlotsEdit',
  },
  {
    Component: AdvisorTime,
    path: '/edit/all-time-slots/:editTimeID',
    exact: true,
    id: 'dashboardTimeSlotsEditSlots',
  },
  
]

export default TimeSlotsRoutes