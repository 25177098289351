import React, { useEffect } from "react";
import { useState } from "react";
import { EditorState } from "draft-js";
import { postCousreApi, getAllLessonApi } from "../../Constants/api";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../../Blogs/Constants";
import { getAllSupportAdvisorApi , getAllFaq} from "../../Constants/api";
import UploadBlogsStatus from "../../../../../../Components/UploadBlogsStatus";
import SweetAlert from "react-bootstrap-sweetalert";
import { faqAPI } from "../../../../../../Constants/apiRoutes";
import LiveSessionCreateComp from "../../../../../../Components/LiveSessionCreateComp";
import { createLiveSession } from "../../Constants/api";

const CreateLiveSession = () => {
  const [formData, setFormData] = useState({
    title: "",
    imgAsset: "",
    supportAdvisor: "",
    learnings: [],
    intensity: "",
    lessons: [],
    Faqs: [],
    outCome: [],
    gettings: [],
    assetUrl: "",
    startDate:"",
    endDate:""
  });

  const [advisor, setAdvisor] = useState([]);
  const [adName, setAdName] = useState([]);
  const [learnTitle, setLearnTitle] = useState("");
  const [lessonsData, setLessonsData] = useState([]);
  const [lessonName, setLessonName] = useState([]);
  const [learnImg, setLearnImg] = useState("");
  const [learnTitleTwo, setLearnTitleTwo] = useState("");
  const [learnImgTwo, setLearnImgTwo] = useState("");
  const [learnTitleThree, setLearnTitleThree] = useState("");
  const [learnImgThree, setLearnImgThree] = useState("");
  const [faq, setFaq] = useState("");
  const [faqData, setFaqData] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [content, setContent] = useState(null);
  const [headerImg, setHeaderImg] = useState("");
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });

  const { isCreating, creatingStage } = creating;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  

  const { title, imgAsset, supportAdvisor, intensity, assetUrl, startDate, endDate } = formData;
  

  const handleChangeSession = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getAllLessonData = async () => {
    const { data } = await getAllLessonApi();
    console.log(data?.data);
    setLessonsData(data?.data);
  };

  const getAllAdvisorData = async () => {
    const { data } = await getAllSupportAdvisorApi();
    console.log(data.data);
    setAdvisor(data?.data);
  };

  const getAllFaqData = async () =>{
    const {data} = await getAllFaq()
    setFaqData(data?.faqs)
  }

  useEffect(() => {
    getAllAdvisorData();
    getAllLessonData();
    getAllFaqData();
  }, []);

  const formatMultiSelectData = (data) => {
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const intensityData = [
    {
      key: "LOW",
      label: "LOW",
      value: "LOW",
    },
    {
      key: "MEDIUM",
      label: "MEDIUM",
      value: "MEDIUM",
    },
    {
      key: "HIGH",
      label: "HIGH",
      value: "HIGH",
    },
  ];

  const handleCreateLiveSession = async () => {
    console.log("enter");
    try {
      console.log("try enter");
      if (!headerImg)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const headerImgUrl = await uploadFile(
        headerImg,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        20
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const learnImgUrl = await uploadFile(
        learnImg,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        30
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!learnImgTwo)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const learnImgTwoUrl = await uploadFile(
        learnImgTwo,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        40
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      if (!learnImgThree)
        return setError({
          isError: true,
          errorMessage: "Please select a cover image",
          errorType: "warning",
        });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const learnImgThreeUrl = await uploadFile(
        learnImgThree,
        "COURSES-IMAGE",
        setThumbnailUploadPercentage,
        90
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      await createLiveSession({
        title: formData.title,
        description: content,
        imgAsset: headerImgUrl,
        supportAdvisorId: formatMultiSelectData(adName),
        faqId:formatMultiSelectData(faq),
        assetUrl:formData.assetUrl,
        whatWillYouLearn: [
          {
            imgAsset: learnImgUrl,
            title: learnTitle,
          },
          {
            imgAsset: learnImgTwoUrl,
            title: learnTitleTwo,
          },
          {
            imgAsset: learnImgThreeUrl,
            title: learnTitleThree,
          },
        ],
        // startDate: new Date(formData?.startDate),
        // endDate: new Date(formData?.endDate),
        startDate: selectedDate[0],
        endDate: selectDate[0],
        
      });
      console.log(startDate,'hurraahhhhhhh'),

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
    } catch (error) {
      console.log(error, error);
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setThumbnailUploadPercentage(0);
      setContentUploadPercentage(0);
      setError({
        isError: true,
        errorMessage: "Unable to create Image please try again later",
        errorType: "danger",
      });
    }
  };

  const reset = () => {
    setLearnImg(null);
    setLearnImgTwo(null);
    setLearnImgThree(null);
    setHeaderImg(null);

    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      imgAsset: "",
      supportAdvisor: "",
      learnings: [],
      intensity: "",
      lessons: [],
      Faqs: [],
      outCome: [],
      gettings: [],
      assetUrl: "",
      startDate:"",
      endDate:""
    });
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  return (
    <>
      {isCreating ? (
        <UploadBlogsStatus
          currentStep={creatingStage}
          thumbnailUploadPercentage={thumbnailuploadPercentage}
          contentUploadPercentage={contentUploadPercentage}
          reset={reset}
          fileName="live-sessions"
        />
      ) : (
        <LiveSessionCreateComp
        pageHeading={"CreateLive Session"}
          handleChangeSession={handleChangeSession}
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          faq={faq}
          faqData={faqData}
          setFaqData={setFaqData}
          setFaq={setFaq}
          setAdName={setAdName}
          adName={adName}
          setLearnImgThree={setLearnImgThree}
          setLearnTitleThree={setLearnTitleThree}
          setLearnImgTwo={setLearnImgTwo}
          setLearnTitleTwo={setLearnTitleTwo}
          learnImgThree={learnImgThree}
          learnTitleThree={learnTitleThree}
          learnImgTwo={learnImgTwo}
          learnTitleTwo={learnTitleTwo}
          setLearnImg={setLearnImg}
          learnImg={learnImg}
          setContent={setContent}
          formData={formData}
          setFormData={setFormData}
          title={title}
          description={description}
          setDescription={setDescription}
          image={imgAsset}
          advisor={advisor}
          setAdvisor={setAdvisor}
          supportAdvisor={supportAdvisor}
          learnTitle={learnTitle}
          setLearnTitle={setLearnTitle}
          meetLink={assetUrl}
          handleChangeCourse={handleChangeSession}
          handleCreateLiveSession={handleCreateLiveSession}
          setSelectedDate={setSelectedDate}
          setSelectDate={setSelectDate}
          selectDate={selectDate}
          selectedDate={selectedDate}
          setValidationError={setValidationError}
          validationError={validationError}

        />
      )}
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={() => {
            handleCreateLiveSession();
            setShowUnPublishAlert();
          }}
          onCancel={() => setShowUnPublishAlert()}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/live-sessions/all");
          }}
        >
          Courses has been created
        </SweetAlert>
      ) : null}
    </>
  );
};

export default CreateLiveSession;
