import AllVideos from "../Components/AllVideos";
import YoutubeVideos from "../Components/CreateYtVideos";

export const YtVideosRoutes = [
  {
    Component:AllVideos,
    path:"/all",
    exact : true,
    id: "dashboardYtVideosAll",
    routePath: "/dashboard/ytVideos/all",
    name: "All Videos"
  },
  {
    Component:YoutubeVideos,
    path:"/add",
    exact : true,
    id: "dashboardYtVideosAdd",
    routePath: "/dashboard/ytVideos/add",
    name: "Add Youtube Videos"
  }
]

