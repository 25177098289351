import React, { useState, useEffect } from "react";
import SleepSessionEditForm from "../../../../../../Components/SleepSessionEditForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getByIdQuestionApi, updateQuestions } from "../../SleepSessions.Api";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

const EditQuestions = () => {
  const { questionId } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    title: "",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [ansOptions, setAnsOptions] = useState([]);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
  const [error, setError] = useState("");
  const { title } = formData;

  const options = [
    { value: null, label: "Park" },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
    { value: 32, label: 32 },
    { value: 33, label: 33 },
    { value: 34, label: 34 },
    { value: 35, label: 35 },
    { value: 36, label: 36 },
    { value: 37, label: 37 },
    { value: 38, label: 38 },
    { value: 39, label: 39 },
    { value: 40, label: 40 },
    { value: 41, label: 41 },
    { value: 42, label: 42 },
    { value: 43, label: 43 },
    { value: 44, label: 44 },
    { value: 45, label: 45 },
    { value: 46, label: 46 },
    { value: 47, label: 47 },
    { value: 48, label: 48 },
    { value: 49, label: 49 },
    { value: 50, label: 50 }
  ];

  const optionsData = options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const getEditQuestions = async () => {
    try {
      const { data } = await getByIdQuestionApi(questionId);
      setFormData({
        title: data.data.question,
      });
      setSelectedOption({
        value: data.data.orderNo,
        label: data.data.orderNo,
      });
      setAnsOptions(data.data.options || []);
      console.log(data.data, "abdfbaadb");
    } catch (error) {
      console.log(error);
    }
  };

  const updateSleepSurveyQuestions = async () => {
    try {
      await updateQuestions(
        {
          question: formData.title,
          orderNo: selectedOption.value,
          options: ansOptions,
        },
        questionId
      );
      setShowUpdateConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEditQuestions();
  }, []);

  const handleChangeQuestions = (e) => {
    setFormData({
      ...formData,
      title: e.target.value,
    });
  };

  const handleChange = (selectOption) => {
    setSelectedOption(selectOption);
  };

  const handleAddAnswer = () => {
    if (ansOptions.length < 4) {
      setAnsOptions([...ansOptions, ""]);
      setError("");
    } else {
      setError("Only 4 options can be added.");
    }
  };

  const handleRemoveAnswer = (index) => {
    const newAnswers = ansOptions.filter((_, idx) => idx !== index);
    setAnsOptions(newAnswers);
    setError("");
  };

  const handleAnswerChange = (e, index) => {
    const newAnswers = ansOptions.map((option, idx) =>
      idx === index ? e.target.value : option
    );
    setAnsOptions(newAnswers);
  };

  return (
    <>
      <SleepSessionEditForm
        heading={"Edit questions"}
        handleChange={handleChange}
        handleChangeQuestions={handleChangeQuestions}
        formData={formData}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        title={title}
        options={options}
        optionsData={optionsData}
        updateQuestions={updateSleepSurveyQuestions}
        ansOptions={ansOptions}
        handleAddAnswer={handleAddAnswer}
        handleRemoveAnswer={handleRemoveAnswer}
        handleAnswerChange={handleAnswerChange}
        error={error}
      />
      {showUpdateConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/sleep-sessions/all");
          }}
        ></SweetAlert>
      ) : null}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </>
  );
};

export default EditQuestions;
