import axios from "axios";

// <<<<<<< Updated upstream
// import baseDomain, { tagAPI, tagsAPI, categoryAPI, tagsBinAPI, categoryBinAPI,updateFaqApi,publishFaqApi,searchAllFaqApi,publishActionApi,unpublishActionApi, subcategoryBinAPI,unpublishFaqAPI, restoreTagAPI, searchTagAPI, faqsAPI,allFaqTagsApi, searchFaqAPI,createFaqAPI, addFaqTagApi, categoryFaqAPI } from "../../../../Constants/apiRoutes";

import baseDomain, { tagAPI, tagsAPI, categoryAPI,subcategoryAPI, tagsBinAPI, categoryBinAPI,updateFaqApi,editFaqApi,publishFaqApi,searchAllFaqApi,publishActionApi,unpublishActionApi, subcategoryBinAPI,unpublishFaqAPI, restoreTagAPI, searchTagAPI, faqsAPI,allFaqTagsApi, searchFaqAPI,createFaqAPI, addFaqTagApi, categoryFaqAPI, faqAPI } from "../../../../Constants/apiRoutes";


export const getAllTags = () => {
  return axios.get(`${baseDomain}${addFaqTagApi}`);
};

export const searchFaqTagApi = (text) => {
  return axios.get(`${baseDomain}${searchFaqAPI}/${text}`);
};
//1
export const getTags = ({ page, limit }) => {
  return axios.get(`${baseDomain}${faqsAPI}?page=${page}&limit=${limit}`);
};

export const getCategory = ({ page, limit }) => {
  return axios.get(`${baseDomain}${categoryFaqAPI}?page=${page}&limit=${limit}`);
};

export const getSubcategory = ({ page, limit }) => {
  return axios.get(`${baseDomain}${subcategoryAPI}?page=${page}&limit=${limit}`);
};

export const getTagsBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${tagsBinAPI}?page=${page}&limit=${limit}`);
};

export const getCategoryBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${categoryBinAPI}?page=${page}&limit=${limit}`);
};

export const deleteTag = (tagID) => {
  return axios.delete(`${baseDomain}${tagAPI}/${tagID}`);
};

export const restoreTag = (tagID) => {
  return axios.put(`${baseDomain}${restoreTagAPI}/${tagID}`);
};

export const faqFormAPi = () => {
  return axios.get(`${baseDomain}${allFaqTagsApi}`)
};

export const createFaq = (data) => {
  return axios.post(`${baseDomain}${createFaqAPI}`,data)
}; 

export const unpublishFaq = ({page,limit}) => {
  return axios.get(`${baseDomain}${unpublishFaqAPI}?page=${page}&limit=${limit}`)
};

export const publishAction = (publishID) => {
  return axios.put(`${baseDomain}${publishActionApi}/${publishID}`)
};

export const publishFaq =({page,limit}) => {
  return axios.get(`${baseDomain}${publishFaqApi}?page=${page}&limit=${limit}`)
}

export const unpublishAction =(unpublishID) => {
  return axios.put(`${baseDomain}${unpublishActionApi}/${unpublishID}`)
}
export const searchAllFaq = (text) => {
  return axios.get(`${baseDomain}${searchAllFaqApi}/${text}`);
}

export const updateFaqInfo = (userId,payload) => {
  return axios.put(`${baseDomain}${updateFaqApi}/${userId}`,payload);
}
export const editFaq = (userID) => {
  return axios.get(`${baseDomain}${editFaqApi}/${userID}`);
} 