import axios from "axios";

import baseDomain, { viewPlanAPI, editPlanAPI, restorePlanAPI } from "../../Constants/apiRoutes";

export const viewPlan = (planID) => {
  return axios.get(`${baseDomain}${viewPlanAPI}/${planID}`);
};

export const editPlan = (planID, payload) => {
  return axios.put(`${baseDomain}${editPlanAPI}/${planID}`, payload);
};

export const restorePlan = (planID) => {
  return axios.put(`${baseDomain}${restorePlanAPI}/${planID}`);
};