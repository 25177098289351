import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import MusicForm from "../../../../../../Components/MusicForm";
import UploadingMusicLoader from "../../../../../../Components/UploadingMusicLoader";
import Loader from "../../../../../../Components/Loader";
import {
    filterTagsAndFormat,
    formatMultiSelectData,
    formatForReactSelect,
    formatArray } from "../../helper";
import { uploadFile } from "../../../Music/Music.Helpers";    
import { fileTypes , creatingStates} from "../../constant";
import { getMediaApi, getAllTags } from "../../../Tag/Tag.Apis";
import { editMusicReq, getMusicReq , transCodingApi} from "../../api";


const EditVedio = () => {
  const [getCategory, setGetCategory] = useState('')
  const [getSubCategory, setGetSubCategory] = useState('')
  const [ getTags, setGetTags] = useState('')
  const [getCategoryCount, setGetCategoryCount] = useState('')
  const [getSubCategoryCount, setGetSubCategoryCount] = useState('')
  const [ getTagsCount, setGetTagsCount] = useState('')
  const { musicID } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailColor, setThumbnailColor] = useState(null);
  const [level,setLevel] = useState("")

  const [img115x150, setImg115x150] = useState(null);
  const [img115x150UploadPercent, setImg115x150UploadPercent] = useState(0);

  const [img145x345, setImg145x345] = useState(null);
  const [img145x345UploadPercent, setImg145x345UploadPercent] = useState(0);

  const [img180x160, setImg180x160] = useState(null);
  const [img180x160UploadPercent, setImg180x160UploadPercent] = useState(0);

  const [img155x135, setImg155x135] = useState(null);
  const [img155x135UploadPercent, setImg155x135UploadPercent] = useState(0);

  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    language: 'en',
    isFree: true,
    UserRecommendationMood: [],
    UserRecommendationStress: [],
  });
  const [UserRecommendationAge, setUserRecommendationAge] = useState([]);
  const [UserRecommendationMaritalStatus, setUserRecommendationMaritalStatus] =
    useState([]);
  const [UserRecommendationBPM, setUserRecommendationBPM] = useState([]);
  const [
    UserRecommendationFocusOnPresentMoment,
    setUserRecommendationFocusOnPresentMoment,
  ] = useState([]);
  const [
    UserRecommendationExperienceStressAnxiety,
    setUserRecommendationExperienceStressAnxiety,
  ] = useState([]);
  const [UserRecommendationSleep, setUserRecommendationSleep] = useState([]);
  const [UserRecommendationHoursOfSleep, setUserRecommendationHoursOfSleep] =
    useState([]);
  const [
    UserRecommendationIrritatedOrAngry,
    setUserRecommendationIrritatedOrAngry,
  ] = useState([]);
  const [
    UserRecommendationFrequentLethargy,
    setUserRecommendationFrequentLethargy,
  ] = useState([]);
  const [
    UserRecommendationCommitmentAtWork,
    setUserRecommendationCommitmentAtWork,
  ] = useState([]);
  const [
    UserRecommendationIssuesInRelationship,
    setUserRecommendationIssuesInRelationship,
  ] = useState([]);
  const [UserRecommendationLowSelfEsteem, setUserRecommendationLowSelfEsteem] =
    useState([]);
  const [
    UserRecommendationHoursSpendOnSocialMedia,
    setUserRecommendationHoursSpendOnSocialMedia,
  ] = useState([]);
  const [UserRecommendationGender, setUserRecommendationGender] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [moods, setMoods] = useState([]);
  const [stressLevel, setStressLevel] = useState([]);

  const {
    title,
    description,
    isFree,
    language,
    UserRecommendationMood,
    UserRecommendationStress,
  } = formData;

  const [allTags, setAllTags] = useState({
    tagsData: [],
    categoryData: [],
    subCategoryData: [],
  });
  const moodsData = {
    moodData: [
      { key: 'HAPPY', label: 'HAPPY', value: 'HAPPY' },
      { key: 'STRESSED', label: 'STRESSED', value: 'STRESSED' },
      { key: 'CANT_SLEEP', label: 'CANT_SLEEP', value: 'CANT_SLEEP' },
      { key: 'CALM', label: 'CALM', value: 'CALM' },
      { key: 'LOW', label: 'LOW', value: 'LOW' },
      { key: 'ALL', label: 'ALL', value: 'ALL' },
    ],
    stressLevelData: [
      { key: 'FROM_1_TO_3', label: 'FROM_1_TO_3', value: 'FROM_1_TO_3' },
      { key: 'FROM_4_TO_6', label: 'FROM_4_TO_6', value: 'FROM_4_TO_6' },
      { key: 'FROM_7_TO_10', label: 'FROM_7_TO_10', value: 'FROM_7_TO_10' },
      { key: 'ALL', label: 'ALL', value: 'ALL' },
    ],
  };
  const ageData = [
    { key: 'BELOW_14', label: 'BELOW_14', value: 'BELOW_14' },
    { key: 'FROM_14_TO_16', label: 'FROM_14_TO_16', value: 'FROM_14_TO_16' },
    { key: 'FROM_17_TO_30', label: 'FROM_17_TO_30', value: 'FROM_17_TO_30' },
    { key: 'FROM_31_TO_50', label: 'FROM_31_TO_50', value: 'FROM_31_TO_50' },
    { key: 'ABOVE_50', label: 'ABOVE_50', value: 'ABOVE_50' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const maritalData = [
    { key: 'MARRIED', label: 'MARRIED', value: 'MARRIED' },
    { key: 'UNMARRIED', label: 'UNMARRIED', value: 'UNMARRIED' },
    { key: 'SEPARATED', label: 'SEPARATED', value: 'SEPARATED' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const bpmData = [
    {
      key: 'FROM_110_TO_120',
      label: 'FROM_90_TO_110',
      value: 'FROM_110_TO_120',
    },
    { key: 'ABOVE_120', label: 'ABOVE_110', value: 'ABOVE_120' },
    { key: 'BELOW_110', label: 'BELOW_90', value: 'BELOW_110' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];

  const focusOnPresentMomentData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const experienceStressAnxietyData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const sleepData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const sleepHoursData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];

  const irritatedOrAngryData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const lethargyData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];
  const commitWorkData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];

  const issueRelationshipData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];

  const esteemData = [
    { key: 'NEVER', label: 'NEVER', value: 'NEVER' },
    { key: 'SOMETIMES', label: 'SOMETIMES', value: 'SOMETIMES' },
    { key: 'OFTEN', label: 'OFTEN', value: 'OFTEN' },
    { key: 'ALMOST_ALWAYS', label: 'ALMOST_ALWAYS', value: 'ALMOST_ALWAYS' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];

  const hoursOnSocialData = [
    { key: 'FROM_0_TO_2', label: 'FROM_0_TO_2', value: 'FROM_0_TO_2' },
    { key: 'FROM_2_TO_4', label: 'FROM_2_TO_4', value: 'FROM_2_TO_4' },
    { key: 'FROM_4_TO_6', label: 'FROM_4_TO_6', value: 'FROM_4_TO_6' },
    { key: 'ABOVE_6', label: 'ABOVE_6', value: 'ABOVE_6' },
  ];

  const genderData = [
    { key: 'MALE', label: 'MALE', value: 'MALE' },
    { key: 'FEMALE', label: 'FEMALE', value: 'FEMALE' },
    { key: 'OTHER', label: 'OTHER', value: 'OTHER' },
    { key: 'ALL', label: 'ALL', value: 'ALL' },
  ];

  const { tagsData, categoryData, subCategoryData } = allTags;
  const { moodData, stressLevelData } = moodsData;

  const handleChangeThumbnail = (thumbFile) => {
    setIsDisabledBtn(false);
    setThumbnail(thumbFile);
  };
  const handleImg115x150 = (img) => {
    setIsDisabledBtn(false);
    setImg115x150(img);
  };
  const handleImg145x345 = (img) => {
    setIsDisabledBtn(false);
    setImg145x345(img);
  };
  const handleLevelSelect = (event) => {
    setLevel(event.target.value);
  };
  const handleImg155x135 = (img) => {
    setIsDisabledBtn(false);
    setImg155x135(img);
  };
  const handleImg180x160 = (img) => {
    setIsDisabledBtn(false);
    setImg180x160(img);
  };

  // Handle form IP
  const handelInput = (e) => {
    setIsDisabledBtn(false);
    if (e.target.name === 'isFree')
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // handle Change Tags
  const handleCategoriesSelect = (selectedCategories) => {
    setError({
      ...error,
      isError: false,
    });
    setIsDisabledBtn(false);
    setCategories(selectedCategories);
    setSubCategories([])
  };
  const handleSubCategoriesSelect = (selectedSubCategories) => {
    setIsDisabledBtn(false);
    setSubCategories(selectedSubCategories);

  };
  const handleTagsSelect = (selectedTags) => {
    setIsDisabledBtn(false);
    setTags(selectedTags);
  };

  const handleMoodsSelect = (selectedMoods) => {
    setIsDisabledBtn(false);
    setMoods(selectedMoods);
  };
  const handleStressLevelSelect = (selectedStressLevel) => {
    setIsDisabledBtn(false);
    setStressLevel(selectedStressLevel);
  };
  const handleAgeSelect = (selectAge) => {
    setIsDisabledBtn(false);
    setUserRecommendationAge(selectAge);
  };

  const handleMaritalSelect = (selectMarital) => {
    setIsDisabledBtn(false);
    setUserRecommendationMaritalStatus(selectMarital);
  };

  const handleBpmSelect = (selectBpm) => {
    setIsDisabledBtn(false);
    setUserRecommendationBPM(selectBpm);
  };

  const handlefocusOnPresentMomentSelect = (
    selectfocusOnPresentMomentSelect
  ) => {
    setIsDisabledBtn(false);
    setUserRecommendationFocusOnPresentMoment(selectfocusOnPresentMomentSelect);
  };
  const handleExperienceStressAnxiety = (selectExperienceStressAnxiety) => {
    setIsDisabledBtn(false);
    setUserRecommendationExperienceStressAnxiety(selectExperienceStressAnxiety);
  };
  const handleUserSleep = (selectUserSleep) => {
    setIsDisabledBtn(false);
    setUserRecommendationSleep(selectUserSleep);
  };
  const handleUserSleepHours = (selectUserSleepHours) => {
    setIsDisabledBtn(false);
    setUserRecommendationHoursOfSleep(selectUserSleepHours);
  };

  const handleIrritatedOrAngry = (select) => {
    setIsDisabledBtn(false);
    setUserRecommendationIrritatedOrAngry(select);
  };

  const handleLethargy = (select) => {
    setIsDisabledBtn(false);
    setUserRecommendationFrequentLethargy(select);
  };

  const handleCommitWork = (select) => {
    setIsDisabledBtn(false);
    setUserRecommendationCommitmentAtWork(select);
  };

  const handleIssueInRelationship = (select) => {
    setIsDisabledBtn(false);
    setUserRecommendationIssuesInRelationship(select);
  };

  const handleEsteem = (select) => {
    setIsDisabledBtn(false);
    setUserRecommendationLowSelfEsteem(select);
  };
  const handleHoursOnSocialMedia = (select) => {
    setIsDisabledBtn(false);
    setUserRecommendationHoursSpendOnSocialMedia(select);
  };

  const handleGenderSelect = (select) => {
    setUserRecommendationGender(select);
  };

  useEffect(() => {
    for (let i in moods) {
      if (moods[i].label === 'ALL') {
        if (moods?.length !== 1) {
          setMoods([{ key: 'ALL', label: 'ALL', value: 'ALL' }]);
        }
      }
    }
  }, [moods]);
  useEffect(() => {
    for (let i in stressLevel) {
      if (stressLevel[i].label === 'ALL') {
        if (stressLevel?.length !== 1) {
          setStressLevel([{ key: 'ALL', label: 'ALL', value: 'ALL' }]);
        }
      }
    }
  }, [stressLevel]);
  useEffect(() => {
    for (let i in UserRecommendationAge) {
      if (UserRecommendationAge[i].label === 'ALL') {
        if (UserRecommendationAge?.length !== 1) {
          setUserRecommendationAge([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationAge]);

  useEffect(() => {
    for (let i in UserRecommendationMaritalStatus) {
      if (UserRecommendationMaritalStatus[i].label === 'ALL') {
        if (UserRecommendationMaritalStatus?.length !== 1) {
          setUserRecommendationMaritalStatus([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationMaritalStatus]);

  useEffect(() => {
    for (let i in UserRecommendationBPM) {
      if (UserRecommendationBPM[i].label === 'ALL') {
        if (UserRecommendationBPM?.length !== 1) {
          setUserRecommendationBPM([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationBPM]);

  useEffect(() => {
    for (let i in UserRecommendationFocusOnPresentMoment) {
      if (UserRecommendationFocusOnPresentMoment[i].label === 'ALL') {
        if (UserRecommendationFocusOnPresentMoment?.length !== 1) {
          setUserRecommendationFocusOnPresentMoment([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationFocusOnPresentMoment]);

  useEffect(() => {
    for (let i in UserRecommendationExperienceStressAnxiety) {
      if (UserRecommendationExperienceStressAnxiety[i].label === 'ALL') {
        if (UserRecommendationExperienceStressAnxiety?.length !== 1) {
          setUserRecommendationExperienceStressAnxiety([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationExperienceStressAnxiety]);

  useEffect(() => {
    for (let i in UserRecommendationSleep) {
      if (UserRecommendationSleep[i].label === 'ALL') {
        if (UserRecommendationSleep?.length !== 1) {
          setUserRecommendationSleep([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationSleep]);

  useEffect(() => {
    for (let i in UserRecommendationHoursOfSleep) {
      if (UserRecommendationHoursOfSleep[i].label === 'ALL') {
        if (UserRecommendationHoursOfSleep?.length !== 1) {
          setUserRecommendationHoursOfSleep([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationHoursOfSleep]);

  useEffect(() => {
    for (let i in UserRecommendationIrritatedOrAngry) {
      if (UserRecommendationIrritatedOrAngry[i].label === 'ALL') {
        if (UserRecommendationIrritatedOrAngry?.length !== 1) {
          setUserRecommendationIrritatedOrAngry([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationIrritatedOrAngry]);

  useEffect(() => {
    for (let i in UserRecommendationFrequentLethargy) {
      if (UserRecommendationFrequentLethargy[i].label === 'ALL') {
        if (UserRecommendationFrequentLethargy?.length !== 1) {
          setUserRecommendationFrequentLethargy([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationFrequentLethargy]);

  useEffect(() => {
    for (let i in UserRecommendationCommitmentAtWork) {
      if (UserRecommendationCommitmentAtWork[i].label === 'ALL') {
        if (UserRecommendationCommitmentAtWork?.length !== 1) {
          setUserRecommendationCommitmentAtWork([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationCommitmentAtWork]);

  useEffect(() => {
    for (let i in UserRecommendationIssuesInRelationship) {
      if (UserRecommendationIssuesInRelationship[i].label === 'ALL') {
        if (UserRecommendationIssuesInRelationship?.length !== 1) {
          setUserRecommendationIssuesInRelationship([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationIssuesInRelationship]);

  useEffect(() => {
    for (let i in UserRecommendationLowSelfEsteem) {
      if (UserRecommendationLowSelfEsteem[i].label === 'ALL') {
        if (UserRecommendationLowSelfEsteem?.length !== 1) {
          setUserRecommendationLowSelfEsteem([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationLowSelfEsteem]);

  useEffect(() => {
    for (let i in UserRecommendationGender) {
      if (UserRecommendationGender[i].label === 'ALL') {
        if (UserRecommendationGender.length !== 1) {
          setUserRecommendationGender([
            { key: 'ALL', label: 'ALL', value: 'ALL' },
          ]);
        }
      }
    }
  }, [UserRecommendationGender]);

  // Get initial data
  const getAllTagsData = async () => {
    try {
      const { data } = await getAllTags();
      const filteredData = filterTagsAndFormat(data.data);
      setAllTags(filteredData);
    } catch (error) {}
  };

  const bpmKeys = {
    FROM_110_TO_120: 'FROM_90_TO_110',
    ABOVE_120: 'ABOVE_110',
    BELOW_110: 'BELOW_90',
    ALL: 'ALL',
  };

  const formatBpm = (UserRecommendationBPM) => {
    const newDdta = UserRecommendationBPM.map((item) => {
      item.label = bpmKeys[item.label];
      return item;
    });

    return newDdta;
  };

  const catObj=(obj)=>{
    {
      const { name, _id } = obj;
      return { label: name, value: _id, key: _id };
    }
  }

  const getMusic = async (musicID) => {
    try {
      setIsLoading(true);
      const { data } = await getMusicReq(musicID);
      if (data.data.isPublished || data.data.isDisabled)
        history.push('dashboard/music/all');  

      setFormData({
        title: data.data.title,
        description: data.data.description,
        isFree: data.data.isFree || false,
        language: data.data.language,
        UserRecommendationMood: 'Select Mood',
        UserRecommendationStress: 'Select Stress Level',
      });
      setLevel(data?.data?.level);
      setUserRecommendationBPM(
        formatBpm(formatArray(data?.data?.UserRecommendationBPM))
      );
      setUserRecommendationAge(formatArray(data?.data?.UserRecommendationAge));
      setUserRecommendationMaritalStatus(
        formatArray(data?.data?.UserRecommendationMaritalStatus)
      );
      setUserRecommendationFocusOnPresentMoment(
        formatArray(data?.data?.UserRecommendationFocusOnPresentMoment)
      );
      setUserRecommendationExperienceStressAnxiety(
        formatArray(data?.data?.UserRecommendationExperienceStressAnxiety)
      );
      setUserRecommendationSleep(
        formatArray(data?.data?.UserRecommendationSleep)
      );
      setUserRecommendationHoursOfSleep(
        formatArray(data?.data?.UserRecommendationHoursOfSleep)
      );
      setUserRecommendationIrritatedOrAngry(
        formatArray(data?.data?.UserRecommendationIrritatedOrAngry)
      );
      setUserRecommendationFrequentLethargy(
        formatArray(data?.data?.UserRecommendationFrequentLethargy)
      );
      setUserRecommendationCommitmentAtWork(
        formatArray(data?.data?.UserRecommendationCommitmentAtWork)
      );
      setUserRecommendationIssuesInRelationship(
        formatArray(data?.data?.UserRecommendationIssuesInRelationship)
      );
      setUserRecommendationLowSelfEsteem(
        formatArray(data?.data?.UserRecommendationLowSelfEsteem)
      );
      setUserRecommendationHoursSpendOnSocialMedia(
        formatArray(data?.data?.UserRecommendationHoursSpendOnSocialMedia)
      );
      setUserRecommendationGender(
        formatArray(data?.data?.UserRecommendationGender)
      );
      setCategories(catObj(data.data.categorie));
      setSubCategories(formatForReactSelect(data.data.subCategories));
      setTags(formatForReactSelect(data.data.tags));
      let moodApiData = data.data.UserRecommendationMood;
      setMoods(formatArray(moodApiData));
      let stressApiData = data.data.UserRecommendationStress;
      setStressLevel(formatArray(stressApiData));
      setThumbnailColor(data.data.thumbnailColorPalette);
      setThumbnail(data.data.assetImage);
      setImg115x150(data.data.img115x150);
      setImg145x345(data.data.img145x345);
      setImg155x135(data.data.img155x135);
      setImg180x160(data.data.img180x160);
      setIsLoading(false);
    } catch (error) {
      console.log(error,'error')
    }
  };

  useEffect(() => {
    getMusic(musicID);
    getAllTagsData();
    setIsDisabledBtn(false);
    // getAllUserData();
    // eslint-disable-next-line
  }, []);

  // Edit Music
  const handleSubmit = async () => {
    if (!moods || moods?.length === 0)
      return setError({
        isError: true,
        errorMessage: 'Please select Moods.',
        errorType: 'warning',
      });
    if (!stressLevel || stressLevel?.length === 0)
      return setError({
        isError: true,
        errorMessage: 'Please select Stress Levels.',
        errorType: 'warning',
      });
    if (!categories || categories?.length === 0)
      return setError({
        isError: true,
        errorMessage: 'Please select Categories',
        errorType: 'warning',
      });
    if (!UserRecommendationGender || UserRecommendationGender?.length === 0)
      return setError({
        isError: true,
        errorMessage: 'Please select Gender',
        errorType: 'warning',
      });
    if (!UserRecommendationAge || UserRecommendationAge?.length === 0) {
      return setError({
        isError: true,
        errorMessage: 'Please select Age',
        errorType: 'warning',
      });
    }
    if (
      !UserRecommendationMaritalStatus ||
      UserRecommendationMaritalStatus?.length === 0
    )
      return setError({
        isError: true,
        errorMessage: 'Please select Marital Status',
        errorType: 'warning',
      });
    if (!UserRecommendationBPM || UserRecommendationBPM?.length === 0)
      return setError({
        isError: true,
        errorMessage: 'Please select BPM',
        errorType: 'warning',
      });

    try {
      let thumbnailUrl = null;
      let img115x150Url = null;
      let img145x345Url = null;
      let img180x160Url = null;
      let img155x135Url = null;

      if (img115x150 && typeof img115x150 !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        img115x150Url = await uploadFile(
          img115x150,
          fileTypes.IMG_115X150,
          setImg115x150UploadPercent,
          10
        );
      } else setImg115x150UploadPercent(10);

      if (img145x345 && typeof img145x345 !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        img145x345Url = await uploadFile(
          img145x345,
          fileTypes.IMG_145X345,
          setImg145x345UploadPercent,
          10
        );
      } else setImg145x345UploadPercent(10);

      if (img180x160 && typeof img180x160 !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        img180x160Url = await uploadFile(
          img180x160,
          fileTypes.IMG_180X160,
          setImg180x160UploadPercent,
          10
        );
      } else setImg180x160UploadPercent(10);

      if (img155x135 && typeof img155x135 !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        img155x135Url = await uploadFile(
          img155x135,
          fileTypes.IMG_155X135,
          setImg155x135UploadPercent,
          10
        );
      } else setImg155x135UploadPercent(10);

      if (typeof thumbnail !== 'string') {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        thumbnailUrl = await uploadFile(
          thumbnail,
          fileTypes.TRACK_IMAGE,
          setThumbnailUploadPercentage,
          40
        );
      } else setThumbnailUploadPercentage(40);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,
      });
     const {data} = await editMusicReq(
        {
          title,
          description,
          isFree,
          language,
          UserRecommendationGender: formatMultiSelectData(
            UserRecommendationGender
          ),
          UserRecommendationMaritalStatus: formatMultiSelectData(
            UserRecommendationMaritalStatus
          ),
          UserRecommendationAge: formatMultiSelectData(UserRecommendationAge),
          UserRecommendationBPM: formatMultiSelectData(UserRecommendationBPM),
          UserRecommendationMood: formatMultiSelectData(moods),
          UserRecommendationStress: formatMultiSelectData(stressLevel),
          UserRecommendationFocusOnPresentMoment: formatMultiSelectData(
            UserRecommendationFocusOnPresentMoment
          ),
          UserRecommendationExperienceStressAnxiety: formatMultiSelectData(
            UserRecommendationExperienceStressAnxiety
          ),
          UserRecommendationSleep: formatMultiSelectData(
            UserRecommendationSleep
          ),
          UserRecommendationHoursOfSleep: formatMultiSelectData(
            UserRecommendationHoursOfSleep
          ),
          UserRecommendationIrritatedOrAngry: formatMultiSelectData(
            UserRecommendationIrritatedOrAngry
          ),
          UserRecommendationFrequentLethargy: formatMultiSelectData(
            UserRecommendationFrequentLethargy
          ),
          UserRecommendationCommitmentAtWork: formatMultiSelectData(
            UserRecommendationCommitmentAtWork
          ),
          UserRecommendationIssuesInRelationship: formatMultiSelectData(
            UserRecommendationIssuesInRelationship
          ),
          UserRecommendationLowSelfEsteem: formatMultiSelectData(
            UserRecommendationLowSelfEsteem
          ),
          UserRecommendationHoursSpendOnSocialMedia: formatMultiSelectData(
            UserRecommendationHoursSpendOnSocialMedia
          ),
          level:level,
          categorie: formatMultiSelectData(categories),
          subCategories: formatMultiSelectData(subCategories),
          tags: formatMultiSelectData(tags),
          ...(typeof file !== 'string' && {
            assetImage: thumbnailUrl,
            thumbnailColorPalette: thumbnailColor,
          }),
          img115x150: img115x150Url,
          img145x345: img145x345Url,
          img180x160: img180x160Url,
          img155x135: img155x135Url,
        },
        musicID
      );
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      // await transCodingApi({
      //   assetURL:data?.data?.assetURL,
      //   title:data?.data?.title,
      //   description:data?.data?.description,
      //   assetImage:data?.data?.assetImage,
      //   id: data?.data?._id
      // })
    } catch (error) {
      console.error('error', error);
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      setError({
        isError: true,
        errorMessage: 'Unable to create Music please try again later',
        errorType: 'danger',
      });
    }
  };
  console.log(subCategories,'subCategories');
  const [selectedSubCat, setSelectedSubCat] = useState([])
  const getAllMediaSubCategory = async(params)=>{
  const { data } = await getMediaApi(params)
  setSelectedSubCat(
    data.data.map(item=>{
      return{
        key: item._id,
        label: item.name,
        value: item._id,
      }
    })
  )
  // setGetSubCategory(data.data.map(item=>{
  //   return{
  //     key: item._id,
  //     label: item.name,
  //     value: item._id,
  //   }
  // }))
  setGetSubCategoryCount(data.count)
}

 useEffect(()=>{
  getAllMediaSubCategory({objectType: "SUBCATEGORY", page:1, limit:getSubCategoryCount})
 },[getSubCategoryCount])

  const [allCatData, setAllCatData] = useState([]);
  const getAllMediaCategory = async(params)=>{
    const { data } = await getMediaApi(params)
    setAllCatData(data.data);
    setGetCategory(data.data.map(item=>{
      return{
        key: item._id,
        label: item.name,
        value: item._id,

      }
    }))
    setGetCategoryCount(data.count)
    
  }
   useEffect(()=>{
    getAllMediaCategory({objectType: "CATEGORY", page:1, limit:getCategoryCount})
   },[getCategoryCount])

 

   const getAllMediaTags = async(params)=>{
    const { data } = await getMediaApi(params)
    setGetTags(data.data.map(item=>{
      return{
        key: item._id,
        label: item.name,
        value: item._id,

      }
    }))
    setGetTagsCount(data.count)
  }

   useEffect(()=>{
    getAllMediaTags({objectType: "TAG", page:1, limit:getTagsCount})
   },[getTagsCount])


   
   const filteredSubCat = () => {
    const filteredCategory =
    allCatData &&
    allCatData.length > 0 &&
    allCatData?.find((item) => item?._id === categories?.key);
    console.log(filteredCategory,'filteredCategory');
  const newSubCategory =
  selectedSubCat &&
  selectedSubCat.length > 0 &&
  selectedSubCat?.filter((subcategory) =>{
    return filteredCategory?.refIds?.includes(subcategory?.key)}

    );
    console.log(newSubCategory,'filteredCategory');
  setGetSubCategory(newSubCategory);
  
  };
  useEffect(() => {
    filteredSubCat();
  }, [categories,allCatData,selectedSubCat]);

  return (
    <>
      {isLoading ? (
        <>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h3 className='m-0'>Edit Video</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          {isCreating ? (
            <UploadingMusicLoader
              currentStep={creatingStage}
              thumbnailuploadPercentage={
                thumbnailuploadPercentage +
                img115x150UploadPercent +
                img145x345UploadPercent +
                img155x135UploadPercent +
                img180x160UploadPercent
              }
              showSelectAudio={false}
              showThumbnailUpload={true}
              processingWord='Updating'
              processedWord='Updated'
              mediaVideo={true}
            />
          ) : (
            <MusicForm
              musicID={musicID}
              pageHeading='Edit Video Media'
              handelInput={handelInput}
              title={title}
              description={description}
              level={level}
              handleLevelSelect={handleLevelSelect}
              language={language}
              UserRecommendationGender={UserRecommendationGender}
              handleGenderSelect={handleGenderSelect}
              genderData={genderData}
              UserRecommendationAge={UserRecommendationAge}
              ageData={ageData}
              handleAgeSelect={handleAgeSelect}
              maritalData={maritalData}
              handleMaritalSelect={handleMaritalSelect}
              UserRecommendationMaritalStatus={UserRecommendationMaritalStatus}
              UserRecommendationMood={UserRecommendationMood}
              UserRecommendationBPM={UserRecommendationBPM}
              bpmData={bpmData}
              handleBpmSelect={handleBpmSelect}
              UserRecommendationStress={UserRecommendationStress}
              handleMoodsSelect={handleMoodsSelect}
              moods={moods}
              handleStressLevelSelect={handleStressLevelSelect}
              UserRecommendationFocusOnPresentMoment={
                UserRecommendationFocusOnPresentMoment
              }
              handlefocusOnPresentMomentSelect={
                handlefocusOnPresentMomentSelect
              }
              focusOnPresentMomentData={focusOnPresentMomentData}
              UserRecommendationExperienceStressAnxiety={
                UserRecommendationExperienceStressAnxiety
              }
              handleExperienceStressAnxiety={handleExperienceStressAnxiety}
              experienceStressAnxietyData={experienceStressAnxietyData}
              UserRecommendationSleep={UserRecommendationSleep}
              handleUserSleep={handleUserSleep}
              sleepData={sleepData}
              UserRecommendationHoursOfSleep={UserRecommendationHoursOfSleep}
              handleUserSleepHours={handleUserSleepHours}
              sleepHoursData={sleepHoursData}
              UserRecommendationIrritatedOrAngry={
                UserRecommendationIrritatedOrAngry
              }
              irritatedOrAngryData={irritatedOrAngryData}
              handleIrritatedOrAngry={handleIrritatedOrAngry}
              UserRecommendationFrequentLethargy={
                UserRecommendationFrequentLethargy
              }
              lethargyData={lethargyData}
              handleLethargy={handleLethargy}
              UserRecommendationCommitmentAtWork={
                UserRecommendationCommitmentAtWork
              }
              commitWorkData={commitWorkData}
              handleCommitWork={handleCommitWork}
              UserRecommendationIssuesInRelationship={
                UserRecommendationIssuesInRelationship
              }
              handleIssueInRelationship={handleIssueInRelationship}
              issueRelationshipData={issueRelationshipData}
              UserRecommendationLowSelfEsteem={UserRecommendationLowSelfEsteem}
              esteemData={esteemData}
              handleEsteem={handleEsteem}
              UserRecommendationHoursSpendOnSocialMedia={
                UserRecommendationHoursSpendOnSocialMedia
              }
              handleHoursOnSocialMedia={handleHoursOnSocialMedia}
              hoursOnSocialData={hoursOnSocialData}
              stressLevel={stressLevel}
              handleCategoriesSelect={handleCategoriesSelect}
              categories={categories}
              handleSubCategoriesSelect={handleSubCategoriesSelect}
              subCategories={subCategories}
              handleTagsSelect={handleTagsSelect}
              tagsData={tagsData}
              tags={tags}
              moodData={moodData}
              stressLevelData={stressLevelData}
              categoryData={categoryData}
              subCategoryData={subCategoryData}
              setThumbnail={handleChangeThumbnail}
              thumbnail={thumbnail}
              handleSubmit={handleSubmit}
              isError={isError}
              errorMessage={errorMessage}
              errorType={errorType}
              showSelectAudio={false}
              createBtnText={'Edit Video Media'}
              isDisabledBtn={isDisabledBtn}
              isFree={isFree}
              setThumbnailColor={setThumbnailColor}
              thumbnailColor={thumbnailColor}
              img115x150={img115x150}
              setImg115x150={handleImg115x150}
              img145x345={img145x345}
              setImg145x345={handleImg145x345}
              img155x135={img155x135}
              setImg155x135={handleImg155x135}
              img180x160={img180x160}
              setImg180x160={handleImg180x160}
              getCategory={getCategory}
              setGetCategory= {setGetCategory}
              getSubCategory = {getSubCategory}
              setGetSubCategory={setGetSubCategory}
              getTags={getTags}
              setGetTags={setGetTags}
              getCategoryCount={getCategoryCount}
              setGetCategoryCount={setGetCategoryCount}
              getSubCategoryCount={getSubCategoryCount}
              setGetSubCategoryCount={setGetSubCategoryCount}
              getTagsCount={getTagsCount}
              setGetTagsCount={setGetTagsCount}
              editMediaVideo={true}
              // subCat={true}
              // filterSubData={filterSubData}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditVedio;
