import React, { useState, useEffect } from "react";
import { CardBody } from "reactstrap";
import Flatpickr from "react-flatpickr";

import ReactApexChart from "react-apexcharts";

import {
  getNDaysOldDate,
  sortGraphData,
  getGraphConfig,
} from "../../UsersAnalyticsCard.helpers";

import "flatpickr/dist/themes/material_green.css";

const RevenueGraphCard = ({ mainHeading, graphData, handleUpdateData }) => {
  const [graphSettings, setGraphSettings] = useState({
    series: null,
    options: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(getNDaysOldDate(30));
  const [endDate, setEndDate] = useState(getNDaysOldDate(0));
  const onChangeHandler = (dates) => {
    const [start, end] = dates;
    if (start && end) {
      handleUpdateData({
        startDate: start,
        endDate: end,
      });
      setIsLoading(true);
      setStartDate(start);
      setEndDate(end);
    }
  };

  useEffect(() => {
    const sortedData = sortGraphData(graphData);
    const graphConfigData = getGraphConfig(sortedData);
    setGraphSettings(graphConfigData);
    setIsLoading(false);
  }, [graphData]);

  const { series, options } = graphSettings;

  return (
    <>
      <CardBody>
        <div
          className="float-right d-none d-md-inline-block"
          style={{ width: "50%" }}
        >
          {/* <Flatpickr
            style={{ width: "100%" }}
            placeholder="Select Date range"
            value={[startDate, endDate]}
            options={{ mode: "range", maxDate: new Date() }}
            onChange={onChangeHandler}
            disabled={isLoading}
          /> */}
        </div>
        <h4 className="card-title mb-4">{mainHeading || "RevenueAnalytics"}</h4>
        <div>
          {series && options && (
            <div
              id="line-column-chart"
              className="apex-charts"
              dir="ltr"
              style={{ position: "relative" }}
            >
              {isLoading && (
                <div
                  className="spinner spinner-container"
                  style={{ position: "absolute" }}
                >
                  <i className="ri-loader-line spin-icon"></i>
                </div>
              )}

              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="290"
              />
            </div>
          )}
        </div>
      </CardBody>
    </>
  );
};

export default RevenueGraphCard;
