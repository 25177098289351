import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import CoursesRoutes from './Constants/routes'

const Courses = () => {
  const match = useRouteMatch()
  return (
    <>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {CoursesRoutes.map(({ path, Component, id, exact }) => (
          <Route
            path={`${match.url}${path}`}
            component={Component}
            exact={exact}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/courses" />
      </Switch>
    </>
  );
}

export default Courses