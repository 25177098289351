import React from "react";
import { useState, useEffect } from "react";
import {
  getAllMedia,
  getPlaylistByid,
  updatePlaylistDetails,
  addMediaToPlaylist,
  removeMediaFromPlaylist,
  getAllTags
} from "../../Playlist.Apis";
import { useParams } from "react-router-dom";
import EditPlaylistComp from "../../../../../../Components/EditPlaylistComp";
import { creatingStates } from "../../Playlist.constants";
import SweetAlert from "react-bootstrap-sweetalert";

import { useHistory } from "react-router-dom";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";

const EditPlaylist = () => {
  const { playlistId } = useParams();
  const history = useHistory();
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;
  const [removeMedia,setRemoveMedia] =useState({
    name:"",
  })
  const [ isRemoved , setIsRemoved ] = useState(false);
  const[ isAdded , setIsAdded ] = useState(false);
  const [ selectedMedia,setSelectedMedia] =useState([])
  const {name} = removeMedia
  const [thumbnailUploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const[showPostPlaylistConfirm,setShowPostPlaylistConfirm] = useState(false);
  const [getData, setGetData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const [selectedTags,setSelectedTags] = useState('');
  const [tagsData,setTagsData] = useState([]);

  const [coverImage, setCoverImage] = useState(null);
  const [playlistData, setPlaylistData] = useState({
    title: "",
    description: "",
    type: "PLAYLIST",
    mediaData: [],
    mediaType: "",
  });
  const { title, description, type, mediaData, mediaType } = playlistData;
  const [showPostFaqConfirm, setShowPostFaqConfirm] = useState(false);

  const getAllMediaData = async (queryParams) => {
    try {
      const { data } = await getAllMedia(queryParams);
      setGetData(data.data);
    } catch (error) { 
      console.log("error565");
    }
  };

  const getAllTagsData = async () => {
    const {data} = await getAllTags();
    console.log(data?.data,'hurraaahhhh');
    setTagsData(data?.data)
  }

  const getPlaylistById = async () => {
    try {
      const { data } = await getPlaylistByid(playlistId);
      setPlaylistData({
        title: data.data.title,
        description: data.data.description,
        type: "PLAYLIST",
        mediaData: data.data.mediaData,
        mediaType: data.data.mediaType,
      });
      setCoverImage(data.data.imgAsset);
      
      setSelectedTags(
        data?.data?.tags?.map((ele) => {
          return {
            key: ele?._id,
            label: ele?.name,
            value: data?.data?.tags,
          };
        })
      )
    } catch (error) {
      console.log("error");
    }
  };
  const handleAddMediaPlaylist = async (preId,title) =>{
    try {
      const {data} = await addMediaToPlaylist(preId,{title})
      setIsAdded(true)
    } catch (error) {
      console.log('error')
    }
  }
  const handleRemoveMediaFromPlaylist = async (preId,  title ) =>{
    try {
      const {data} = await removeMediaFromPlaylist(preId,{title})
      setIsRemoved(true)
    } catch (error) {
      console.log('error')
    }
  }

  const handleInput = (e) => {
    setPlaylistData({
      ...playlistData,
      [e.target.name]: e.target.value,
    });
  };

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };


  useEffect(() => {
    getPlaylistById();
    getAllTagsData();
    getAllMediaData({ page:1 , limit:20});
  }, []);
  const updatePlaylistData = async () => {
    let thumbnailUrl = null;
    if (coverImage && typeof coverImage !== "string") {
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });
      thumbnailUrl = await uploadFile(
        coverImage,
        "IMAGE",
        setContentUploadPercentage,
        50
      );
    } else setContentUploadPercentage(50);

    setCreating({
      isCreating: true,
      creatingStage: creatingStates.CREATING_MUSIC,
    });
    try {
      await updatePlaylistDetails(playlistId, {
        title,
        description,
        type: "PLAYLIST",
        mediaData,
        imgAsset: thumbnailUrl,
        mediaType,
        tags:formatMultiSelectData(selectedTags)
      });
      setShowPostPlaylistConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <EditPlaylistComp
        heading={"Edit Playlist"}
        title={title}
        description={description}
        mediaData={mediaData}
        mediaType={mediaType}
        playlistData={playlistData}
        setPlaylistData={setPlaylistData}
        getData={getData}
        handleUpdate= {updatePlaylistData}
        playlistDescription = {playlistData}
        coverImage = {coverImage}
        setCoverImage = {setCoverImage}
        handleInput = {handleInput}
        handleAdd = {handleAddMediaPlaylist}
        showAddButton={showAddButton}
        setShowAddButton={setShowAddButton}
        showRemoveButton={showRemoveButton}
        selectedMedia={selectedMedia}
        setSelectedMedia={setSelectedMedia}
        setShowRemoveButton={setShowRemoveButton}
        handleRemove={(preId, name) =>
          handleRemoveMediaFromPlaylist(preId, name)
        }
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        tagsData={tagsData}
      />
      {isRemoved? (
        <SweetAlert
        success
        title="Removed"
        confirmBtnBsStyle="primary"
        onConfirm={()=>{window.location.reload()}}
        />
      ):null}
      {isAdded?(
          <SweetAlert
          success
          title="Added"
          confirmBtnBsStyle = "primary"
          onConfirm = {()=>{window.location.reload()}}
          />
        ):null}
      {showPostPlaylistConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push('/dashboard/playlist/all')
          }}
        ></SweetAlert>
      ) : null}
    
    </>
  );
};

export default EditPlaylist;
