export const strokeColorTypes = {
  PRIMARY: "#5664d2",
  SUCCESS: "#1cbb8c",
  DANGER: "#ff3d60",
  YELLO: "#eeb902",
  CRIMSON_RED: "#DC143C",
};

export const getGraphConfig = ({ percent, color }) => {
  return {
    series: [percent || 0],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [
        strokeColorTypes[color || "PRIMARY"] || strokeColorTypes.PRIMARY,
      ],
      stroke: {
        lineCap: "round",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%",
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
  };
};
