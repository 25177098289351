import AllLessons from "../Views/AllLessons";
import CreateNewLesson from "../Views/CreateNewLesson";
import EditLesson from "../Views/EditLessons";

export const LessonsSubRoute = [
  {
    Component: AllLessons,
    path: "/all",
    exact: true,
    id: "dashboardAllLesson",
    routePath: "/dashboard/lessons/all",
    name: "All Lesson",
  },
  {
    Component: CreateNewLesson,
    path: "/create",
    exact: true,
    id: "dashboardLessonCreate",
    routePath: "/dashboard/lessons/create",
    name: "Create Lesson",
  },
  {
    Component: EditLesson,
    path: "/edit/:lessonId",
    exact: true,
    id: "dashboardEditLesson",
  },
];
