import axios from 'axios';

import baseDomain, {
  userAllAPI,
  userDisableAPI,
  userEnableAPI,
} from '../../Constants/apiRoutes';

export const EnableUserReq = (userID) => {
  return axios.put(`${baseDomain}${userEnableAPI}/${userID}`);
};

export const DisableUserReq = (userID) => {
  return axios.put(`${baseDomain}${userDisableAPI}/${userID}`);
};

export const GetAllUsers = () => {
  return axios.get(`${baseDomain}${userAllAPI}`);
};
