import AllPlans from "../Components/AllPlans";
import CreatePlans from "../Components/CreatePlans";
import PlansBin from "../Components/PlansBin";


export const PlanRoutes = [
  {
    Component: AllPlans,
    path: "/all",
    exact: true,
    id: "dashboardPlanAll",
    routePath: "/dashboard/plan/all",
    name: "All Plans",
  },
  {
    Component: CreatePlans,
    path: "/create",
    exact: true,
    id: "dashboardPlanCreate",
    routePath: "/dashboard/plan/create",
    name: "Add Plans",
  },
  {
    Component: PlansBin,
    path: "/bin",
    exact: true,
    id: "dashboardPlanBin",
    routePath: "/dashboard/plan/bin",
    name: "Plans Bin",
  }
];
