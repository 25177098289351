import React from "react";
import { useState, useEffect } from "react";
import {
  sleepSessionTab,
  sleepSessionTabUnpublish,
} from "../../SleepSessions.constants";
import SleepSessionCard from "../../../../../../Components/SleepSessionCard";
import { Parser } from "json2csv";
import {
  UnpublishQuestionAction,
  getAllQuestionsApi,
  getAllQuestionsApiUnpublish,
  pusblishQuestionsAction,
  AllUsersData
} from "../../SleepSessions.Api";
import SleepSessionCardUnpublish from "../../../../../../Components/SleepSessionCardUnpublish";
import {
  UnpublishSession,
  publishSession,
} from "../../../BookingHomeSessions/Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import { CSVLink } from "react-csv";

const AllQuestiions = () => {
  const [sessionData, setSessionData] = useState([]);
  const [sessionDataUnpublish, setSessionDataUnpublish] = useState([]);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [csvData,setCsvData] = useState([])

  const [publishID, setPublishID] = useState("");

  const getAllQuestionsPublished = async () => {
    try {
      const { data } = await getAllQuestionsApi();
      setSessionData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllQuestionsUnPublished = async () => {
    try {
      const { data } = await getAllQuestionsApiUnpublish();
      setSessionDataUnpublish(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUsersDataCsv = async () => {
    try {
      const { data } = await AllUsersData();
      setCsvData(data?.data)
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  };
console.log(csvData,'printData')
  useEffect(() => {
    getAllQuestionsPublished(),
    getAllQuestionsUnPublished();
    getAllUsersDataCsv();
  }, []);


  const handleAllExportToCSV = () => {
    const extractLearningTitles = (learningArray) => {
      return learningArray.map(learning => learning.title).join(', ');
  };
    const csvDataAll = csvData.map((item) => ({
      id:item._id,
      UserId: item?.userId?._id,
        FirstName: item?.userId?.firstName,
        LastName: item?.userId?.lastName,
        Email: item?.userId?.email,
        PhoneNumber: item?.userId?.phoneNumber,
        Goal: item?.goal,
        QnA: item.qna.map(q => `Q: ${q.question?.question} A: ${q.answer}`).join(' | '),
        CreatedAt: item?.createdAt, // Trimming the date
        UpdatedAt: item?.updatedAt  // Trimming the date
      
        // AppointmentDate: item.appointmentDate,
        // CreatedAt: item.createdAt.substring(0, 10),
        // ExpiryDate: item.expDate.substring(0, 10),
        // IapId: item.iapId,
        // IsBooked: item.isBooked,
        // IsJoined: item.isJoined,
        // IsPaid: item.isPaid,
        // // PlanId: item.planId,
        // PlanDescription: item.planId.plan_description,
        // PlanDisplayPrice: item.planId.plan_display_price,
        // PlanDurationInDays: item.planId.plan_duration_indays,
        // PlanIdentifier: item.planId.plan_identifier,
        // Id: item._id,
        // // Time: item.time, // Assuming 'time' field is available
        // SelectedDays: item.time.selectedDays,
        // SessionTime: item.time.sessionTime,
        // StartDate: item.time.startDate,
        // // UpdatedAt: item.updatedAt,
        // UserAge: item.userForm.age,
        // UserHealthCondition: item.userForm.healthCondition,
        // ParticipantName: item.userForm.participantName,
        // TeacherPreference: item.userForm.teacherPreference,
        // Timezone: item.userForm.timezone,
        // UserId: item.userId._id,
        // UserEmail: item.userId.email,
        // UserFirstName: item.userId.firstName,
        // UserLastName: item.userId.lastName,
        // UserPhoneNumber: item.userId.phoneNumber,
        // // YogaClassId: item.yogaClassId._id,
        // YogaClassCreatedAt: item.yogaClassId.createdAt.substring(0, 10),
        // // YogaClassTitle: item.yogaClassId.learning.title[0],
        // // SupportAdvisorId: item.supportAdvisorId,
        // YogaClassImage: item.yogaClassId.image,
        // YogaClassIsDisabled: item.yogaClassId.isDisabled,
        // YogaClassIsPublished: item.yogaClassId.isPublished,
        // YogaClassLearning: extractLearningTitles(item.yogaClassId.learning), 
        // YogaClassFAQId: item.yogaClassId.faqId,
        // YogaClassUpdatedAt: item.yogaClassId.updatedAt.substring(0, 10),
    }));
    // console.log(csvData,'here is the data')
    return csvDataAll;
    
};


  const publishQuestionAction = async () => {
    try {
      await pusblishQuestionsAction(publishID);

      const filteredQuestions = sessionData.filter(
        ({ _id }) => _id !== publishID
      );
      setSessionData(filteredQuestions);
      setPublishID("");
    } catch (error) {
      console.log(error);
    }
  };
  const UnPublishQuestionAction = async () => {
    try {
      await UnpublishQuestionAction(publishID);
      const filteredQuestions = sessionDataUnpublish.filter(
        ({ _id }) => _id !== publishID
      );
      setSessionDataUnpublish(filteredQuestions);
      setPublishID("");
    } catch (error) {
      console.log(error);
    }
  };
  const handlePublishSleepSessionClick = async (id) => {
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishSleepSessionClick = async (id) => {
    setPublishID(id);
    setShowPublishAlert(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Sleep sessions</h2>
        <CSVLink
        data={handleAllExportToCSV()}
        filename={'selected_users.csv'}
        className="export-csv-btn" >
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" ,cursor:'pointer'}}
          
          // onClick={() => handleUnPublishSleepSessionClick(row._id)}
        >
          Export to Csv
        </Button>
        </CSVLink>
      </div>
      <SleepSessionCard
        tableData={sessionData}
        tableHeading={sleepSessionTab}
        handlePublishSleepSessionClick={handlePublishSleepSessionClick}
      />
      <SleepSessionCardUnpublish
        tableData={sessionDataUnpublish}
        tableHeading={sleepSessionTabUnpublish}
        handleUnPublishSleepSessionClick={handleUnPublishSleepSessionClick}
      />
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={() => {
            UnPublishQuestionAction(), setShowUnPublishConfirm(true), setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm = {() => {
            window.location.reload();
            setShowUnPublishConfirm(false);
          }}
        >
          Question has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={() => {
            publishQuestionAction(), setShowPublishConfirm(true), setShowPublishAlert(false);
          }}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            window.location.reload();
            setShowPublishConfirm(false)
            
          }}
        >
          Question has been published
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllQuestiions;
