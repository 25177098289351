import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { createOfferReq } from "../../Views/Dashboard/Components/Offers/Offer.Api";

const OfferForm = ({
  pageHeading,
  offerData,
  buttonText,
  update,
}) => {
  const [formData, setFormData] = useState({
    offerCode: "",
    expireTime: "",
    discount: "",
    couponDescription:"",
    objectType:null,
    countryCode: null
  });
  const history = useHistory();

  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });

  useEffect(() => {
    if (offerData) {
      const {
        offerCode,
        discount,
        expireTime,
        objectType,
        description,
        countryCode,
        couponDescription
      } = offerData;
      setFormData({
        ...formData,
        offerCode: offerData.offerCode,
        discount: offerData.discount,
        description: offerData.couponDescription,
        expireTime: offerData.expireTime,
        countryCode: offerData.countryCode,
        objectType:offerData.objectType
      });
    }
  }, [offerData]);


  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const countryCodeData = [
    { key: "INTERNATIONAL", label: "INTERNATIONAL", value: "INTERNATIONAL" },
  { key: "NATIONAL", label: "NATIONAL", value: "NATIONAL" },
  ];
  const offerTypeData = [
    { key: "flat", label: "flat", value: "flat" },
  { key: "percentage", label: "percentage", value: "percentage" },
  ];


  const handleCountryCode = (select) => {
    setFormData({...formData, countryCode:select})  
  };

  const handleOfferType =(select) =>{
    setFormData({...formData, objectType:select})  

  }

  const handleSubmit = async () => {
    if (
      formData.offerCode === "" ||
      formData.couponDescription === "" ||
      formData.discount === "" ||
      formData.expireTime === "" ||
      formData.countryCode.value === ""||
      formData.objectType.value === ""

    ) {
      console.log(formData,'formData')
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Required field empty",
      });
    } else {
      try {
          await createOfferReq({...formData,countryCode:formData.countryCode.value,objectType:formData.objectType.value});
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: `${update?"Updated":"Created"} Sucessfully`,
        });
        setTimeout(() => {
          history.push("/dashboard/offer/all");
          setIsDisabledBtn(false);
        }, 1000);
      } catch (error) {
        setAlert({
          isAlert: true,
          alertType: "danger",
          alertText: "Unable to Create",
        });
        console.error(error.response.data, error);
      }
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="title">Offer Code</Label>
                    <AvField
                      name="offerCode"
                      placeholder="Enter offer Code"
                      value={formData.offerCode}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="offerCode"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                <FormGroup className="select2-container">
                    <Label className="control-label">Select Offer Type</Label>
                    <Select
                      value={formData.objectType}
                      isMulti={false}
                      onChange={handleOfferType}
                      options={offerTypeData}
                      classNamePrefix="select2-selection"
                      placeholder="Select Offer Type"
                    />
                  </FormGroup>
                </Col>

              </Row>
              <Row>
              <Col xs={6}>
                  <FormGroup>
                  <Label htmlFor="title">Description</Label>
                    <AvField
                      name="couponDescription"
                      placeholder="Enter description"
                      value={formData.couponDescription}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Description is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="couponDescription"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Discount Price</Label>
                    <AvField
                      name="discount"
                      placeholder="Enter Discount Price"
                      value={formData.discount}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Discount Price is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="discount"
                    />
                  </FormGroup>
                </Col>
                
              </Row>
              <Row>
              <Col xs={6}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Expire Time</Label>
                    <AvField
                      name="expireTime"
                      placeholder="Enter Expire Time"
                      value={formData.expireTime}
                      onChange={handelInput}
                      type="date"
                      errorMessage="Expire Time is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="expireTime"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Select Country Code</Label>
                    <Select
                      value={formData.countryCode}
                      isMulti={false}
                      onChange={handleCountryCode}
                      options={countryCodeData}
                      classNamePrefix="select2-selection"
                      placeholder="Select Country Code"
                    />
                  </FormGroup>
                </Col> 
              </Row>
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {buttonText}
              </Button>
            </AvForm>
            <br />
            <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role="alert">
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default OfferForm;
