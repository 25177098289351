import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
  Modal,
  Alert,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { editPlan } from "./ViewPlan.Api";

const ViewPlanModal = ({
  planID,
  isOpen,
  toggleModal,
  isEditable = false,
  data,
  isLoading,
  populatePlansData,
}) => {
  const handleClose = () => {
    toggleModal();
    setAlert({ isAlert: false });
  };

  const [isNotEdited, setIsNotEdited] = useState(true);
  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });

  const [formData, setFormData] = useState({
    plan_identifier: "",
    plan_description: "",
    plan_display_price: "",
    plan_discount_price: "",
    plan_duration_indays: "",
    IOS: false,
    Android: false,
    WEB: false,
    freeDays:"",
  });

  useEffect(() => {
    setFormData({
      plan_identifier: data && data.plan_identifier,
      plan_description: data && data.plan_description,
      plan_display_price: data && data.plan_display_price,
      plan_discount_price: data && data.plan_discount_price,
      plan_duration_indays: data && data.plan_duration_indays,
      country_code: data && data.country_code,
      IOS: data && data.IOS,
      Android: data && data.Android,
      WEB: data && data.WEB,
      freeDays: data.freeDays
    });
  }, [data]);

  const handelInput = (e) => {
    setIsNotEdited(false);
    let val = e.target.name;
    if (val === "IOS" || val === "Android" || val === "WEB") {
      return setFormData({
        ...formData,
        [val]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [val]: e.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    if (
      formData.plan_identifier === "" ||
      formData.plan_description === "" ||
      formData.plan_discount_price === "" ||
      formData.plan_display_price === "" ||
      formData.plan_duration_indays === ""
    ) {
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Required field empty",
      });
    } else if (
      formData.IOS === false &&
      formData.Android === false &&
      formData.WEB === false
    ) {
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Please select atleast one device platform",
      });
    } else {
      try {
        const { data } = await editPlan(planID, formData);    
        populatePlansData(data.data);
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Updated Sucessfully",
        });
        setTimeout(() => {
          setAlert({ isAlert: false });
          toggleModal();
        }, 1000);
      } catch (error) {
        setAlert({
          isAlert: true,
          alertType: "danger",
          alertText: "Unable to Update",
        });
        console.error(error.response, error);
      }
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <Row>
        <Col lg={12}>
          <Card>
            {!isLoading && (
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h3 className="m-0">
                        {isEditable ? "Edit Plan" : "View Plan"}
                      </h3>
                    </div>
                  </Col>
                </Row>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label htmlFor="title">Plan Identifier</Label>
                        <AvField
                          name="plan_identifier"
                          placeholder="Enter Plan Identifier"
                          value={formData.plan_identifier}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Title is required"
                          className="form-control"
                          disabled={!isEditable}
                          validate={{ required: { value: true } }}
                          id="plan_identifier"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label htmlFor="description">Plan Description</Label>
                    <AvField
                      name="plan_description"
                      placeholder="Enter Plan description"
                      value={formData.plan_description}
                      onChange={handelInput}
                      type="textarea"
                      errorMessage="Description is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="plan_description"
                      disabled={!isEditable}
                    />
                  </FormGroup>

                  <Row>
                    <Col ls={4}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Display Price</Label>
                        <AvField
                          name="plan_display_price"
                          placeholder="Enter Display Price"
                          value={formData.plan_display_price}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Display Price is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="plan_display_price"
                          disabled={!isEditable}
                        />
                      </FormGroup>
                    </Col>
                    <Col ls={4}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Discount Price</Label>
                        <AvField
                          name="plan_discount_price"
                          placeholder="Enter Discount Price"
                          value={formData.plan_discount_price}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Discount Price is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="plan_discount_price"
                          disabled={!isEditable}
                        />
                      </FormGroup>
                    </Col>
                    <Col ls={4}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Duration</Label>
                        <AvField
                          name="plan_duration_indays"
                          placeholder="Enter Duration in Days"
                          value={formData.plan_duration_indays}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Display Price is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="plan_duration_indays"
                          disabled={!isEditable}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col ls={4}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Country Code</Label>
                        <AvField
                          name="country_code"
                          placeholder="Country Code"
                          value={formData.country_code}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Display Price is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="plan_duration_indays"
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col ls={4}>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Free Days</Label>
                        <AvField
                          name="freeDays"
                          placeholder="Enter Free Days"
                          value={formData.freeDays}
                          onChange={handelInput}
                          type="text"
                          errorMessage="Free Days is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="plan_free_days"
                          disabled={!isEditable}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <div
                        className="custom-control custom-switch mb-2 "
                        dir="ltr"
                      >
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="IOS"
                          checked={formData.IOS}
                          onClick={handelInput}
                          id="IOS"
                          disabled={!isEditable}
                        ></Input>
                        <Label className="custom-control-label" htmlFor="IOS">
                          iOS
                        </Label>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div
                        className="custom-control custom-switch mb-2 "
                        dir="ltr"
                      >
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="Android"
                          checked={formData.Android}
                          onClick={handelInput}
                          id="Android"
                          disabled={!isEditable}
                        ></Input>
                        <Label
                          className="custom-control-label"
                          htmlFor="Android"
                        >
                          Android
                        </Label>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div
                        className="custom-control custom-switch mb-2 "
                        dir="ltr"
                      >
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="WEB"
                          checked={formData.WEB}
                          onClick={handelInput}
                          id="WEB"
                          disabled={!isEditable}
                        ></Input>
                        <Label className="custom-control-label" htmlFor="WEB">
                          WEB
                        </Label>
                      </div>
                    </Col>
                  </Row>

                  {isEditable && (
                    <Button
                      color="primary"
                      className="w-100 mt-4"
                      type="submit"
                      disabled={!isEditable || isNotEdited}
                      // onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </AvForm>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {alert.isAlert && (
            <Alert color={alert.alertType} role="alert">
              {alert.alertText}
            </Alert>
          )}
        </Col>
      </Row>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewPlanModal;
