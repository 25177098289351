import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Button } from "reactstrap";
import FileUploader from "../FileUploader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import { useState } from "react";
import DatePicker from "react-flatpickr";

const LiveSessionCreateComp = ({
  pageHeading = "Create New Live Session",
  handleChangeCourse,
  handleCreateLiveSession,
  headerImg,
  setHeaderImg,
  faq,
  setFaq,
  setAdName,
  adName,
  setLearnImgThree,
  setLearnTitleThree,
  setLearnImgTwo,
  setLearnTitleTwo,
  learnImgThree,
  learnTitleThree,
  learnImgTwo,
  learnTitleTwo,
  setLearnImg,
  learnImg,
  setContent,
  formData,
  setFormData,
  faqData,
  title,
  description,
  setDescription,
  image,
  advisor,
  setAdvisor,
  supportAdvisor,
  learnTitle,
  setLearnTitle,
  setMeetLink,
  meetLink,
  selectedDate,
  selectDate,
  setSelectedDate,
  setSelectDate,
  validationError,
  setValidationError
}) => {
  let advisorName =
    advisor &&
    advisor?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.name,
      };
    });

  const handleAdvisor = (selectedData) => {
    setAdName(selectedData);
  };
  const handleBlur = () => {
    if (!selectedDate) {
      setValidationError('Please select a start date and time.');
    } else {
      setValidationError('');
    }
  };
  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate[0]); // DatePicker returns an array, we take the first element
  };
  // let lessonObj =
  //   lessonsData &&
  //   lessonsData?.map((i) => {
  //     return {
  //       key: i._id,
  //       value: i._id,
  //       label: i.title,
  //     };
  //   });

  let faqName =
    faqData &&
    faqData?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.title,
      };
    });

  const rawContentState = convertToRaw(description.getCurrentContent());
  // const certificateState = convertToRaw(outComeCompletion.getCurrentContent());

  const markup = draftToHtml(
    rawContentState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );

  setContent(markup);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
                onValidSubmit={() => handleCreateLiveSession()}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="title">Live Session Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter title"
                        value={title}
                        onChange={handleChangeCourse}
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FileUploader
                      file={headerImg}
                      setFile={setHeaderImg}
                      dragText="Drop Header Image here"
                      heading="Header Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="supportAdvisor">Co-ordinator Name</Label>
                      <Select
                        value={adName}
                        isMulti={false}
                        onChange={handleAdvisor}
                        options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Co-ordinator Name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="Faqs">FAQs</Label>
                      <Select
                        value={faq}
                        isMulti={true}
                        onChange={(selected) => setFaq(selected)}
                        options={faqName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Lessons"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="assetUrl">Live Session Link</Label>
                      <AvField
                        name="assetUrl"
                        placeholder="Enter Link"
                        value={meetLink}
                        onChange={handleChangeCourse}
                        type="text"
                        errorMessage="Link is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="assetUrl"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="startDate">Start Date and Time</Label>
                      <DatePicker
                        className="date_picker"
                        placeholderText="Select Start Date and Time"
                        selected={selectedDate}
                        value={selectedDate}
                        form={formData}
                        setField={setFormData}
                        keyName="startDate"
                        dateFormat="Y-m-d H:i"
                        onBlur={handleBlur} 
                        onChange={handleDateChange}
                        options={{
                          enableTime: true,
                          noCalendar: false,
                          altInput: true,
                          altFormat: "F j, Y H:i", 
                          time_24hr: true,
                        }}
                      />
                       {validationError && (<div className="text-danger">{validationError}</div>)}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="endDate">End Date and Time</Label>
                      <DatePicker
                        className="date_picker"
                        placeholderText="Select End Date and Time"
                        selected={selectDate}
                        value={selectDate}
                        form={formData}
                        setField={setFormData}
                        keyName="endDate"
                        dateFormat="Y-m-d H:i"
                        onChange={(selectDate) => {
                          setSelectDate(selectDate);
                          setFormData({
                            ...formData,
                            endDate: selectDate,
                          });
                          if (!selectDate) {
                            setValidationError(
                              "Please select start date and time."
                            );
                          } else {
                            setValidationError("");
                          }
                        }}
                        options={{
                          enableTime: true,
                          noCalendar: false,
                          altInput: true,
                          altFormat: "F j, Y H:i",
                          time_24hr: true,
                        }}
                      />
                       {validationError && <div className="text-danger">{validationError}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label htmlFor="description">Things to Note (desc)</Label>
                    <Editor
                      editorState={description}
                      toolbarClassName="toolbarClass"
                      wrapperClassName="wrapperClass"
                      editorClassName="editorClass"
                      onEditorStateChange={setDescription}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="mt-3" htmlFor="learning">
                        What you’ll Learn (Learning)
                      </Label>
                      <Row>
                        <Col>
                          <Label htmlFor="learnTitle">Title </Label>
                          <AvField
                            name="learnTitle"
                            placeholder="Enter title"
                            value={learnTitle}
                            onChange={(e) => setLearnTitle(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="learnTitle"
                          />

                          <FileUploader
                            file={learnImg}
                            setFile={setLearnImg}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="learnTitleTwo">Title</Label>
                          <AvField
                            name="learnTitleTwo"
                            placeholder="Enter title"
                            value={learnTitleTwo}
                            onChange={(e) => setLearnTitleTwo(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="learnTitleTwo"
                          />

                          <FileUploader
                            file={learnImgTwo}
                            setFile={setLearnImgTwo}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                        <Col>
                          <Label htmlFor="learnTitleThree">Title</Label>
                          <AvField
                            name="learnTitleThree"
                            placeholder="Enter title"
                            value={learnTitleThree}
                            onChange={(e) => setLearnTitleThree(e.target.value)}
                            type="text"
                            errorMessage="Title name is required"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="learnTitleThree"
                          />

                          <FileUploader
                            file={learnImgThree}
                            setFile={setLearnImgThree}
                            dragText="Drop Image here"
                            heading="Learning Image"
                            accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={() => handleCreateLiveSession()}
                  // disabled={isDisabledBtn}
                >
                  Create Live Session
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LiveSessionCreateComp;
