import AllQuotes from "../Components/AllQuotes";
import QuotesBin from "../Components/QuotesBin";

export const QuotesRoutes = [
  {
    Component: AllQuotes,
    path: "/all",
    exact: true,
    id: "dashboardQuotesAll",
    routePath: "/dashboard/quotes/all",
    name: "All Quotes",
  },
  {
    Component: QuotesBin,
    path: "/bin",
    exact: true,
    id: "dashboardQuotesBin",
    routePath: "/dashboard/quotes/bin",
    name: "Quotes Bin",
  },
];
