import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import AllHomeSessionsComp from '../../../../../../../../Components/AllHomeSessionsComp'
import { getAllHomeSessionsApi, getCSVDataHomeSessions } from '../../../Constants/api'
import { allNutriClassiHeading } from '../../../Constants/table'

const AllHomeSessions = () => {

  const [homeSessionsData, setHomeSessionsData] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = publishPage;
  const [csvData,setCsvData] = useState([])

  const getAllHomeSessionsData = async(params) =>{
    try {
      setIsLoading(true)
      const {data} = await getAllHomeSessionsApi(params)
      console.log(data.data);
      setHomeSessionsData(data?.data)
      setPublishPage({
        ...publishPage,
        currentPage:params?.page,
        totalSize:data?.count
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
 
  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllHomeSessionsData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  const getAllUsersDataCsv = async (params) => {
    try {
      const { data } = await getCSVDataHomeSessions(params);
      setCsvData(data?.data)
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  };
  console.log(csvData,'printData')

  const handleAllExportToCSV = () => {
    const extractLearningTitles = (learningArray) => {
      return learningArray.map(learning => learning.title).join(', ');
    };
  
    const csvDataAll = csvData.map((item) => ({
      Id: item?._id,
      UserId: item?.userId._id,
      UserFirstName: item?.userId.firstName,
      UserLastName: item?.userId.lastName,
      UserPhoneNumber: item?.userId.phoneNumber,
      UserEmail: item?.userId.email,
      ParticipantName: item?.userForm.participantName,
      UserAge: item?.userForm.age,
      Timezone: item?.userForm.timezone,
      TeacherPreference: item?.userForm.teacherPreference,
      UserHealthCondition: item?.userForm.healthCondition,
      AppointmentDate: item?.appointmentDate ? new Date(item.appointmentDate).toISOString().substring(0, 10) : '',
      CreatedAt: item?.createdAt ? new Date(item.createdAt).toISOString().substring(0, 10) : '',
      ExpiryDate: item?.expDate ? new Date(item.expDate).toISOString().substring(0, 10) : '',
      IapId: item?.iapId,
      IsBooked: item?.isBooked,
      IsJoined: item?.isJoined,
      IsPaid: item?.isPaid,
      PlanDescription: item?.planId.plan_description,
      PlanDisplayPrice: item?.planId.plan_display_price,
      PlanDurationInDays: item?.planId.plan_duration_indays,
      PlanIdentifier: item?.planId.plan_identifier,
      SelectedDays: item?.time.selectedDays.join(', '),
      SessionTime: item?.time.sessionTime ? new Date(item.time.sessionTime).toISOString().substring(0, 10) : '',
      StartDate: item?.time.startDate ? new Date(item.time.startDate).toISOString().substring(0, 10) : '',
      YogaClassId: item?.yogaClassId._id,
      YogaClassCreatedAt: item?.yogaClassId.createdAt ? new Date(item.yogaClassId.createdAt).toISOString().substring(0, 10) : '',
      YogaClassImage: item?.yogaClassId.image,
      YogaClassIsDisabled: item?.yogaClassId.isDisabled,
      YogaClassIsPublished: item?.yogaClassId.isPublished,
      YogaClassLearning: extractLearningTitles(item.yogaClassId.learning),
      YogaClassFAQId: item?.yogaClassId.faqId.join(', '),
      YogaClassUpdatedAt: item?.yogaClassId.updatedAt ? new Date(item.yogaClassId.updatedAt).toISOString().substring(0, 10) : '',
    }));
  
    return csvDataAll;
  };
  

  useEffect(()=>{
    getAllHomeSessionsData({page:1, limit:10})
    getAllUsersDataCsv()
  },[])
  return (
    <>
    <AllHomeSessionsComp
    tableData={homeSessionsData}
    tableHeading={allNutriClassiHeading}
    handleTablePageChange={handleTablePageChange}
    isLoading={isLoading}
    page={currentPage}
    sizePerPage={10}
    totalSize={totalSize}
    handleAllExportToCSV={handleAllExportToCSV}
    />
    </>
  )
}

export default AllHomeSessions