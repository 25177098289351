import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";

const EditDiscoverNispandComp = ({
  mediaData,
  seriesData,
  selectedMedia,
  setSelectedMedia,
  setSelectedPlaylist,
  selectedPlaylist,
  setSelectedSeries,
  selectedSeries,
  playlistData,
  objType,
  setObjType,
  title,
  description,
  handleChange,
  order,
  setOrder,
  handleSubmit,
  setShowUnPublishAlert,
  modal,
  setModal,
  toggle,
  errMsg,
  setErrMsg,
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: "40px",
    }),
  };
  const playlistName =
    playlistData &&
    playlistData?.map((i) => {
      return {
        key: i?._id,
        label: i?.title,
        value: i?._id,
      };
    });

  const mediaName =
    mediaData &&
    mediaData?.map((i) => {
      return {
        key: i?._id,
        label: i?.title,
        value: i?._id,
      };
    });

  const seriesName =
    seriesData &&
    seriesData?.map((i) => {
      return {
        key: i?._id,
        label: i?.title,
        value: i?._id,
      };
    });

  const orderValue = [
    {
      key: "Change Order-No",
      label: "Change Order-No",
      value: 1714771,
    }, 
    {
      key: "1",
      label: "1",
      value: 1,
    },
    {
      key: "2",
      label: "2",
      value: 2,
    },
    {
      key: "3",
      label: "3",
      value: 3,
    },
    {
      key: "4",
      label: "4",
      value: 4,
    },
    {
      key: "5",
      label: "5",
      value: 5,
    },
    {
      key: "6",
      label: "6",
      value: 6,
    },
    {
      key: "7",
      label: "7",
      value: 7,
    },
    {
      key: "8",
      label: "8",
      value: 8,
    },
    {
      key: "9",
      label: "9",
      value: 9,
    },
    {
      key: "10",
      label: "10",
      value: 10,
    },
  ];

  const objectValue = [
    {
      key: "MEDIA",
      label: "MEDIA",
      value: "MEDIA",
    },
    {
      key: "SERIES",
      label: "SERIES",
      value: "SERIES",
    },
    {
      key: "PLAYLIST",
      label: "PLAYLIST",
      value: "PLAYLIST",
    },
  ];

  return (
    <>
      {objType && (
        <Row>
          <Col style={{ height: "100vh" }} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h3 className="m-0">Edit Discover</h3>
                    </div>
                  </Col>
                </Row>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={() => handleSubmit()}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="title">Title</Label>
                        <AvField
                          name="title"
                          placeholder="Enter title"
                          value={title}
                          onChange={handleChange}
                          type="text"
                          errorMessage="Title is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="title"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="description">Description</Label>
                        <AvField
                          name="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={handleChange}
                          type="text"
                          errorMessage="description is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="description"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="objectType">Select type of Media</Label>
                        <Select
                          value={[objType]}
                          isMulti={false}
                          onChange={(select) => {
                            setObjType(select);
                          }}
                          options={objectValue}
                          classNamePrefix="select2-selection"
                          placeholder={objType}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="order">Order Number</Label>
                        <Select
                          value={order}
                          isMulti={false}
                          onChange={(select) => setOrder(select)}
                          options={orderValue}
                          classNamePrefix="select2-selection"
                          // placeholder={order}
                          styles={customStyles}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {objType?.value === "MEDIA" && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="metaIds">Select Media Title</Label>
                          <Select
                            value={selectedMedia}
                            isMulti={true}
                            onChange={(select) => {
                              setSelectedMedia(select); // Update selectedMedia
                              setSelectedPlaylist(""); // Clear selectedPlaylist
                              setSelectedSeries(""); // Clear selectedSeries
                            }}
                            options={mediaName}
                            classNamePrefix="select2-selection"
                            placeholder="Select Media Title"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {objType?.value === "PLAYLIST" && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="metaIds">Select Playlist Title</Label>
                          <Select
                            value={selectedPlaylist}
                            isMulti={true}
                            onChange={(select) => {
                              setSelectedMedia(""); // Update selectedMedia
                              setSelectedPlaylist(select); // Clear selectedPlaylist
                              setSelectedSeries(""); // Clear selectedSeries
                            }}
                            options={playlistName}
                            classNamePrefix="select2-selection"
                            placeholder="Select Playlist Title"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {objType?.value === "SERIES" && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="metaIds">Select Series Title</Label>
                          <Select
                            value={selectedSeries}
                            isMulti={true}
                            onChange={(select) => {
                              setSelectedMedia(""); // Update selectedMedia
                              setSelectedPlaylist(""); // Clear selectedPlaylist
                              setSelectedSeries(select); // Clear selectedSeries
                            }}
                            options={seriesName}
                            classNamePrefix="select2-selection"
                            placeholder="Select Series Title"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Button
                    color="primary"
                    className="w-100 mt-4"
                    type="submit"
                    // onClick={() => handleSubmit()}
                  >
                    Update Discover Nispand
                  </Button>
                </AvForm>
                {modal === true && (
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>{errMsg}</ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle}>
                        OK
                      </Button>
                    </ModalFooter>
                  </Modal>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EditDiscoverNispandComp;
