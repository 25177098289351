import React from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { OfferRoutes } from "./Constants/routes";


const Offer = () => {
  const match = useRouteMatch();
  return (
    <>
       <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {OfferRoutes.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/Offer" />
      </Switch> 
     
    </>
  );
};

export default Offer;
