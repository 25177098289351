import axios from "axios";

import baseDomain, { quoteAPI, quoteAPIAll, quoteBinAPI, restoreQuoteAPI, searchQuoteAPI } from "../../../../Constants/apiRoutes";

export const getQuote = ({ page, limit }) => {
  return axios.get(`${baseDomain}${quoteAPIAll}?page=${page}&limit=${limit}`);
};

export const searchQuote = (text) => {
  return axios.get(`${baseDomain}${searchQuoteAPI}/${text}`);
};

export const getQuoteBin = ({ page, limit }) => {
  return axios.get(`${baseDomain}${quoteBinAPI}?page=${page}&limit=${limit}`);
};

export const restoreQuote = (quoteID) => {
  return axios.put(`${baseDomain}${restoreQuoteAPI}/${quoteID}`);
};

export const deleteQuote = (quoteID) => {
  return axios.delete(`${baseDomain}${quoteAPI}/${quoteID}`);
};
