import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AllUserByHomeSessionsComp from "../../../../../../../../Components/AllUserByHomeSess";
import { getAllUserByHomeSessionsIdApi } from "../../../Constants/api";
import { NutriHomeHeading } from "../../../Constants/table";

const AllUserByHomeSessions = () => {
  const [homeSessionsUser, setHomeSessionsUser] = useState();
  const { sessionsId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });

  const { currentPage, totalSize } = publishPage;

  const convertToIST = (isoString) => {
    const date = new Date(isoString);
    const istOffset = 5.5 * 60 * 60 * 1000; 
    const istDate = new Date(date.getTime() + istOffset);

    const day = String(istDate.getUTCDate()).padStart(2, '0');
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); 
    const year = istDate.getUTCFullYear();

    const hours = String(istDate.getUTCHours()).padStart(2, '0');
    const minutes = String(istDate.getUTCMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };


  const getAllUserByHomeSessionsIdData = async(params) =>{
    try {
      setIsLoading(true);
      const { data } = await getAllUserByHomeSessionsIdApi(params);
      // setHomeSessionsUser(data?.data)
      console.log(data.data, "getAllUserByHomeSessionsIdApi");
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });

      let tempArr = data?.data?.map((item) => {
        return {
          userName: item?.userForm?.participantName,
          userEmail: item?.userId?.email,
          userContact: item?.userId?.phoneNumber,
          appDate: convertToIST(item?.time?.startDate),  // Convert to IST
          appTime: convertToIST(item?.time?.sessionTime), // Convert to IST
          plan: item?.planId?.plan_description,
          price: item?.planId?.plan_display_price,
          title:item?.nutriId?.title,
          advisorName:item?.supportAdvisorId?.name,
          endDate: item?.expDate,
          userId: item?._id,
          meetingLink: item?.time?.appointmentJoiningLink,
          planDesc: item?.planId?.plan_description
        };
      });
      console.log(tempArr, "tempArr");
      setIsLoading(false);
      setHomeSessionsUser(tempArr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllUserByHomeSessionsIdData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };
  console.log(sessionsId,'session vali id')
  useEffect(()=>{
    
    getAllUserByHomeSessionsIdData({ id: sessionsId, page: 1, limit: 10 })
  },[])

  console.log(homeSessionsUser, 'homesessionuser')

  return (
    <AllUserByHomeSessionsComp
      tableData={homeSessionsUser}
      tableHeading={NutriHomeHeading}
      handleTablePageChange={handleTablePageChange}
      isLoading={isLoading}
      page={currentPage}
      sizePerPage={10}
      totalSize={totalSize}
      sessionsId={sessionsId}
    />
  );
};

export default AllUserByHomeSessions;
