export const CreateFaqTagData = {
  FAQTAG: {
    heading: "Tag",
    labelName: "Tag Name",
    nameError: "Enter Tag Name",
    labelDescription: "Tag Description",
    descriptionError: "Enter Tag Description",
    createBtn: "Create Tag",
  },
  FAQCATEGORY: {
    heading: "Category",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Category Description",
    descriptionError: "Enter Category Description",
    createBtn: "Create Category",
  },

};
