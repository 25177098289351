import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";
import { useHistory } from "react-router-dom";

const Tabcard = ({
  heading = "MIND and BODY Tabs",
  tableData = [],
  tableHeading = [],
  sizePerPage,
  publishBlog,
  buttonName,
  handleTablePageChange,
  page,
  totalSize,
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  const handleEditUserClick = (tabId) => {
    history.push("/dashboard/Mind/all/" + tabId);
  };

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => {
            handleEditUserClick(row._id);
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    return () => {
      tableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map((el, i) => {
        var o = Object.assign({ ...el });
        o.sNo = i + 1;
        return o;
      });

      setRowData(result);
    }
  }, [tableData]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                // onTableChange={handleTablePageChange}
                // page={page}
                // sizePerPage={sizePerPage}
                // totalSize={totalSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Tabcard;
