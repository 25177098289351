import React from "react";
import { Row, Col, Card, CardBody, Button, Label, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

const SleepSessionEditForm = ({
  heading,
  handleChange,
  handleChangeQuestions,
  formData,
  selectedOption,
  setSelectedOption,
  options,
  optionsData,
  title,
  updateQuestions,
  ansOptions,
  handleAddAnswer,
  handleRemoveAnswer,
  handleAnswerChange,
  error,
}) => {
  return (
    <Row>
      <Col style={{ height: "100vh" }} lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{heading}</h3>
                </div>
              </Col>
            </Row>

            <AvForm className="form-horizontal">
              <FormGroup>
                <Label htmlFor="question">Enter the question</Label>
                <AvField
                  name="questions"
                  placeholder="Enter Question here"
                  value={formData.title}
                  onChange={handleChangeQuestions}
                  type="text"
                  errorMessage="Question is required"
                  className="form-control"
                  id="questionare"
                />
              </FormGroup>

              <Button
                color="primary"
                className="w-40"
                type="button"
                onClick={handleAddAnswer}
              >
                Add options +
              </Button>

              {ansOptions.map((option, index) => (
                <FormGroup key={index}>
                  <Label htmlFor={`option-${index}`}>Option {index + 1}</Label>
                  <AvField
                    name={`option-${index}`}
                    placeholder={`Enter Option ${index + 1}`}
                    value={option}
                    onChange={(e) => handleAnswerChange(e, index)}
                    type="text"
                    errorMessage="Answer is required"
                    className="form-control"
                    id={`option-${index}`}
                  />
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => handleRemoveAnswer(index)}
                  >
                    Remove
                  </Button>
                </FormGroup>
              ))}
              {ansOptions.length === 4 && error && (
                <div style={{ color: "red" }}>{error}</div>
              )}
            </AvForm>

            <Row>
              <Col ls={4}>
                <FormGroup>
                  <Label htmlFor="orderNo">
                    Select the order of the question
                  </Label>
                  <Select
                    value={selectedOption}
                    isMulti={false}
                    onChange={handleChange}
                    options={options}
                    classNamePrefix="selection"
                    placeholder="Select the order"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button
              color="primary"
              className="w-100"
              type="button"
              onClick={updateQuestions}
            >
              Update Question
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SleepSessionEditForm;
