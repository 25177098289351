import React from "react";
import { allTabs } from "../../Mind.Api";
import { useState, useEffect } from "react";
import { tabsTableHeading } from "../../Mind.Constants";
import Tabcard from "../../../../../../Components/MindTabCard";

const MindTab = () => {
  const [tabData, setTabData] = useState([]);

  const allMindTabs = async() => {

    try {
      const { data } = await allTabs()
      setTabData(data.data)
    } catch (error) {
      console.log("error1")   
    }
  }
  
  useEffect (() => {
    allMindTabs();
  },[])
  
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>All tabs</h2>
      </div>
      <Tabcard
      tableData = {tabData}
      tableHeading = { tabsTableHeading }

       />
    </>
  );
};

export default MindTab;
