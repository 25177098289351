import React, { Fragment, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import DashboardLayout from "../../Layout/DashboardLayout";
// import Breadcrumbs from "../../Components/Breadcrumbs";

import AuthContext from "../../context/auth/authContext";

import { DashboardRoutes } from "./Constants/routes";

const Dashboard = () => {
  const authContext = useContext(AuthContext);

  const match = useRouteMatch();

  useEffect(() => {
    authContext.retainLogin();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!authContext.isLoading && (
        <>
          {authContext.isLoggedIn ? (
            <DashboardLayout>
              <div className="page-content" style={{minHeight: '100vh'}}>
                <Container fluid>
                  {/* <Breadcrumbs title="Dashboard" /> */}
                  <Switch>
                    <Redirect
                      exact
                      from={`${match.url}/`}
                      to={`${match.url}/home`}
                    />
                    {DashboardRoutes.map(({ path, Component, exact, id }) => (
                      <Route
                        path={`${match.url}${path}`}
                        exact={exact}
                        component={Component}
                        key={id}
                      />
                    ))}
                    <Redirect to="/dashboard/home" />
                  </Switch>
                </Container>
              </div>
            </DashboardLayout>
          ) : (
            <Redirect from={`${match.url}/`} to={`/auth`} />
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
