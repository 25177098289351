import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";

const PublishedPlaylist = ({
  heading = " ",
  tData = [],
  tableHeading = [],
  sizePerPage,
  unpublishPlaylist,
  page,
  handleTablePageChange,
  totalSize,
}) => {
  const [rowsData, setRowsData] = useState([]);
  const publishFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => unpublishPlaylist(row._id) }
        >
          Unpublish
        </Button>
      </div>
    );
  };
  useEffect(() => {
    tableHeading.push({
      text: "Unpublish",
      dataField: "unpublish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: publishFormatter,
    });

    return () => {
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tData) {
      var output = tData.map((e, i) => {
        var x = Object.assign({ ...e });
        x.sNo = i + 1;
        return x;
      });
      setRowsData(output);
    }
  }, [tData]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowsData}
                columns={tableHeading}
                // onTableChange={handleTablePageChange}
                // page={page}
                // sizePerPage={sizePerPage}
                // totalSize={totalSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PublishedPlaylist;
