import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Table from "../Table";

const AllTimeByIdComp = ({
  tableHeading,
  tableData,
  page,
  sizePerPage,
  totalSize,
  advisorName,
}) => {
  const [rowData, setRowData] = useState([]);

  const history = useHistory();

  const handleEditUserClick = (slotId, date, advisor) => {
    history.push("/dashboard/time-slots/edit/" + slotId + "?date=" + date + "&advisorId=" + advisor);
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <div style={{ textAlign: "center" }} key={row._id}>
          <i
            className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
            onClick={() => {
              handleEditUserClick(row._id, row.ISODate, row.supportAdvisorId);
            }}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData) {
      var result = tableData.map((el, i) => {
        var date = new Date(el.date)
        var o = Object.assign({ ...el });
        o.ISODate = el.date;
        o.date = (date.getDate() + 1) + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        o.sNo = i + 1;
        return o;
      });

      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);
  console.log(tableData)
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{`Time Slots of Advisor ${advisorName}`}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                // onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllTimeByIdComp;
