import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  Button,
  Form,
} from "reactstrap";

import AuthContext from "../../context/auth/authContext";
import Table from "../Table";
import { EnableUserReq, DisableUserReq } from "./UsersCard.Api";

const UsersCard = ({
  tableData = [],
  tableHeading = [],
  setUsers,
  handleTablePageChange,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleView,
  filterData,
  setFilterDate,
  isFilterApplied,
  setIsFilterApplied,
}) => {
  const [rowData, setRowData] = useState([]);
  const authContext = useContext(AuthContext);
  const { countrySearch, enabledQuery, subscribedQuery } = filterData;

  const toggleFilterApplied = () => setIsFilterApplied(!isFilterApplied);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterDate({
      ...filterData,
      [name]: value,
    });
  };

  const subscriptionFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        {row.isSubscribe ? "Subscribed" : " Not Subscribed"}
      </div>
    );
  };

  const subscriptionPlanTYpeFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        {row?.subscriptionsId?.plan_description ? row.subscriptionsId.plan_description : " NA"}
      </div>
    );
  };

  const viewFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleView(row)}
        >
          View
        </Button>
      </div>
    );
  };

  const actionsFormatter = (cell, row) => {
    const handleDisableUser = async (userID) => {
      if (userID === authContext.user._id) return;
      if (row.isDisabled) {
        await EnableUserReq(userID);
      } else {
        await DisableUserReq(userID);
      }
      setUsers((usrs) => {
        const temp = usrs.map((user) => {
          if (user._id === userID) {
            return {
              ...user,
              isDisabled: !row.isDisabled,
            };
          }
          return user;
        });
        return temp;
      });
    };
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <div className="custom-control custom-switch" dir="ltr">
          <Input
            type="checkbox"
            className="custom-control-input"
            checked={row.isDisabled}
            id={row._id}
            onClick={() => handleDisableUser(row._id)}
          ></Input>
          <Label className="custom-control-label" htmlFor={row._id}></Label>
        </div>
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Subscription",
      dataField: "subscription",
      // isDummyField: true,
      headerStyle: { textAlign: "center", width: "5%" },
      formatter: subscriptionFormatter,
    });
    tableHeading.push({
      text: "Plan Type",
      dataField: "subscriptionsId.plan_description",
      // isDummyField: true,
      headerStyle: { textAlign: "center", width: "5%" },
      formatter: subscriptionPlanTYpeFormatter,
    });
    tableHeading.push({
      text: "View",
      dataField: "view",
      headerStyle: { textAlign: "center", width: "20px" },
      formatter: viewFormatter,
    });
    tableHeading.push({
      text: "Disable",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "5%" },
      formatter: actionsFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
      tableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1 + page * 10 - 10;
        o.subscription = el?.subscriptionsId?.subsription;
        o.plan_description = el?.subscriptionsId?.plan_description;
        o.plan_duration_indays = el?.subscriptionsId?.plan_duration_indays;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between w-100 p-0">
                  <h3 className="m-0">Users</h3>
                  <div className="page-title-box d-flex">
                    <div>
                      <div className="page-title-box d-flex align-items-center justify-content-center p-0">
                        <h4>Country</h4>
                      </div>
                      <Form>
                        <div className="search-box">
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control rounded bg-light border-0"
                              placeholder="Search..."
                              name="countrySearch"
                              value={countrySearch}
                              onChange={handleInputChange}
                            />
                            <i className="mdi mdi-magnify search-icon"></i>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="ml-3">
                      <div className="page-title-box d-flex align-items-center justify-content-center p-0">
                        <h4>Enabled</h4>
                      </div>
                      <select
                        className="form-control"
                        onChange={handleInputChange}
                        name="enabledQuery"
                        value={enabledQuery}
                      >
                        <option value={"ALL"}>All</option>
                        <option value={"true"}>Enabled</option>
                        <option value={"false"}>Disabled</option>
                      </select>
                    </div>
                    <div className="ml-3">
                      <div className="page-title-box d-flex align-items-center justify-content-center p-0">
                        <h4>Subscribed</h4>
                      </div>
                      <select
                        className="form-control"
                        onChange={handleInputChange}
                        name="subscribedQuery"
                        value={subscribedQuery}
                      >
                        <option value={"ALL"}>All</option>
                        <option value={"true"}>Subscribed</option>
                        <option value={"false"}>Non Subscribed</option>
                        <option value={"FREETRAIL"}>Free Trail</option>
                      </select>
                    </div>
                    <div className="ml-4">
                      <h4>Apply Filter</h4>
                      <div className="page-title-box d-flex align-items-center justify-content-center w-100 p-0">
                        <div className="custom-control custom-switch" dir="ltr">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            checked={isFilterApplied}
                            id="filter"
                            onClick={toggleFilterApplied}
                          ></Input>
                          <Label
                            className="custom-control-label"
                            htmlFor="filter"
                          ></Label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersCard;
