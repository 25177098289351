import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import { createDiscoverReq } from "../../Views/Dashboard/Components/Discover/Discover.Apis";
import FileUploader from "../FileUploader";
import { uploadFile } from "../../Views/Dashboard/Components/Discover/Discover.Helpers";
import { fileTypes } from "../../Views/Dashboard/Components/Discover/Discover.Constants";

const DiscoverForm = ({
  pageHeading = "Create Discovers",
  isEditable = true,
  formData,
  setFormData,
}) => {
  const history = useHistory();

  const [discoverSeries, setDiscoverSeries] = useState([]);
  const [currentSeries, setCurrentSeries] = useState([]);
  const [mainDiscoverSeries, setMainDiscoverSeries] = useState({});


  const addIntoDiscoverSeries = (e) => {
    setCurrentSeries(e.target.value);
  }

  const handleAddToSeries = () => {
    setDiscoverSeries([...discoverSeries, currentSeries]);
  }

  useEffect(() => {
    var abcd = {};
    discoverSeries.forEach(key => abcd[key] = []);
    setMainDiscoverSeries(abcd);
  }, [discoverSeries])

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });

  const [image, setImage] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [seriesBackgroundImage, setSeriesBackgroundImage] = useState();

  const [imgUploadProgress, setImgUploadProgress] = useState(0);
  const [backgroundImgUploadProgress, setBackgroundImgUploadProgress] = useState(0);
  const [seriesBackgroundImgUploadProgress, setSeriesBackgroundImgUploadProgress] = useState(0);

  const createBtnText = imgUploadProgress === 0 && backgroundImgUploadProgress === 0 && seriesBackgroundImgUploadProgress === 0 ? "Create Discover" : "Creating";

  // useEffect(() => {
  //   let keys = Object.keys(formData?.modules?.Series)
  //   setDiscoverSeries(keys)
  // }, [])

  const handelInput = (e) => {
    let val = e.target.name;
    setFormData({ ...formData, [val]: e.target.value });
  };

  const handleSubmit = async () => {
    if (
      formData.name === "" || 
      formData.language === "" ||
      formData.card_desription === "" ||
      formData.series_desription === "" ||
      formData.singles_desription === "" ||
      formData.dayOne === "" ||
      formData.dayTwo === "" ||
      formData.dayThree === "" ||
      formData.dayFour === "" ||
      formData.dayFive === "" ||
      !image ||
      !backgroundImage ||
      !seriesBackgroundImage
    ) {
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Required field empty",
      });
    } else {
      setIsDisabledBtn(true);
      let imageUrl = null;
      let backgroundImageUrl = null;
      let seriesBackgroundImageUrl = null;
      if (image) {
        imageUrl = await uploadFile(
          image,
          fileTypes.DISCOVER_IMAGE,
          setImgUploadProgress,
          5
        );
      }
      if (backgroundImage) {
        backgroundImageUrl = await uploadFile(
          backgroundImage,
          fileTypes.BACKGROUND_IMAGE,
          setBackgroundImgUploadProgress,
          5
        );
      }
      if (seriesBackgroundImage) {
        seriesBackgroundImageUrl = await uploadFile(
          seriesBackgroundImage,
          fileTypes.SERIES_BACKGROUND_IMAGE,
          setSeriesBackgroundImgUploadProgress,
          5
        );
      }
      // console.log(formData)
      const seriesObject = {
        [formData.dayOne]:[],
        [formData.dayTwo]:[],
        [formData.dayThree]:[],
        [formData.dayFour]:[],
        [formData.dayFive]:[],
      }
      try {
        await createDiscoverReq({ ...formData, image: imageUrl, backgroundImage: backgroundImageUrl, seriesBackgroundImage: seriesBackgroundImageUrl, modules: { Series: seriesObject, Singles: {} } });
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: "Created Sucessfully",
        });
        setTimeout(() => {
          history.push("/dashboard/discover");
          setIsDisabledBtn(false);
        }, 1000);
      } catch (error) {
        setAlert({
          isAlert: true,
          alertType: "danger",
          alertText: "Unable to Create",
        });
        console.error(error.response.data, error);
      }
    }
  };


  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h3 className='m-0'>{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Col lg={2}>
                  <FormGroup>
                    <div dir='ltr'>
                      <Label htmlFor='notificationSelect'>Language</Label>
                      <Input
                        name='language'
                        placeholder='Select Type'
                        type='select'
                        className='form-control'
                        value={formData.language}
                        onChange={handelInput}
                        validate={{ required: { value: true } }}
                        id='languageSelect'
                      >
                        <option value='en'>ENGLISH</option>
                        <option value='hi'>HINDI</option>
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor='name'>Discover Name</Label>
                    <AvField
                      name='name'
                      placeholder='Enter Discover Name'
                      value={formData.name}
                      onChange={handelInput}
                      type='text'
                      disabled={!isEditable}
                      errorMessage='Discover Name is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='name'
                    />
                  </FormGroup>
                </Col>

                {/* redirect toggle  commented */}
                {/* <Col className="d-flex align-items-center mt-3" xs={2}>
                  <div className="custom-control custom-switch mb-2 " dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      name="redirect"
                      checked={formData.redirect}
                      onClick={(e) => setFormData({ ...formData, redirect: e.target.checked })}
                      id="redirect"
                    />
                    <Label className="custom-control-label" htmlFor="redirect">
                      Is Redirect
                    </Label>
                  </div>
                </Col> */}
              </Row>

              <FormGroup>
                <Label htmlFor='card_desription'>Card Description</Label>
                <AvField
                  name='card_desription'
                  placeholder='Enter Card Description'
                  value={formData.card_desription}
                  onChange={handelInput}
                  type='textarea'
                  disabled={!isEditable}
                  errorMessage='Card Description is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='card_desription'
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='series_desription'>Series Description</Label>
                <AvField
                  name='series_desription'
                  placeholder='Enter Series Description'
                  value={formData.series_desription}
                  onChange={handelInput}
                  type='textarea'
                  disabled={!isEditable}
                  errorMessage='Series Description is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='series_desription'
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='singles_desription'>Singles Description</Label>
                <AvField
                  name='singles_desription'
                  placeholder='Enter Singles Description'
                  value={formData.singles_desription}
                  onChange={handelInput}
                  type='textarea'
                  disabled={!isEditable}
                  errorMessage='Singles Description is required'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  id='singles_desription'
                />
              </FormGroup>
              <Row>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor='name'>Day 1</Label>
                    <AvField
                      name='dayOne'
                      placeholder='Enter Day 1'
                      value={formData.dayOne}
                      onChange={handelInput}
                      type='text'
                      disabled={!isEditable}
                      errorMessage='Day 1 is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='dayOne'
                    />
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor='name'>Day 2</Label>
                    <AvField
                      name='dayTwo'
                      placeholder='Enter Day 2'
                      value={formData.dayTwo}
                      onChange={handelInput}
                      type='text'
                      disabled={!isEditable}
                      errorMessage='Day 2 is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='dayTwo'
                    />
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup>
                    <Label htmlFor='name'>Day 3</Label>
                    <AvField
                      name='dayThree'
                      placeholder='Enter Day 3'
                      value={formData.dayThree}
                      onChange={handelInput}
                      type='text'
                      disabled={!isEditable}
                      errorMessage='Day 3 is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='day3'
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor='name'>Day 4</Label>
                    <AvField
                      name='dayFour'
                      placeholder='Enter Day 4'
                      value={formData.dayFour}
                      onChange={handelInput}
                      type='text'
                      disabled={!isEditable}
                      errorMessage='Day 4 is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='dayFour'
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor='name'>Day 5</Label>
                    <AvField
                      name='dayFive'
                      placeholder='Enter Day 5'
                      value={formData.dayFive}
                      onChange={handelInput}
                      type='text'
                      disabled={!isEditable}
                      errorMessage='Day 5 is required'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='day5'
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* {!formData.redirect && (
                <Row>
                  <Col md={6}>
                    <Label htmlFor="name">Add Discover Series</Label>
                    <AvField
                      name="Series"
                      placeholder="Enter Discover Series"
                      value={currentSeries}
                      onChange={addIntoDiscoverSeries}
                      type="text"
                      disabled={!isEditable}
                      className="form-control"
                      id="name"
                    />
                    <Button color="primary" className="w-100 mb-4" type="button" onClick={handleAddToSeries} >
                      Add Series
                    </Button>
                  </Col>
                  <Col md={6}>
                    <ul>
                      {discoverSeries.map((item, i) => (
                        <div key={i} style={{ padding: "0.5rem 0" }}>{i + 1}{". "}{item}</div>
                      ))}
                    </ul>
                  </Col>
                </Row>
              )} */}

              <Row>
                <Col md={6}>
                  <FileUploader
                    file={image}
                    setFile={setImage}
                    dragText='Drop Image here'
                    heading='Select Image'
                    disabled={!isEditable}
                    accept='image/png, image/gif, image/jpeg, image/jpg'
                    errMsg='Please select image'
                  />
                </Col>
                <Col md={6}>
                  <FileUploader
                    file={backgroundImage}
                    setFile={setBackgroundImage}
                    dragText='Drop Background Image here'
                    heading='Select Background Image'
                    accept='image/png, image/gif, image/jpeg, image/jpg'
                    errMsg='Please select Background image'
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FileUploader
                    file={seriesBackgroundImage}
                    setFile={setSeriesBackgroundImage}
                    dragText='Drop Series Background Image here'
                    heading='Select Series Background Image'
                    disabled={!isEditable}
                    accept='image/png, image/gif, image/jpeg, image/jpg'
                    errMsg='Please select Series Background Image'
                  />
                </Col>
              </Row>

              <Button
                color='primary'
                className='w-100 mt-4'
                type='submit'
                disabled={isDisabledBtn || !isEditable}
              >
                {createBtnText}
              </Button>
            </AvForm>
            <br />
            <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role='alert'>
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DiscoverForm;
