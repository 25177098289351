import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Table from '../Table';
import { Card, CardBody, Col, Row, Button } from "reactstrap";

const AllUserByNutriIdComp = ({
  tableData = [],
  pageHeading = "All Nutri Class Users",
  tableHeading = [],
  handleTablePageChange,
  isLoading,
  page,
  sizePerPage,
  totalSize,
  nutriId
}) => {

  console.log(nutriId, 'nutriId')
  const [rowData, setRowData] = useState([]);
  const history = useHistory()
  const handleUserEditAppointment = (userId) => {
    console.log(userId, 'click edit');
    history.push("/dashboard/n-live/nutri-clinic/edit/" + userId + '?nutriId=' + nutriId);
  };
  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row.userId}>

        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleUserEditAppointment(row.userId, row.nutriId)}
        />
      </div>
    );
  };

  useEffect(() => {

    tableHeading.push({
      text: "Action",
      dataField: "view",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewContentFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      var result = tableData?.map(function (el, i) {
        var o = {
          ...el,
          name: el?.userName,
          date: el?.appDate?.slice(0, 10),
          endDate: el?.endDate?.slice(0, 10),
          time: el?.appTime?.slice(11, 16),
        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AllUserByNutriIdComp