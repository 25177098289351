import { useState, useEffect } from "react";
import CreateNutriDietForm from "../../../../../../Components/CreateNutriDietForm";
import UploadingNutriDietLoader from "../../../../../../Components/UploadingNutriDietLoader";
import { creatingStates, fileTypes } from "../../Constants/constant";
import { uploadFile } from "../../nutridiets.Constants";
import { createNutriDietApi, getFaqlistApi } from "../../nutridiets.Api";
import { EditorState, convertToRaw } from "draft-js";

const CreateNewNutriDite = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [content, setContent] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    supportAdvisorsId: "",
    faqId: [],
    imgAsset:""
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  useEffect(() => {
    const faqList = async () => {
      const { data } = await getFaqlistApi();
      setFaqData(data.faqs);
    };
    faqList();
  }, []);

  const options = faqData.map((item) => ({
    value: item._id,
    label: item.title,
    key: item._id,
  }));

  // Handle form IP
  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // reset page
  const reset = () => {
    setThumbnail(null);
    setError({
      isError: false,
      errorMessage: "",
      errorType: "warning",
    });
    setFormData({
      title: "",
      description: "",
      supportAdvisorsId: "",
      faqId: [],
      imgAsset:""
    });

    setThumbnailUploadPercentage(0);
    setCreating({
      isCreating: false,
      creatingStage: creatingStates.UPLOADING_IMAGE,
    });
  };

  const handleFaqSelect = (selectedOptions) => {
    console.log(selectedOptions,"pta nhi")
    if (selectedOptions) {
      const selectedFaqIds = selectedOptions.map((option) => option.value);
      setFormData((prev) => ({ ...prev, faqId: selectedFaqIds }));
    }
  };

  // const handleEditorChange = (evt) => {
  //   const desc = evt.editor.getData(); // Update the editorContent field
  //   // Update the formData.description

  //   setFormData((prev) => {
  //     return { ...prev, description: desc };
  //   });
  // };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };
  // submit
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (!thumbnail)
      return setError({
        isError: true,
        errorMessage: "Please select thumbnail",
        errorType: "warning",
      });

    try {
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      });

      const thumbnailUrl = await uploadFile(
        thumbnail,
        fileTypes.NUTRI_DIET_IMAGE,
        setThumbnailUploadPercentage,
        10
      );
      const updatedFormData = {
        title: formData.title, // You should replace this with the actual title
        description: content, // You should replace this with the actual description
        supportAdvisorsId: "", // You should replace this with the actual supportAdvisorsId
        faqId: formData.faqId, // faqId updated here
        imgAsset: thumbnailUrl, // imgAsset updated here
      };

      await createNutriDietApi(updatedFormData);

      // Console log the values
      console.log("Submitted Data:", updatedFormData);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {isCreating ? (
        <UploadingNutriDietLoader
          currentStep={creatingStage}
          thumbnailuploadPercentage={thumbnailuploadPercentage}
          reset={reset}
        />
      ) : (
        <CreateNutriDietForm
          handelInput={handelInput}
          title={formData.title}
          pageHeading={"Create NutriDiet"}
          options={options}
          description={formData.description}
          setEditorContent={setEditorContent}
          // handleEditorChange={handleEditorChange}
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          editorContent={editorContent}
          setThumbnail={setThumbnail}
          thumbnail={thumbnail}
          handleFaqSelect={handleFaqSelect} // Pass the handleFaqSelect function
          formData={formData} // Pass the formData
          handleSubmit={handleSubmit}
          isError={isError}
          setContent={setContent}
          content={content}
          errorMessage={errorMessage}
          errorType={errorType}
        />
      )}
    </div>
  );
};

export default CreateNewNutriDite;
