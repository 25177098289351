export const PublishedMusicTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Media Name",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const CreatedMusicTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Media Name",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const userRecommendationData = [
  {
    objectType: "UserRecommendationGender",
    label: "MALE",
    _id: "1",
  },
  {
    objectType: "UserRecommendationGender",
    label: "FEMALE",
    _id: "2",
  },
  {
    objectType: "UserRecommendationGender",
    label: "OTHER",
    _id: "3",
  },
  {
    objectType: "UserRecommendationGender",
    label: "ALL",
    _id: "4",
  },
  {
    objectType: "UserRecommendationAge",
    label: "BELOW_14",
    _id: "5",
  },
  {
    objectType: "UserRecommendationAge",
    label: "FROM_14_TO_16",
    _id: "6",
  },
  {
    objectType: "UserRecommendationAge",
    label: "FROM_17_TO_30",
    _id: "7",
  },
  {
    objectType: "UserRecommendationAge",
    label: "FROM_31_TO_50",
    _id: "8",
  },
  {
    objectType: "UserRecommendationAge",
    label: "ABOVE_50",
    _id: "9",
  },
  {
    objectType: "UserRecommendationAge",
    label: "ALL",
    _id: "10",
  },
  {
    objectType: "UserRecommendationMaritalStatus",
    label: "MARRIED",
    _id: "11",
  },
  {
    objectType: "UserRecommendationMaritalStatus",
    label: "UNMARRIED",
    _id: "12",
  },
  {
    objectType: "UserRecommendationMaritalStatus",
    label: "SEPARATED",
    _id: "13",
  },
  {
    objectType: "UserRecommendationMaritalStatus",
    label: "ALL",
    _id: "14",
  },
  {
    objectType: "UserRecommendationMood",
    label: "STRESSED",
    _id: "15",
  },
  {
    objectType: "UserRecommendationMood",
    label: "CANT_SLEEP",
    _id: "16",
  },
  {
    objectType: "UserRecommendationMood",
    label: "FEELING_LOW",
    _id: "17",
  },
  {
    objectType: "UserRecommendationMood",
    label: "ALL",
    _id: "18",
  },
  {
    objectType: "UserRecommendationBPM",
    label: "FROM_100_TO_120",
    _id: "19",
  },
  {
    objectType: "UserRecommendationBPM",
    label: "ABOVE_120",
    _id: "20",
  },
  {
    objectType: "UserRecommendationBPM",
    label: "BELOW_100",
    _id: "21",
  },
  {
    objectType: "UserRecommendationBPM",
    label: "ALL",
    _id: "22",
  },
  {
    objectType: "UserRecommendationStress",
    label: "FROM_4_TO_6",
    _id: "23",
  },
  {
    objectType: "UserRecommendationStress",
    label: "FROM_7_TO_10",
    _id: "24",
  },
  {
    objectType: "UserRecommendationStress",
    label: "ALL",
    _id: "25",
  },
];

export const fileTypes = {
  TRACK_IMAGE: "TRACK_IMAGE",
  IMG_115X150: "IMG_115X150",
  IMG_145X345: "IMG_145X345",
  IMG_180X160: "IMG_180X160",
  IMG_155X135: "IMG_155X135",
  TRACK_AUDIO: "TRACK_AUDIO",
};

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
};
