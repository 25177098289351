import React from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

import { CouponRoutes} from "./Constants/routes";

const Coupon = () => {
  const match = useRouteMatch();
  return (
    <>
       <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {CouponRoutes.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/coupon" />
      </Switch> 
     
    </>
  );
};

export default Coupon;
