export const editTagData = {
  TAG: {
    heading: "Edit Tag",
    labelName: "Tag Name",
    nameError: "Enter Tag Name",
    labelDescription: "Tag Description",
    descriptionError: "Enter Tag Description",
    createBtn: "Edit Tag",
  },
  CATEGORY: {
    heading: "Edit Category",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Category Description",
    descriptionError: "Enter Category Description",
    createBtn: "Edit Category",
  },
  SUBCATEGORY: {
    heading: "Edit SubCategory",
    labelName: "SubCategory Name",
    nameError: "Enter SubCategory Name",
    labelDescription: "SubCategory Description",
    descriptionError: "Enter SubCategory Description",
    createBtn: "Edit SubCategory",
    mediaOne: "Media One",
    mediaTwo: "media Two",
  },
  MAPPING: {
    heading: "Edit Mapping",
    labelName: "Category Name",
    nameError: "Enter Category Name",
    labelDescription: "Sub Category Name",
    descriptionError: "Enter Sub Category Name",
    createBtn: "Edit Mapping",
    mediaOne: "Media One",
    mediaTwo: "media Two",
  },
};
