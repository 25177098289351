export const PubMasterClassTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  
 
]

export const UnPubMasterClassTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  
 
]

export const UserMasterClassTableHeading = [
  {
    text: 'FirstName',
    dataField: 'name', 
  },
  {
    text: 'LastName',
    dataField: 'lastName', 
  },
  {
    text: 'Email',
    dataField: 'email', 
  },
  {
    text: 'Contact',
    dataField: 'contact', 
  },
  {
    text: 'StartDate',
    dataField: 'targetDate', 
  },
  // {
  //   text: 'Time',
  //   dataField: 'targetDate', 
  // },
  // {
  //   text:'StartDate',
  //   data:'date',
  // },  
  {
    text: 'Time',
    dataField: 'time', 
  },

  // {
  //   text:'Tagline',
  //   dataField:'tagline'

  // },
  // {
  //   text: 'Plan',
  //   dataField: 'plan', 
  // },
  {
    text: 'Points',
    dataField:'points'

  }
]