import { useState, useEffect } from "react";
import { getAllImages } from "../../api";
import { Col, Row, Card, CardBody } from "reactstrap";
import ImageCard from "../../../../../../../../Components/ImageCard";

const AllImages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageDetails, setImageDetails] = useState([]);
  const handelAllImages = async () => {
    try {
      setIsLoading(true);

      const { data } = await getAllImages();

      setImageDetails(data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handelAllImages();
  }, []);

  if (imageDetails.length === 0) {
    return <h1>no data</h1>;
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">All images</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                {imageDetails?.map((item, index) => {
                  return <ImageCard item={item} key={index} />;
                })}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllImages;
