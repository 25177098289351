import OSS from "ali-oss";
import Resizer from "react-image-file-resizer";
import { regenerateSecureStsTokenApi } from "../../../../Constants/apiRoutes";
import { getSecureStsCred } from "./nutridiets.Api";

const getOssClient = ({
  securityToken,
  accessKeyId,
  accessKeySecret,
  ossBucketName,
  ossBucketRegion,
}) => {
  const client = new OSS({
    region: ossBucketRegion,
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket: ossBucketName,
    // Refresh the temporary access credential.
    refreshSTSToken: async () => {
      const { data } = await regenerateSecureStsTokenApi();
      return {
        accessKeyId: data.data.accessKeyId,
        accessKeySecret: data.data.accessKeySecret,
        stsToken: data.data.securityToken,
      };
    },
  });

  return client;
};

export const uploadFile = (file, type, onProgress, percentageW) =>
  new Promise(async (resolve, reject) => {
    try {
      let uploadFile = file;
      if (file.type.includes("image")) {
        if (file.size < 50000) {
        } else uploadFile = await resizeFile(file);
      }
      const { data } = await getSecureStsCred({
        name: uploadFile.name,
        type,
      });

      const client = getOssClient(data.data);
      // const { url } = await client.put(data.data.fileLocation, uploadFile); // example for simple upload

      const { name, bucket } = await client.multipartUpload(
        data.data.fileLocation,
        uploadFile,
        {
          progress: (p) => {
            onProgress(p * percentageW);
          },
          partSize: 1024 * 1024 * 1, // this will create a 1MB chunk of file
          parallel: 3,
        }
      );
      resolve(
        `https://${bucket}.${data.data.ossBucketRegion}.aliyuncs.com/${name}`
      );
    } catch (error) {
      reject({
        error: true,
        errorMessage: "Unable to upload data try again later",
      });
    }
  });

// export const getVideoDuration = (file) =>
// new Promise((resolve, reject) => {
//   const reader = new FileReader();
//   reader.onload = (e) => {
//     const audioContext = new (window.AudioContext ||
//       window.webkitAudioContext)();
//     audioContext.decodeAudioData(e.target.result, (buffer) => {
//       const duration = Math.floor(buffer.duration);
//       resolve(duration);
//     });
//   };
//   reader.onerror = (event) => {
//     reject("Error while getting audio");
//   };
//   reader.readAsArrayBuffer(file);
// });

const resizeFile = (file) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
