
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import FileUploader from "../FileUploader";

const EditProgramForm = ({
  time,
  time1,
  time2,
  title,
  description,
  title1,
  description1,
  title2,
  description2,
  coverImage,
  setCoverImage,
  coverImage1,
  setCoverImage1,
  coverImage2,
  setCoverImage2,
  handleUpdateProgram,
  handleChange,
  handleChange1,
  handleChange2,
  mediaSelecta,
  mediaSelecta1,
  mediaSelecta2,
  mediaSelectb,
  mediaSelectb1,
  mediaSelectb2,
  mediaSelectc,
  mediaSelectc1,
  mediaSelectc2,
  handleMediaSelectChangeA,
  handleMediaSelectChangeA1,
  handleMediaSelectChangeA2,
  handleMediaSelectChangeB,
  handleMediaSelectChangeB1,
  handleMediaSelectChangeB2,
  handleMediaSelectChangeC,
  handleMediaSelectChangeC1,
  handleMediaSelectChangeC2,

  mediaData,
}) => {

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{time}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" >
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handleChange}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={coverImage}
                    setFile={setCoverImage}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handleChange}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: false } }}
                  id="description"
                />
              </FormGroup>
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor='mediaIds'>Media 1</Label>
                    <Select
                      value={mediaSelecta}
                      isMulti={false}
                      onChange={handleMediaSelectChangeA}
                      options={mediaData}
                      classNamePrefix='selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor=''>Media 2</Label>
                    <Select
                      value={mediaSelecta1}
                      // isMulti={true}
                      onChange={handleMediaSelectChangeA1}
                      options={mediaData}
                      classNamePrefix='select2-selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor=''>Media 3</Label>
                    <Select
                      value={mediaSelecta2}
                      // isMulti={true}
                      onChange={handleMediaSelectChangeA2}
                      options={mediaData}
                      classNamePrefix='select2-selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>

              </Row>
              {/*             
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                // disabled={!isDataUpdated ? true : false}
              >
                {'createBtnText'}
              </Button> */}
            </AvForm>
            <br />
            {/* <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role="alert">
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row> */}
          </CardBody>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{time1}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" >
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title1}
                      onChange={handleChange1}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileUploader
                    file={coverImage1}
                    setFile={setCoverImage1}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description1}
                  onChange={handleChange1}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: false } }}
                  id="description"
                />
              </FormGroup>
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor='mediaIds'>Media 1</Label>
                    <Select
                      value={mediaSelectb}
                      isMulti={false}
                      onChange={handleMediaSelectChangeB}
                      options={mediaData}
                      classNamePrefix='selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor=''>Media 2</Label>
                    <Select
                      value={mediaSelectb1}
                      onChange={handleMediaSelectChangeB1}
                      options={mediaData}
                      classNamePrefix='select2-selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor=''>Media 3</Label>
                    <Select
                      value={mediaSelectb2}
                      // isMulti={true}
                      onChange={handleMediaSelectChangeB2}
                      options={mediaData}
                      classNamePrefix='select2-selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>

              </Row>
              
            </AvForm>
            <br />
            
          </CardBody>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{time2}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" >
              <Row>
                <Col>
                <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title2}
                      onChange={handleChange2}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: false } }}
                      id="title"
                    />
                  </FormGroup>
                  <FileUploader
                    setFile={setCoverImage2}
                    file={coverImage2}
                    dragText="Drop Image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  />
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description2}
                  onChange={handleChange2}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: false } }}
                  id="description"
                />
              </FormGroup>
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor='mediaIds'>Media 1</Label>
                    <Select
                      value={mediaSelectc}
                      isMulti={false}
                      onChange={handleMediaSelectChangeC}
                      options={mediaData}
                      classNamePrefix='selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor=''>Media 2</Label>
                    <Select
                      value={mediaSelectc1}
                      onChange={handleMediaSelectChangeC1}
                      options={mediaData}
                      classNamePrefix='select2-selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor=''>Media 3</Label>
                    <Select
                      value={mediaSelectc2}
                      // isMulti={true}
                      onChange={handleMediaSelectChangeC2}
                      options={mediaData}
                      classNamePrefix='select2-selection'
                      placeholder={
                        mediaData && mediaData.length === 0
                          ? 'Loading...'
                          : 'Select Media'
                      }
                    
                    />
                  </FormGroup>
                </Col>

              </Row>

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                // disabled={!isDataUpdated ? true : false}
                onClick={handleUpdateProgram}
              >
                {'Save Changes'}
              </Button>
            </AvForm>
            <br />
            {/* <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role="alert">
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row> */}

          </CardBody>
        </Card>
      </Col>
    </Row>

  );
};

export default EditProgramForm;
