import AllDiscovers from "../Components/AllDiscovers";
import CreateDiscovers from "../Components/CreateDiscovers";


export const DiscoverRoutes = [
  {
    Component: AllDiscovers,
    path: "/all",                                                                                
    exact: true,
    id: "dashboardDiscoverAll",
    routePath: "/dashboard/discover/all",
    name: "All Discovers",
  },
  {
    Component: CreateDiscovers,
    path: "/create",
    exact: true,
    id: "dashboardDiscoverCreate",
    routePath: "/dashboard/discover/create",
    name: "Add Discovers",
  }
];
