import AllFaqTag from "../Components/AllFaqTags";
import FaqAllTag from "../Components/AllFaqTags";
import AllTags from "../Components/AllFaqTags";
import CreateFaq from "../Components/CreateFaq";
import FaqBin from "../Components/TagsBin";
import AllFaqs from "../Components/AllFaq's";
import EditFaq from "../Components/EditFaq";

export const FaqRoutes = [
  {
    Component: CreateFaq,
    path: "/create",
    exact: true,
    id: "dashboardFaqCreate",
    routePath: "/dashboard/faq/create",
    name: "Create FAQs",
  },
  {
    Component: AllFaqs,
    path: "/allFaq",
    exact: true,
    id: "dashboardFaqAll",
    routePath: "/dashboard/faq/allFaq",
    name: "All FAQs"
  },

  {
    Component: EditFaq,
    path: "/editfaq/:userId",
    exact: true,
    id: "dashboardFaqAll",
    
  },

  {
    Component: FaqAllTag,
    path: "/all",
    exact: true,
    id: "dashboardTagsAll",
    routePath: "/dashboard/faq/all",
    name: "All Topics",
  },

  {
    Component: FaqBin,
    path: "/bin",
    exact: true,
    id: "dashboardFaqBin",
    routePath: "/dashboard/faq/bin",
    name: "Topics Bin",
  }
];