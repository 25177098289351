import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import FileUploader from "../FileUploader";
import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { useEffect } from "react";

const CreateNutriDietForm = ({
  musicID,
  pageHeading,
  handelInput,
  title,
  options,
  faqData,
  setThumbnail,
  thumbnail,
  faqId,
  handleFaqSelect,
  handleSubmit,
  isError,
  errorMessage,
  errorType,
  showSelectAudio = true,
  isDisabledBtn = false,
  createBtnText = "Create Nutri Diet",
  setUseSameThumbnail,
  useSameThumbnail,
  prevThumbnail,
  content,
  setContent,
}) => {
  const animatedComponents = makeAnimated();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    // This effect will run when the component mounts or when editorState changes
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState, {
      trigger: "#",
      separator: " ",
    });
    setContent(markup);
  }, [editorState]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row style={{ alignItems: "center" }}>
              <Col xs={10}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
              {pageHeading === "Create Nutri Diet" && (
                <Col>
                  <Link to={`/dashboard/media/alternate/${musicID}`}>
                    <Button color="primary">Create Alternate</Button>
                  </Link>
                </Col>
              )}
            </Row>

            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={8}>
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="select">Select FAQ Groups</Label>
                <Select
                  value={faqData}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  onChange={(selectedOptions) =>
                    handleFaqSelect(selectedOptions)
                  }
                  isMulti
                  options={options}
                />
              </FormGroup>
              <Row>
                <Col lg={showSelectAudio ? 6 : 12}>
                  {pageHeading === "Alternate Nutri Diet" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="useSameThumbnail"
                        checked={useSameThumbnail}
                        onChange={(e) => setUseSameThumbnail(e.target.checked)}
                        id="useSameThumbnail"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="useSameThumbnail"
                      >
                        Use Same Thumbnail
                      </Label>
                    </div>
                  )}

                  <FileUploader
                    file={thumbnail}
                    setFile={setThumbnail}
                    dragText="Drop media image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                  
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Label htmlFor="description">Description</Label>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClass"
                    wrapperClassName="wrapperClass"
                    editorClassName="editorClass"
                    onEditorStateChange={setEditorState}
                  />
                </Col>
              </Row>

              {isError && (
                <Alert color={errorType} role="alert">
                  {errorMessage}
                </Alert>
              )}
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateNutriDietForm;
