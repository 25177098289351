import React, { useState, useEffect } from "react";
import AllUserByMasterId from "../../../../../../../Components/AllUserByMasterId";
import { getAllEnableMasterClass, csvDataMasterClass } from "../../../Constants/api";
import { PubMasterClassTableHeading } from "../../../Constants/constant";

const AllUserMasterId = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [publishedCourse, setPublishedCourse] = useState([]);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = publishPage;
  const [masterId, setMasterId] = useState([]);
  const [csvData,setCsvData] = useState([])

  const getAllPublishedCourseData = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await getAllEnableMasterClass(params);
      setPublishedCourse(data?.data);
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      if (data?.data?.length > 0) {
        setMasterId(data.data[0]._id);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // const extractAndSetMasterIds = (courses) => {
  //   if (courses?.length > 0) {
  //     const ids = courses.map((course) => course._id);
  //     setMasterId(ids);
  //   }
  // };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllPublishedCourseData({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  const handleEditSeries = (id) => {
    history.push("/dashboard/master-user/" + id);
  };

  const getAllUsersDataCsv = async (params) => {
    try {
      const { data } = await csvDataMasterClass();
      setCsvData(data?.allRegisteredMasterClass)
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  };
  console.log(csvData,'printData')

  const handleAllExportToCSV = () => {
    const csvDataAll = csvData?.map((item) => ({
      Id: item?._id,
      UserId: item?.userId?._id,
      UserFirstName: item?.userId?.firstName,
      UserLastName: item?.userId?.lastName,
      UserPhoneNumber: item?.userId?.phoneNumber,
      UserEmail: item?.userId?.email,
      UserAge: item?.userId?.age,
      UserCountry: item?.userId?.country,
      UserDOB: item?.userId?.dob ? new Date(item.userId.dob).toISOString().substring(0, 10) : '',
      UserGender: item?.userId?.gender,
      UserHeight: item?.userId?.height,
      UserMaritalStatus: item?.userId?.maritalStatus,
      UserProfileUrl: item?.userId?.profileUrl,
      UserWeight: item?.userId?.weight,
      UserFirebaseToken: item?.userId?.firebaseToken,
      UserSubscriptionRenewed: item?.userId?.isSubscriptionRenewed,
      UserLastActiveDate: item?.userId?.lastActiveDate ? new Date(item.userId.lastActiveDate).toISOString().substring(0, 10) : '',
      UserSubscriptionsId: item?.userId?.subscriptionsId,
      UserLangPref: item?.userId?.langPref,
      UserCountryCode: item?.userId?.countryCode,
      UserReferralCode: item?.userId?.referralCode,
      MasterclassId: item?.masterclassId?._id,
      MasterclassTitle: item?.masterclassId?.title,
      // MasterclassDescription: item?.masterclassId?.description,
      MasterclassImage: item?.masterclassId?.image,
      MasterclassTargetDate: item?.masterclassId?.targetDate ? new Date(item.masterclassId.targetDate).toISOString().substring(0, 10) : '',
      MasterclassJoinLink: item?.masterclassId?.joinLink,
      MasterclassTagline: item?.masterclassId?.tagline,
      MasterclassSupportAdvisorId: item?.masterclassId?.supportAdvisorId,
      MasterclassIsPublished: item?.masterclassId?.isPublished,
      MasterclassIsFree: item?.masterclassId?.isFree,
      MasterclassCreatedAt: item?.masterclassId?.createdAt ? new Date(item.masterclassId.createdAt).toISOString().substring(0, 10) : '',
      MasterclassUpdatedAt: item?.masterclassId?.updatedAt ? new Date(item.masterclassId.updatedAt).toISOString().substring(0, 10) : '',
      IsBooked: item?.isBooked,
      IsFree: item?.isFree,
      CreatedAt: item?.createdAt ? new Date(item.createdAt).toISOString().substring(0, 10) : '',
      UpdatedAt: item?.updatedAt ? new Date(item.updatedAt).toISOString().substring(0, 10) : ''
    }));
  
    return csvDataAll;
  };
  
  

useEffect(() => {
  getAllPublishedCourseData({ page: 1, limit: 10 });
  getAllUsersDataCsv()
}, []);

  // console.log(masterId, "dedo naa");

  return (
    <>
      <AllUserByMasterId
        tableData={publishedCourse}
        tableHeading={PubMasterClassTableHeading}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        // handleEditSeries={handleEditSeries}
        masterId={masterId}
        handleAllExportToCSV={handleAllExportToCSV}
      />
    </>
  );
};

export default AllUserMasterId;
