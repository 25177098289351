import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  UpdatePublishDiscoverNispandAPI,
  UpdateUnPublishDiscoverNispandAPI,
  getPublishedDiscoverNispandAPI,
  getUnPublishedDiscoverNispandAPI,
} from "../../Constants/api";
import DiscoverPublished from "../../../../../../Components/DiscoverPublished";
import DiscoverUnPublished from "../../../../../../Components/DiscoverUnPublished";
import {
  PublishedDiscoverTableHeading,
  UnPublishedDiscoverTableHeading,
} from "../../Constants/constant";
import SweetAlert from "react-bootstrap-sweetalert";

const AllDiscoverNispand = () => {
  const [PublishedDiscoverNispand, setPublishedDiscoverNispand] = useState([]);
  const [UnPublishedDiscoverNispand, setUnPublishedDiscoverNispand] = useState(
    []
  );
  const [publishID, setPublishID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = paginationValue;

  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 10,
  });
  const { unpublishedCurrentPage, unpublishedTotalSize } =
    unpublishedPaginationValue;

  const history = useHistory();

  const getPublishedDiscoverNispandData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getPublishedDiscoverNispandAPI(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });

      setPublishedDiscoverNispand(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getUnPublishedDiscoverNispandData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getUnPublishedDiscoverNispandAPI(queryParams);
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setUnPublishedDiscoverNispand(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getPublishedDiscoverNispandData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getUnPublishedDiscoverNispandData({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };

  const handleEditDiscoverNispand = (id) => {
    history.push({
      pathname: "/dashboard/discover-nispand/edit/" + id._id,
      state: { from: id, userRefPath: id.userRefPath },
    });
  };

  const handlePublishDiscoverNispandClick = async (id) => {
    setPublishID(id);
    setShowUnPublishAlert(true);
  };

  const handleUnPublishDiscoverNispandClick = async (id) => {
    setPublishID(id);
    setShowPublishAlert(true);
  };

  // const handleViewDiscoverNispand = (refId) => {
  //   history.push("/dashboard/DiscoverNispand/view/" + refId);
  // };

  const publishDiscoverNispandAction = async () => {
    await UpdateUnPublishDiscoverNispandAPI(publishID);
    setShowUnPublishAlert(false);
    setShowUnPublishConfirm(true);
    const filteredDiscoverNispand = PublishedDiscoverNispand.filter(
      ({ _id }) => _id !== publishID
    );
    setPublishedDiscoverNispand(filteredDiscoverNispand);
    setPublishID("");
  };

  const UnpublishDiscoverNispandAction = async () => {
    await UpdatePublishDiscoverNispandAPI(publishID);
    setShowPublishAlert(false);
    setShowPublishConfirm(true);
    const filteredDiscoverNispand = UnPublishedDiscoverNispand.filter(
      ({ _id }) => _id !== publishID
    );
    setUnPublishedDiscoverNispand(filteredDiscoverNispand);
    setPublishID("");
  };

  useEffect(() => {
    getPublishedDiscoverNispandData({ page: 1, limit: 10 });
    getUnPublishedDiscoverNispandData({ page: 1, limit: 10 });
  }, []);
  return (
    <>
      <DiscoverPublished
        tableData={PublishedDiscoverNispand}
        handleEditDiscoverNispand={handleEditDiscoverNispand}
        tableHeading={PublishedDiscoverTableHeading}
        setPublishedDiscoverNispand={setPublishedDiscoverNispand}
        handlePublishDiscoverNispand={handlePublishDiscoverNispandClick}
        handleTablePageChange={handleTablePageChange}
        // handleViewDiscoverNispand={handleViewDiscoverNispand}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />

      <DiscoverUnPublished
        tableData={UnPublishedDiscoverNispand}
        tableHeading={UnPublishedDiscoverTableHeading}
        setUnPublishedDiscoverNispand={setUnPublishedDiscoverNispand}
        handleEditDiscoverNispand={handleEditDiscoverNispand}
        // handleViewDiscoverNispand={handleViewDiscoverNispand}
        handleUnPublishDiscoverNispand={handleUnPublishDiscoverNispandClick}
        handleTablePageChange={handleUnpublishedTablePageChange}
        isLoading={isLoading}
        page={unpublishedCurrentPage}
        sizePerPage={10}
        unpublishedTotalSize={unpublishedTotalSize}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={publishDiscoverNispandAction}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          DiscoverNispand has been unpublished
        </SweetAlert>
      ) : null}

      {showPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, publish it!"
          reverseButtons={true}
          onConfirm={UnpublishDiscoverNispandAction}
          onCancel={() => setShowPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishConfirm ? (
        <SweetAlert
          success
          title="published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            window.location.reload();
          }}
        >
          Discover Nispand has been published
        </SweetAlert>
      ) : null}
    </>
  );
};

export default AllDiscoverNispand;
