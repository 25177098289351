export const PlanTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Plan Name",
    dataField: "plan_identifier",
  },
  {
    text: "Plan Duration",
    dataField: "plan_duration_indays",
  },
  {
    text: "Plan Display Price",
    dataField: "plan_display_price",
  },
  {
    text: "Country Code",
    dataField: "country_code",
  },
];


export const PlanBinTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Plan Name",
    dataField: "plan_identifier",
  },
  {
    text: "Plan Duration",
    dataField: "plan_duration_indays",
  },
  {
    text: "Plan Display Price",
    dataField: "plan_display_price",
  },
];
