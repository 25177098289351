import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import FileUploader from "../FileUploader";

const MindForm = ({
  pageHeading,
  handleSubmit,
  handleUpdate,
  createBtnText,
  categoryData,
  // preData,
  // categoriesData,
  // otherCat1,
  // otherCat2,
  // otherCat3,
  // otherCat4,
  // otherCat5,
  // otherCat6,
  // otherCat7,
  // otherCat8,
  // otherCat9,
  mediaData,
  mediaData3,
  setMediaData3,
  setMediaData4,
  setMediaData2,
  mediaData4,
  setMediaData,
  // setCategoriesData,
  // selectedOption2,
  // setCategoryValue,
  handleSelectChange,
  itemData,
  categoryData2,
  mediaData2,
  // categoryValue,
  // otherCategory,
  // optionsData,
  allCategoriesData,
  handleSelectChange2,
  // handleSelectOtherCat1,
  // handleSelectOtherCat2,
  // handleSelectOtherCat3,
  // handleSelectOtherCat4,
  // handleSelectOtherCat5,
  // handleSelectOtherCat6,
  // handleSelectOtherCat7,
  // handleSelectOtherCat8,
  // handleSelectOtherCat9,
  allMediaOptions,
  selectedTags,
  handleTagChange,
  tags,
  otCat,
  handleOtChange,
}) => {
  const [image, setImage] = useState();

  // console.log(allMediaOptions,"eee")

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row style={{ alignItems: "center" }}>
                <Col xs={10}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>

              <AvForm className="form-horizontal">
                <Row>
                  <Col ls={10}>
                    <FormGroup>
                      <div dir="ltr">
                        <FormGroup className="select2-container">
                          <Label className="control-label">Category 1</Label>
                          <Select
                            value={categoryData}
                            onChange={handleSelectChange}
                            options={allCategoriesData.map((category) => ({
                              value: category._id,
                              label: category.name,
                            }))}
                            classNamePrefix="select2-selection"
                            placeholder={"select a category"}
                          />
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col ls={4}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor="cat/subcat Select">Item 1</Label>
                        <Select
                          value={mediaData}
                          onChange={(selectedOption) =>
                            setMediaData([selectedOption])
                          }
                          options={allMediaOptions.map((elem) => ({
                            value: elem._id,
                            label: elem.title,
                          }))}
                          classNamePrefix="select2-selection"
                          placeholder="Select an item"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor="cat/subcat Select">Item 2</Label>
                        <Select
                          value={mediaData3}
                          isMulti={false}
                          onChange={(selectedOption) =>
                            setMediaData3([selectedOption])
                          }
                          options={allMediaOptions.map((elem) => ({
                            value: elem._id,
                            label: elem.title,
                          }))}
                          classNamePrefix="select2-selection"
                          placeholder="Select an item"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col ls={10}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor="cat/subcat Select">Category 2</Label>
                        <Select
                          value={categoryData2}
                          onChange={handleSelectChange2}
                          options={allCategoriesData.map((category) => ({
                            value: category._id,
                            label: category.name,
                          }))}
                          classNamePrefix="select2-selection"
                          placeholder={"select a category"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Item 1</Label>
                      <Select
                        value={mediaData2}
                        isMulti={false}
                        onChange={(selectedOption) =>
                          setMediaData2(selectedOption)
                        }
                        options={allMediaOptions.map((elem) => ({
                          value: elem._id,
                          label: elem.title,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Item 2</Label>
                      <Select
                        value={mediaData4}
                        isMulti={false}
                        onChange={(selectedOption) =>
                          setMediaData4(selectedOption)
                        }
                        options={allMediaOptions.map((elem) => ({
                          value: elem._id,
                          label: elem.title,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"select a category"}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col ls={2}>
                    <Label htmlFor="otherCategories">Other Categories</Label>
                  </Col>
                </Row>
                <Row>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 1 </Label>
                      <Select
                        value={otherCat1}
                        isMulti={false}
                        onChange={handleSelectOtherCat1}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 2 </Label>
                      <Select
                        value={otherCat2}
                        isMulti={false}
                        onChange={handleSelectOtherCat2}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 3</Label>
                      <Select
                        value={otherCat3}
                        isMulti={false}
                        onChange={handleSelectOtherCat3}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 4</Label>
                      <Select
                        value={otherCat4}
                        isMulti={false}
                        onChange={handleSelectOtherCat4}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 5</Label>
                      <Select
                        value={otherCat5}
                        isMulti={false}
                        onChange={handleSelectOtherCat5}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 6</Label>
                      <Select
                        value={otherCat6}
                        isMulti={false}
                        onChange={handleSelectOtherCat6}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 7</Label>
                      <Select
                        value={otherCat7}
                        isMulti={false}
                        onChange={handleSelectOtherCat7}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 8</Label>
                      <Select
                        value={otherCat8}
                        isMulti={false}
                        onChange={handleSelectOtherCat8}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={4}>
                    <FormGroup>
                      <Label htmlFor="itemSelect">Other Category 9</Label>
                      <Select
                        value={otherCat9}
                        isMulti={false}
                        onChange={handleSelectOtherCat9}
                        options={allCategoriesData.map((category) => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        classNamePrefix="select2-selection"
                        placeholder={"Select a category"}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col ls={10}>
                    <FormGroup>
                      <div dir="ltr">
                        <FormGroup className="select2-containe">
                          <Label className="control-label">Other Categories</Label>
                          <Select
                            value={otCat}
                            onChange={handleOtChange}
                            isMulti={true}
                            options={allCategoriesData.map((category) => ({
                              value: category._id,
                              label: category.name,
                              assetImage: category.imgAsset,
                            }))}
                            // options={selectedTags}
                            classNamePrefix="select2-selectio"
                            placeholder={"select a tag"}
                          />
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={10}>
                    <FormGroup>
                      <div dir="ltr">
                        <FormGroup className="select2-containe">
                          <Label className="control-label">Tags</Label>
                          <Select
                            value={selectedTags}
                            onChange={handleTagChange}
                            isMulti={true}
                            options={tags.map((tag) => ({
                              value: tag._id,
                              label: tag.name,
                            }))}
                            // options={selectedTags}
                            classNamePrefix="select2-selectio"
                            placeholder={"select a tag"}
                          />
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  disabled={""}
                  onClick={handleUpdate}
                >
                  {createBtnText}
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MindForm;
