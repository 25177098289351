import AllOffers from "../Components/AllOffers.js";
import CreateOffer from "../Components/CreateOffer";


export const OfferRoutes = [
  {
    Component: AllOffers,
    path: "/all",
    exact: true,
    id: "dashboardOfferAll",
    routePath: "/dashboard/offer/all",
    name: "All Offers",
  },
  {
    Component: CreateOffer,
    path: "/create",
    exact: true,
    id: "dashboardOfferCreate",
    routePath: "/dashboard/offer/create",
    name: "Create Offers",
  },
];
