import axios from "axios";

import baseDomain, {
    deactivatePlanAPI,
    planAPI,
    searchPlanAPI,
    plansBinAPI,
    createPlanAPI
} from "../../../../Constants/apiRoutes";

export const getAllPlanReq = ({ page, limit }) => {
    return axios.get(`${baseDomain}${planAPI}?page=${page}&limit=${limit}`);
};

export const searchPlanReq = (text) => {
    return axios.get(`${baseDomain}${searchPlanAPI}/${text}`);
};

export const unpublishPlanReq = (planID) => {
    return axios.delete(`${baseDomain}${deactivatePlanAPI}/${planID}`);
};

export const planBinReq = ({ page, limit }) => {
    return axios.get(`${baseDomain}${plansBinAPI}?page=${page}&limit=${limit}`);
};

export const createPlanReq = (payload) => {
    return axios.post(`${baseDomain}${createPlanAPI}`, payload);
};

