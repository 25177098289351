import React, { useEffect, useState } from "react";
import AllTimeSlotsComp from "../../../../../../Components/AllTimeSlots";
import {
  AllAdvisorTimeTableHeading,
} from "../../Constants/constant";
import { getAllAdvisors } from "../../../ServiceAdvisors/advisors.Apis";


const AllTimeSlots = () => {
  const [advisorData, setAdvisorData] = useState([]);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  });
 
  const { currentPage, totalSize } = paginationValue;

  const getAllAdvisorData = async (queryParams) => {
    try {
      const { data } = await getAllAdvisors(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setAdvisorData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getAllAdvisorData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  useEffect(() => {
    getAllAdvisorData({ page: 1, limit: 10 });
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <h2 style={{ margin: "0px" }}>All Time Slots For Advisor</h2>
      </div>

      <AllTimeSlotsComp
        tableData={advisorData}
        tableHeading={AllAdvisorTimeTableHeading}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />

    
    </>
  );
};

export default AllTimeSlots;
