export const AppointmentHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'User',
    dataField: 'name', 
  },
  {
    text: 'Date',
    dataField: 'date', 
  },  
  {
    text: 'Time',
    dataField: 'time', 
  },
  {
    text: "Class Name",
    dataField:'nutriId'
  }

]