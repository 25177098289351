import React from "react";
import { useState, useEffect } from "react";
import PublishedFaq from "../../../../../../Components/PublishedFaq";
import FaqInfo from "../FaqInfo";
import { allFaqTableHeading, publishedTableHeading } from "../../Faq.constants";
import {
  unpublishFaq,
  publishAction,
  publishFaq,
  unpublishAction,
  searchAllFaq,
} from "../../Faq.Apis";
import SweetAlert from "react-bootstrap-sweetalert";
import UnpublishedFaq from "../../../../../../Components/FaqTable";

const AllFaqs = () => {
  const [createdFaq, setCreatedFaq] = useState([]);
  const [publishedFaq, setPublishedFaq] = useState([]);
  const [showPublishedAlert, setShowPublishedAlert] = useState(false);
  const [showPublishedConfirm, setShowPublishedConfirm] = useState(false);
  const [showUnpublishAlert, setShowUnpublishAlert] = useState(false);
  const [showUnpublishConfirm, setShowUnpublishConfirm] = useState(false);
  const [publishId, setPublishId] = useState("");
  const [unpublishId, setUnpublishId] = useState("");
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [isFaqInfoOpen, setIsFaqInfoOpen] = useState(false);
  const [createdPaginationValue, setCreatedPaginationValue] = useState({
    createdCurrentPage: 1,
    limit: 10,
  });
  const [totalSize, setTotalSize] = useState(0);
  const { createdCurrentPage, createdTotalSize } = createdPaginationValue;

  const [faqInfo, setFaqInfo] = useState({
    topic: "",
    category: "",
    title: "",
  });
  const toggleFaqInfo = () => setIsFaqInfoOpen(!isFaqInfoOpen);
  const handleFaqInfo = (item) => {
    setFaqInfo({
      topic: item.tag.name,
      category: item.category.name,
      title: item.title,
    });
    toggleFaqInfo();
  };

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    limit: 10,
  });
  const { currentPage, limit } = paginationValue;

  const getUnpublishedFaq = async (queryParams) => {
    try {
      const { data } = await unpublishFaq(queryParams);
      setCreatedPaginationValue({
        createdCurrentPage: queryParams.page,
        createdTotalSize: 10,
      });
      setTotalSize(data.count);
      setCreatedFaq(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPublishedFaq = async (queryParams) => {
    try {
      const { data } = await publishFaq(queryParams);
      setCreatedPaginationValue({
        createdCurrentPage: queryParams.page,
        createdTotalSize: 10,
      });
      setTotalSize(data.count);
      setPublishedFaq(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const publishBlogAction = (id) => {
    setPublishId(id);
    setShowPublishedAlert(true);
  };
  const handlePublishAction = async () => {
    try {
      await publishAction(publishId);
      getUnpublishedFaq({ page: 1, limit: 10 });
      getPublishedFaq({ page: 1, limit: 10 });
    } catch (error) {
      console.log(error);
    }
  };
  const UnpublishBlogAction = async (id) => {
    setUnpublishId(id);
    setShowUnpublishAlert(true);
  };

  const getFaqSearchData = async (text) => {
    try {
      const { data } = await searchAllFaq(text);
      setCurrentSearch(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getFaqSearchData(searchBar);
  };
  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleUnpublishAction = async () => {
    try {
      await unpublishAction(unpublishId);
      getUnpublishedFaq({ page: 1, limit: 10 });
      getPublishedFaq({ page: 1, limit: 10 });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUnpublishedFaq({ page: 1, limit: 10 });
    getPublishedFaq({ page: 1, limit: 10 });
  }, []);

  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getUnpublishedFaq({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
      limit: limit,
    });
  };
  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getPublishedFaq({ page, limit: sizePerPage });
    setCreatedPaginationValue({
      ...createdPaginationValue,
      createdCurrentPage: page,
      limit: limit,
    });
  };

  const select_faq = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };

  const selector1 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#1cbb8c",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  const selector2 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "#fcb92c",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  const selector3 = {
    padding: "7.5px",
    boxSizing: "border-box",
    backgroundColor: "red",
    borderRadius: "50%",
    marginRight: "1rem",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>All FAQs</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search FAQs"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={() => hideComponent()}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_faq}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleFaqInfo(item)}
                      >
                        {item &&
                          item.isDisabled === false &&
                          item.isPublished === true && (
                            <div style={selector1}></div>
                          )}
                        {item &&
                          item.isDisabled === false &&
                          item.isPublished === false && (
                            <div style={selector2}></div>
                          )}
                        {item &&
                          item.isDisabled === true &&
                          item.isPublished === false && (
                            <div style={selector3}></div>
                          )}
                        <div>{item ? item.title : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <PublishedFaq
        heading="Published FAQ's"
        tData={publishedFaq}
        tableHeading={publishedTableHeading}
        sizePerPage={10}
        unpublishBlog={UnpublishBlogAction}
        buttonName="unpublish"
        handleTablePageChange={handlePublishedTablePageChange}
        totalSize={totalSize}
        page={createdCurrentPage}
      />
      {showUnpublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={() => {
            handleUnpublishAction();
            setShowUnpublishConfirm(true);
          }}
          onCancel={() => setShowUnpublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnpublishConfirm ? (
        <SweetAlert
          success
          title="Unpublished"
          onConfirm={() => {
            setShowUnpublishAlert(false);
            setShowUnpublishConfirm(false);
          }}
        ></SweetAlert>
      ) : null}

      <UnpublishedFaq
        setShowPublishedAlert={setShowPublishedAlert}
        heading="Unpublished FAQs"
        tableData={createdFaq}
        tableHeading={allFaqTableHeading}
        sizePerPage={10}
        publishBlog={publishBlogAction}
        buttonName="Publish"
        handleTablePageChange={handleUnpublishedTablePageChange}
        totalSize={totalSize}
        page={currentPage}
      />

      {showPublishedAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={() => {
            handlePublishAction();
            setShowPublishedConfirm(true);
          }}
          onCancel={() => setShowPublishedAlert(false)}
        ></SweetAlert>
      ) : null}

      {showPublishedConfirm ? (
        <SweetAlert
          success
          title="published"
          onConfirm={() => {
            setShowPublishedAlert(false);
            setShowPublishedConfirm(false);
          }}
        ></SweetAlert>
      ) : null}

      <FaqInfo
        topic={faqInfo.topic}
        category={faqInfo.category}
        title={faqInfo.title}
        isFaqInfoOpen={isFaqInfoOpen}
        toggleFaqInfoModal={toggleFaqInfo}
      />
    </>
  );
};

export default AllFaqs;
