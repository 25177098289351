import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

const EditUserComp = ({
  email,
  firstName,
  lastName,
  companyName,
  companySize,
  designation,
  Monthly,
  Annually,
  companionCount,
  country,
  currency,
  state,
  phoneNumber,
  countryCode,
  city,
  totalGross,
  isDataUpdated,
  pageHeading,
  handelInput,
  handleEditUserSubmit,
  handleUserStocks,
}) => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label htmlFor="title">Email</Label>
                      <AvField
                        onChange={handelInput}
                        name="email"
                        value={email}
                        placeholder="Email"
                        type="text"
                        className="form-control"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">First Name</Label>
                      <AvField
                        onChange={handelInput}
                        name="firstName"
                        value={firstName}
                        placeholder="First Name"
                        type="text"
                        className="form-control"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">Last Name</Label>
                      <AvField
                        onChange={handelInput}
                        name="lastName"
                        value={lastName}
                        placeholder="Last Name"
                        type="text"
                        className="form-control"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">Company Name</Label>
                      <AvField
                        name="companyName"
                        placeholder="Company Name"
                        type="text"
                        value={companyName}
                        onChange={handelInput}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="title">Phone Number</Label>
                      <Row className="gap-0">
                        <Col xs={2}>
                          <input
                            name="countryCode"
                            className=" form-control text-center"
                            value={countryCode}
                            disabled="disabled"
                          />
                        </Col>
                        <Col xs={10}>
                          <input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            value={phoneNumber}
                            onChange={handelInput}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Country</Label>
                      <Input value={country} disabled="disabled" />
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">State</Label>
                      <Input value={state} disabled="disabled" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">City</Label>
                      <Input value={city} disabled="disabled" />
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Your Role</Label>
                      <Input
                        name="role"
                        placeholder="Select Type"
                        type="text"
                        className="form-control"
                        value={designation}
                        onChange={handelInput}
                        id="languageSelect"
                        disabled="disabled"
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Annual stocks</Label>
                      <AvField
                        name="annual_Stocks"
                        placeholder="Annual stocks"
                        type="number"
                        value={Annually}
                        onChange={handelInput}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                        disabled="disabled"
                      />
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Monthly stocks</Label>
                      <AvField
                        name="monthly_Stocks"
                        placeholder="Monthly stocks"
                        type="number"
                        value={Monthly}
                        onChange={handelInput}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                        disabled="disabled"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Company Size</Label>
                      <Input
                        name="companySize"
                        placeholder="Select Type"
                        type="text"
                        className="form-control"
                        value={companySize}
                        onChange={handelInput}
                        id="companySelect"
                        disabled="disabled"
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">
                        Number of companionCount
                      </Label>
                      <AvField
                        name="companionCount"
                        placeholder="Number of companionCount"
                        type="number"
                        value={companionCount}
                        onChange={handelInput}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Currency</Label>
                      <Input
                        name="currency"
                        placeholder="Select Type"
                        type=""
                        className="form-control"
                        value={currency}
                        onChange={handelInput}
                        id="languageSelect"
                        disabled="disabled"
                      >
                        <option value="">Select the currency</option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col ls={6}>
                    <FormGroup className="select2-container">
                      <Label className="control-label">Amount Paid</Label>
                      <AvField
                        name="amountPaid"
                        placeholder="Amount paid by user"
                        type="number"
                        value={totalGross}
                        onChange={handelInput}
                        className="form-control"
                        id="plan_identifier"
                        autocomplete="off"
                        disabled="disabled"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-25 mt-4"
                  type="submit"
                  onClick={handleUserStocks}
                >
                  Update Stocks
                </Button>
                {!isDataUpdated && (
                  <div  style={{
                    color: "red",
                    fontSize: "18px",
                    textAlign: "center",
                    marginTop: "20px",
                  }}>Please Update the data</div>
                )}
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={handleEditUserSubmit}
                  disabled={!isDataUpdated ? true : false}
                >
                  Update
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditUserComp;
