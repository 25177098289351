import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Loader from "../../../../../../Components/Loader";
// import UploadStatusBar from "Components/UploadStatusBar";

import { uploadFile } from "../../others/Uploads/upload.Helper";
import { creatingStates } from "../../Constants";
// import EditBlogComp from "Components/EditBlogComp";
import EditBlogComp from "../../../../../../Components/EditBlogComp";
import { getBlogReq, editBlogReq, unpublishBlog } from "../../Api";
// import UploadBlogsStatus from "Components/UploadBlogsStatus";
import UploadBlogsStatus from "../../../../../../Components/UploadBlogsStatus";
import SweetAlert from "react-bootstrap-sweetalert";

const EditBlog = () => {
  const { blogId } = useParams();
  const history = useHistory();

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [thumbnailUploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [blogThumbnail, setblogThumbnail] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState(null);
  const [currentContent, setCurrentContent] = useState(null);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [tagsId, setTagsId] = useState([]);
  const [meta, setMeta] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [publishID, setPublishID] = useState("");
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    metaDescription: "",
    categoryId: "",
    slug: "",
    _id: "",
    altTag: "",
  });

  const publishBlogAction = async () => {
    setShowPublishAlert(true);
  };

  const publishBlogActionConfirm = async () => {
    await unpublishBlog(publishID);
    setShowPublishConfirm(true);
  };

  const metaParsingAlgo = (data) => {
    let headers = {
      title: "",
      links: [],
      metaData: [],
      script: "",
    };
    data = data.replace(/\\n/g, "");
    data = data.split("\n");
    data.forEach((el, idx) => {
      if (el.includes("<meta") || el.includes("<link")) {
        let obj = {};
        let regExp = /(\S+)="[^"]*/g;
        let regexMatches = el.match(regExp);

        regexMatches.map((el) => {
          let partition = el.split('="');
          obj[partition[0]] = partition[1].replace(/"/g, "");
        });

        if (el.includes("<meta")) headers.metaData.push(obj);
        if (el.includes("<link")) headers.links.push(obj);
      } else if (el.includes("<title"))
        headers.title = el.replace("<title>", "").replace("</title>", "");
      else if (el.includes("<script")) headers.script = el;
    });

    const key = headers?.metaData?.find(
      (item) => item?.name === "keywords"
    )?.content;
    return {
      title: headers.title.trimStart(),
      keywords: key,
    };
  };

  const handelInput = (e) => {
    setIsDisabledBtn(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { title, metaDescription, slug, _id, altTag } = formData;

  const getBlog = async () => {
    try {
      setIsLoading(true);
      const { data } = await getBlogReq(blogId);

      setFormData(data.data);
      if (data.data.blogThumbnail) setblogThumbnail(data.data.blogThumbnail);
      if (data.data.coverImage) setCoverImage(data.data.coverImage);
      if (data.data.tags);

      setTags(
        data.data.tags.map((item) => {
          return { ...item, text: item.name, id: item._id };
        })
      );
      if (data.data.tags) setTagsId(data.data.tags.map((item) => item._id));
      if (data.data.content) {
        setContent(data.data.content);
        setCurrentContent(data.data.content);
      }
      if (data.data.meta) setMeta(data.data.meta);
      if (data.data.meta) setMetaTitle(metaParsingAlgo(data.data.meta).title);
      if (data.data.excerpt)
        setFormData({ ...data.data, metaDescription: data.data.excerpt });
      if (data.data.meta) setKeyword(metaParsingAlgo(data.data.meta).keywords);
      if (data.data._id) setPublishID(data.data._id);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlog();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let coverUrl = null;
      let blogUrl = null;

      if (blogThumbnail && typeof blogThumbnail !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        coverUrl = await uploadFile(
          blogThumbnail,
          "IMAGE",
          setThumbnailUploadPercentage,
          30
        );
      } else setThumbnailUploadPercentage(30);

      if (coverImage && typeof coverImage !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        blogUrl = await uploadFile(
          coverImage,
          "IMAGE",
          setContentUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });

      await editBlogReq(_id, {
        title,
        excerpt: metaDescription,
        content,
        coverImage: blogUrl,
        tags: tagsId,
        slug,
        meta: meta,
        altTag,
      });
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateblog = () => {
    history.push("/dashboard/Blogs/create");
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h3 className="m-0">Edit blog</h3>
          </div>
          <Loader />
        </>
      ) : (
        <>
          {isCreating ? (
            <UploadBlogsStatus
              currentStep={creatingStage}
              contentUploadPercentage={contentUploadPercentage}
              reset={handleCreateblog}
              processingWord="updating"
              processedWord="updated"
            />
          ) : (
            <>
              <EditBlogComp
                pageHeading="Edit Blog"
                handelInput={handelInput}
                title={title}
                metaDescription={metaDescription}
                content={content}
                coverImage={coverImage}
                setCoverImage={setCoverImage}
                metaTitle={metaTitle}
                keywords={keyword}
                setKeyword={setKeyword}
                slug={slug}
                meta={meta}
                setMetaTitle={setMetaTitle}
                tags={tags}
                setTags={setTags}
                tagsId={tagsId}
                setTagsId={setTagsId}
                setContent={setContent}
                handleSubmit={handleSubmit}
                setMeta={setMeta}
                // content={content}
                // setContent={handleContentSelect}
                currentContent={currentContent}
                showSelectAudio={false}
                editBtnText={"Edit blog"}
                isDisabledBtn={isDisabledBtn}
                setIsDisabledBtn={setIsDisabledBtn}
                _id={_id}
                altTag={altTag}
                handlePublishBlog={publishBlogAction}
              />

              {showPublishAlert ? (
                <SweetAlert
                  title="Are you sure?"
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  confirmBtnText="Yes, Unpublish it!"
                  reverseButtons={true}
                  onConfirm={publishBlogActionConfirm}
                  onCancel={() => setShowPublishAlert(false)}
                ></SweetAlert>
              ) : null}

              {showPublishConfirm ? (
                <SweetAlert
                  success
                  title="Unpublished!"
                  confirmBtnBsStyle="primary"
                  onConfirm={() => {
                    setShowPublishAlert(false);
                    setShowPublishConfirm(false);
                    //getPublishedBlog({ page: 1, limit: 10 })
                    history.push(-1);
                  }}
                >
                  Blog has been Unpublished
                </SweetAlert>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditBlog;
