import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CKEditor } from "ckeditor4-react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import htmlToDraft from "html-to-draftjs";

const EditFaqComp = ({
  handleInput,
  heading,
  handleSubmit,
  postDetails,
  // getData,
  onChange,
  title,
  tag,
  category,
  description,
  handelUpdate,
  setPostDetails,
  ckDesc,
  getData,
  faqType,
  handleFaqtype,
  content,
  setContent
}) => {
  const blocksFromHtml = htmlToDraft(content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const [descrip, setDescrip] = useState( EditorState.createWithContent(contentState));
  const rawContentState = convertToRaw(descrip.getCurrentContent());
  const markup = draftToHtml(
    rawContentState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );
  setContent(markup);


  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <AvForm className="form-horizontal" >
                <FormGroup>
                  <Label htmlFor="description">Plan Title</Label>
                  <AvField
                    name="title"
                    placeholder="Enter Plan title"
                    value={title}
                    onChange={(e)=>setPostDetails({...postDetails, title:e.target.value})}
                    type="text"
                    errorMessage="Title is required"
                    className="form-control"
                    id="title"
                  />
                </FormGroup>
                <Row>
                  <Col xs={3}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor="notificationSelect">Tags</Label>
                        <Input
                          name="tag"
                          placeholder="Select Type"
                          type="select"
                          onChange={(e)=>setPostDetails({...postDetails, tag:e.target.value})}
                          className="form-control"
                          id="languageSelect"
                        >
                          <option value={tag?._id}>{tag?.name}</option>
                          {getData?.map((item) => {
                          if (item?.objectType === "FAQTAG" && tag?._id !== item._id) {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          }
                        })}
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup>
                      <div dir="ltr">
                        <Label htmlFor="notificationSelect">Categories</Label>
                        <Input
                          name="category"
                          placeholder="Select Type"
                          type="select"
                          onChange={(e)=>setPostDetails({...postDetails, category:e.target.value})}
                          className="form-control"
                          id="languageSelect"
                        >
                          <option value={category?._id}>{category?.name}</option>
                          {getData?.map((item) => {
                          if (item?.objectType === "FAQCATEGORY" && category?._id !== item._id ) {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          }
                        })}
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">FAQ type</Label>
                      <select
                        name="Faq-type"
                        placeholder="Select Type"
                        type="select"
                        value={faqType}
                        onChange={handleFaqtype}
                        className="form-control"
                        id="languageSelect"
                      >
                        <option value="">Select category</option>
                        <option value="GENERAL">GENERAL</option>
                        <option value="COURSE">COURSE</option>
                        <option value="NLIVE">NLIVE</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                </Row>
              </AvForm>
              <Row>
                  <Col>
                    <Label htmlFor="description">Description</Label>
                    <Editor
                      editorState={descrip}
                      toolbarClassName="toolbarClass"
                      wrapperClassName="wrapperClass"
                      editorClassName="editorClass"
                      onEditorStateChange={setDescrip}
                    />
                  </Col>
                </Row>
              <Button
                color="primary"
                className="w-100 "
                type="submit"
                onClick={handelUpdate}
              >
                Update FAQ
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditFaqComp;
