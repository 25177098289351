import React from 'react'
import AllSessions from '../Components/AllSessions'
import CreateSessions from '../Components/CreateSessions'
import EditSessions from '../Components/EditSessions'

const BookingSessionsRoutes = [
  {
    Component: AllSessions,
    path: '/all',
    exact: true,
    id: 'dashboardBookingSessionsAll',
    routePath: '/dashboard/booking-session/all',
    name: 'All Home Sessions',
  },
  {
    Component: CreateSessions,
    path: '/create',
    exact: true,
    id: 'dashboardBookingSessionsCreate',
    routePath: '/dashboard/booking-session/create',
    name: 'Create Home Sessions',
  },
  {
    Component: EditSessions,
    path: '/edit/:sessionsId',
    exact: true,
    id: 'dashboardBookingSessionsEdit',
  },
  
]

export default BookingSessionsRoutes