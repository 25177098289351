import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { editTagData } from "./EditTag.Constants";
import { editTag } from "./EditTag.Api";

const CreateTag = ({
  isOpen,
  toggleModal,
  currentTag,
  currentTagID,
  currentArray,
  populateTag,
  setIsEditModalOpen,
  isEditModalOpen,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const { name, description } = formData;

  const [isEditing, setIsEditing] = useState(false);
  const [isNotEdited, setIsNotEdited] = useState(true);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Edited Sucessfully",
  });
  const { isAlert, alertType, alertText } = alert;

  useEffect(() => {
    if (currentArray) {
      const tagData = currentArray.filter(({ _id }) => currentTagID === _id)[0];
      if (tagData)
        setFormData({ name: tagData.name, description: tagData.description });
    }
  }, [currentArray, currentTagID]);

  const handelInput = (e) => {
    setIsNotEdited(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setFormData({
      name: "",
      description: "",
    });
    setAlert({
      isAlert: false,
      alertType: "success",
      alertText: "Edited Sucessfully",
    });
    toggleModal();
  };

  const handelEditTag = async () => {
    setIsEditing(true);
    try {
      const { data } = await editTag(
        {
          ...formData,
          objectType: currentTag,
        },
        currentTagID
      );
      setAlert({
        isAlert: true,
        alertType: "success",
        alertText: "Edited Sucessfully",
      });
      populateTag({
        tag: data.data,
        tagType: currentTag,
      });
      setIsNotEdited(true);
      setTimeout(() => {
        setAlert({ isAlert: false });
        setIsEditModalOpen(!isEditModalOpen);
      }, 1000);
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: "danger",
        alertText: "Unable to Edit",
      });
      console.error(error.response.data, error);
    }
    setIsEditing(false);
  };

  return (
    <Modal size="lg" isOpen={isOpen} backdrop="static">
      <AvForm className="needs-validation" onValidSubmit={handelEditTag}>
        <ModalHeader>{editTagData[currentTag].heading}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">
                  {editTagData[currentTag].labelName}
                </Label>
                <AvField
                  name="name"
                  placeholder={editTagData[currentTag].labelName}
                  type="text"
                  errorMessage={editTagData[currentTag].nameError}
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={name}
                  onChange={handelInput}
                  id="name"
                  disabled={isEditing}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">
                  {editTagData[currentTag].labelDescription}
                </Label>
                <AvField
                  name="description"
                  placeholder={editTagData[currentTag].labelDescription}
                  type="text"
                  errorMessage={editTagData[currentTag].descriptionError}
                  className="form-control"
                  value={description}
                  onChange={handelInput}
                  validate={{ required: { value: true } }}
                  id="description"
                  disabled={isEditing}
                />
              </FormGroup>
            </Col>
          </Row>
          {isAlert && (
            <Alert color={alertType} role="alert">
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isEditing || isNotEdited}
          >
            {editTagData[currentTag].createBtn}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default CreateTag;
