import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import { Card, CardBody, Col, FormGroup, Label, Row, Button } from "reactstrap";
import Select from "react-select";
import FileUploader from "../FileUploader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/scss/custom/components/_editor.scss";
import draftToHtml from "draftjs-to-html";
import DatePicker from "react-flatpickr";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const CreateMasteClassComp = ({
  setDescData,
  descData,
  setAboutData,
  aboutData,
  headerImg,
  setHeaderImg,
  title,
  tagline,
  targetDate,
  joinLink,
  setAdName,
  handleChangeMasterClass,
  adName,
  advisor,
  createMasterClass,
  formData,
  setFormData,
  free,
  setFree,
  points,
}) => {
  const [description, setDescription] = useState(EditorState.createEmpty());

  const [selectedDate, setSelectedDate] = useState(null);

  const rawDescState = convertToRaw(description.getCurrentContent());

  const descMarkup = draftToHtml(
    rawDescState,
    {
      trigger: "#",
      separator: " ",
    },
    true
  );

  setDescData(descMarkup);

  let advisorName =
    advisor &&
    advisor?.map((i) => {
      return {
        key: i._id,
        value: i._id,
        label: i.name,
      };
    });

  let freeOption = [
    {
      key: "true",
      value: "true",
      label: "true",
    },
    {
      key: "false",
      value: "false",
      label: "false",
    },
  ];
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Create Master Class</h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
                onValidSubmit={() => createMasterClass()}
              >
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="title">Master Class Title</Label>
                      <AvField
                        name="title"
                        placeholder="Enter title"
                        value={title}
                        onChange={handleChangeMasterClass}
                        type="text"
                        errorMessage="Title is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="title"
                      />
                    </FormGroup>
                  </Col>

                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="isFree">isFree</Label>
                      <Select
                        value={free}
                        isMulti={false}
                        onChange={(select) => setFree(select)}
                        options={freeOption}
                        classNamePrefix="select2-selection"
                        placeholder="Select isFree"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FileUploader
                      file={headerImg}
                      setFile={setHeaderImg}
                      dragText="Drop Header Image here"
                      heading="Header Image"
                      accept="image/png, image/gif, image/jpeg, image/jpg , image/webp"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="tagline">
                        Enter Tagline For Master CLass
                      </Label>
                      <AvField
                        name="tagline"
                        placeholder="Enter tagline"
                        value={tagline}
                        onChange={handleChangeMasterClass}
                        type="text"
                        errorMessage="Tagline is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="tagline"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="targetDate">
                        Enter Day Date and Time
                      </Label>
                      <DatePicker
                        type="number"
                        className="date_picker"
                        placeholderText="Enter Day Date and Time"
                        selected={selectedDate}
                        value={selectedDate}
                        form={formData}
                        setField={setFormData}
                        keyName="targetDate"
                        dateFormat="dd/MM/yyyy"
                        onChange={(selectedDate) => {
                          setSelectedDate(selectedDate);
                          setFormData({
                            ...formData,
                            targetDate: selectedDate,
                          });
                        }}
                        options={{
                          enableTime: true,
                          noCalendar: false,
                          altInput: true,
                          altFormat: "F j, Y H:i", 
                          time_24hr: true,
                        }}
                        
                      />
                    </FormGroup>
                  </Col>
                </Row>
                
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="joinLink">Meeting Link</Label>
                      <AvField
                        name="joinLink"
                        placeholder="Enter Meeting Link"
                        value={joinLink}
                        onChange={handleChangeMasterClass}
                        type="text"
                        errorMessage="Meeting Link is required"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="joinLink"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="supportAdvisorId">
                        Co-ordinator Name
                      </Label>
                      <Select
                        value={adName}
                        isMulti={false}
                        onChange={(select) => setAdName(select)}
                        options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Co-ordinator Name"
                      />
                    </FormGroup>
                  </Col>
                  {free && free.value === 'false' && (
                    <Col className="mt-3">
                      <FormGroup>
                        <Label htmlFor="points">Points</Label>
                        <AvField
                          name="points"
                          placeholder="Enter points"
                          value={points}
                          onChange={handleChangeMasterClass}
                          type="number"
                          errorMessage="Points is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="points"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                {/* <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="google">Google Plan Name</Label>
                      <Select
                        // value={adName}
                        isMulti={false}
                        // onChange={(select) => setAdName(select)}
                        // options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Google Plan Name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label htmlFor="apple">Apple Plan Name</Label>
                      <Select
                        // value={adName}
                        isMulti={false}
                        // onChange={(select) => setAdName(select)}
                        // options={advisorName}
                        classNamePrefix="select2-selection"
                        placeholder="Select Apple Plan Name"
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <Label htmlFor="description">Description</Label>
                    <Editor
                      editorState={description}
                      toolbarClassName="toolbarClass"
                      wrapperClassName="wrapperClass"
                      editorClassName="editorClass"
                      onEditorStateChange={setDescription}
                    />
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={() => createMasterClass()}
                  // disabled={isDisabledBtn}
                >
                  Create Cousre
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateMasteClassComp;
