import axios from "axios";

import baseDomain, {
    discoverAPI,
    createDiscoverAPI,
    secureStsCredApi,
    regenerateSecureStsTokenApi
} from "../../../../Constants/apiRoutes";

export const getAllDiscoverReq = () => {
    return axios.get(`${baseDomain}${discoverAPI}`);
};

export const getOneDiscoverReq = (discoverId) => {
    return axios.get(`${baseDomain}${discoverAPI}/${discoverId}`);
};

export const createDiscoverReq = (payload) => {
    return axios.post(`${baseDomain}${createDiscoverAPI}`, payload);
};

export const getSecureStsCred = (params) => {
    return axios.get(`${baseDomain}${secureStsCredApi}`, {
        params,
    });
};

export const regenerateSecureStsToken = () => {
    return axios.get(`${baseDomain}${regenerateSecureStsTokenApi}`);
};