import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Label, FormGroup } from "reactstrap";

import AuthContext from "../../../../context/auth/authContext";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { getLocal } from "../../../../utils/localStorage";

const Login = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const [loginCred, setLoginCred] = useState({
    email: "",
    password: "",
  });

  const { email, password } = loginCred;

  const handelInput = (e) => {
    setLoginCred({
      ...loginCred,
      [e.target.name]: e.target.value,
    });
  };

  const handelLogin = async () => {
    authContext.loginUser(loginCred, history);
  };

  const {errorMessage} = authContext;

  useEffect(() => {
    authContext.setLoading();
    if (getLocal("tkn")) history.push("/dashboard");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="p-2 mt-5">
      <AvForm className="form-horizontal" onValidSubmit={handelLogin}>
        <FormGroup className="auth-form-group-custom mb-4">
          <i className="ri-user-2-line auti-custom-input-icon"></i>
          <Label htmlFor="email">Email</Label>
          <AvField
            name="email"
            value={email}
            onChange={handelInput}
            type="email"
            className="form-control"
            id="email"
            validate={{ email: true, required: true }}
            placeholder="Enter Email"
          />
        </FormGroup>

        <FormGroup className="auth-form-group-custom mb-4">
          <i className="ri-lock-2-line auti-custom-input-icon"></i>
          <Label htmlFor="userpassword">Password</Label>
          <AvField
            name="password"
            value={password}
            onChange={handelInput}
            type="password"
            className="form-control"
            id="userpassword"
            placeholder="Enter password"
          />
        </FormGroup>

        {errorMessage && 
         <div style={{width:"100%", padding:"5px", color:"red", margin:"-20px 0 -20px 0"}}>{errorMessage}</div>
        }

        <div className="mt-4 text-center">
          <Button
            color="primary"
            className="w-md waves-effect waves-light"
            type="submit"
          >
            Log In
          </Button>
        </div>

        {/* <div className="mt-4 text-center">
          <Link to="/auth/forgot-password" className="text-muted">
            <i className="mdi mdi-lock mr-1"></i> Forgot your password?
          </Link>
        </div> */}
      </AvForm>
    </div>
  );
};

export default Login;
