import axios from "axios";
import {
  baseDomain,
  stage,
  getProgramsData,
  getProgramsDataById,
  updateProgramsData,
  publishedMusic,
  programMedia,
} from "../../../../Constants/apiRoutes";

export const getAllPrograms = () => {
  return axios.get(`${baseDomain}${stage}${getProgramsData}`);
};
export const getAllProgramsById = (programId) => {
  return axios.get(`${baseDomain}${stage}${getProgramsDataById}/${programId}`);
};
export const updatePrograms = (programId, payload) => {
  return axios.put(
    `${baseDomain}${stage}${updateProgramsData}/${programId}`,
    payload
  );
};
export const getAllMusic = () => {
  return axios.get(`${baseDomain}${stage}${programMedia}${publishedMusic}?page=1&limit=3000`);
};
