import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditMasterClassComp from "../../../../../../Components/EditMasteComp";
import {
  getAllSupportAdvisorApi,
  getMasterClassByIDApi,
  updateMasterClassByIDApi,
} from "../../Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { creatingStates } from "../../../Blogs/Constants";
import { uploadFile } from "../../../Blogs/others/Uploads/upload.Helper";

const EditMasterClass = () => {
  const history = useHistory();
  const { masterId } = useParams();
  const [descData, setDescData] = useState("");
  const [aboutData, setAboutData] = useState("");
  const [headerImg, setHeaderImg] = useState("");
  const [advisor, setAdvisor] = useState([]);
  const [adName, setAdName] = useState([]);
  const [free, setFree] = useState({ key: "true", value: "true", label: "true" });
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    targetDate: "",
    tagline: "",
    joinLink: "",
    points: ""
  });
  const [pointsCheck, setPointsCheck] = useState([]);
  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });

  const { isCreating, creatingStage } = creating;
  const [thumbnailUploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const { title, tagline, targetDate, joinLink, points } = formData;

  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  function htmlDecode(input) {
    console.log("ceck");
    var e = document.createElement("div");
    e.innerHTML = input;
    console.log(e.firstChild.nodeValue, "check");
    return e.firstChild.nodeValue;
  }

  let freeOption = [
    {
      key: "true",
      value: "true",
      label: "true",
    },
    {
      key: "false",
      value: "false",
      label: "false",
    },
  ];

  const getMasterClassByIDData = async () => {
    const { data } = await getMasterClassByIDApi(masterId);
    setPointsCheck(data?.data)
    console.log(data?.data, "masterclassDoc");
    var isoDateString = data?.data?.targetDate; 
    
    var isoDate = new Date(isoDateString);

    var formattedDate = isoDate.toString();

    console.log(formattedDate, "formattedDate");
    const res = await getAllSupportAdvisorApi();
    setAdvisor(res?.data?.data);
    setFree({
      key: data?.data?.isFree.toString(),
      label: `${data?.data?.isFree}`,
      value: data?.data?.isFree.toString(),
    });
    setFormData({
      ...formData,
      title: data?.data?.title,
      joinLink: data?.data?.joinLink,
      targetDate: data?.data?.targetDate,
      tagline: data?.data?.tagline,
      points: data?.data?.points
    });
    if (!selectedDate) {

      setSelectedDate((data?.data?.targetDate)?.slice(0, 10));

    }
    setHeaderImg(data?.data?.image);
    setAdName({
      key: data?.data?.supportAdvisorId,
      label: data?.data?.supportAdvisorId?.name,
      value: data?.data?.supportAdvisorId,
    });
    if (data?.data?.description) {
      setDescData(data?.data?.description);
    }
  };

  const handleSubmit = async () => {
    try {
      let headerImgUrl = headerImg;
      setShowUnPublishAlert(true);
      if (headerImg && typeof headerImg !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });

        headerImgUrl = await uploadFile(
          headerImg,
          "COURSES-IMAGE-HEADER",
          setThumbnailUploadPercentage,
          50
        );
      } else setContentUploadPercentage(50);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
      const updatedPoints = formatMultiSelectData(free) === 'true' ? 0 : Number(formData.points);
      console.log(pointsCheck),

      await updateMasterClassByIDApi(masterId, {
        title: formData?.title,
        description: descData,
        image: headerImgUrl,
        targetDate: new Date(formData?.targetDate).toISOString(),
        tagline: formData?.tagline,
        joinLink: formData?.joinLink,
        supportAdvisorId: formatMultiSelectData(adName),
        isFree: formatMultiSelectData(free) === 'true' ? true : false,
        points: updatedPoints,
        // plans: {
        //   google: "annually_899_1y_play",
        //   apple: "annually_899_1y_play",
        // },
      });

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      });
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeMasterClass = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getMasterClassByIDData();
  }, []);

  useEffect(() => {
    console.log(free);
  }, [free]);

  return (
    <>
      {descData && (
        <EditMasterClassComp
          freeOption={freeOption}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          free={free}
          setFree={setFree}
          setDescData={setDescData}
          descData={descData}
          setAboutData={setAboutData}
          aboutData={aboutData}
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          formData={formData}
          setFormData={setFormData}
          title={title}
          tagline={tagline}
          targetDate={targetDate}
          joinLink={joinLink}
          setAdName={setAdName}
          adName={adName}
          setAdvisor={setAdvisor}
          advisor={advisor}
          handleChangeMasterClass={handleChangeMasterClass}
          handleSubmit={handleSubmit}
          setShowUnPublishAlert={setShowUnPublishAlert}
          points={points}
          pointsCheck={pointsCheck}
        />
      )}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={() => {
            handleSubmit();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/master-class/all");
          }}
        >
          Courses has been edited
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditMasterClass;
