export const NutriLiveHeading = [
  // {
  //   text: 'S.No.',
  //   dataField: 'sNo', 
  // },
  {
    text: 'User',
    dataField: 'name', 
  },
  {
    text: 'Email',
    dataField: 'email', 
  },
  {
    text: 'Contact',
    dataField: 'contact', 
  },
  {
    text: 'Start-Date',
    dataField: 'date', 
  },
  {
    text: 'End-Date',
    dataField: 'endDate', 
  },  
  {
    text: 'Time',
    dataField: 'time', 
  },
  {
    text: 'Plan',
    dataField: 'plan', 
  },
  {
    text: 'Price',
    dataField: 'price', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
  {
    text: 'AdvisorName',
    dataField: 'advisorName', 
  },
  {
    text: 'Meeting Link',
    dataField: 'meetingLink', 
  },
  

]

export const NutriHomeHeading = [
  {
    text: 'User',
    dataField: 'name', 
  },
  {
    text: 'Email',
    dataField: 'email', 
  },
  {
    text: 'Contact',
    dataField: 'contact', 
  },
  {
    text: 'Start-Date',
    dataField: 'date', 
  },
  {
    text: 'End-Date',
    dataField: 'endDate', 
  },  
  {
    text: 'Time',
    dataField: 'time', 
  },
  {
    text: 'Plan',
    dataField: 'plan', 
  },
  {
    text: 'Price',
    dataField: 'price', 
  },
  // {
  //   text: 'Title',
  //   dataField: 'title', 
  // },
  // {
  //   text: 'AdvisorName',
  //   dataField: 'advisorName', 
  // },
  {
    text: 'Meeting Link',
    dataField: 'meetingLink', 
  },
  

]

export const allNutriClassiHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo', 
  },
  {
    text: 'Title',
    dataField: 'title', 
  },
]