export const PublishedLessonTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Title",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
  {
    text: "Media Type",
    dataField: "mediaType",
  },
];

export const UnPublishedLessonTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Title",
    dataField: "title",
  },
  {
    text: "Description",
    dataField: "description",
  },
  {
    text: "Ready to Publish",
    dataField: "readtToPublish",
  },
  {
    text: "Media Type",
    dataField: "mediaType",
  },
];

export const fileTypes = {
  TRACK_IMAGE: "TRACK_IMAGE",
  TRACK_VIDEO: "TRACK_VIDEO",
};

export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_VIDEO: 2,
  CREATING_VIDEO: 3,
  UPLOAD_COMPLETE: 4,
};
