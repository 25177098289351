import axios from "axios";
const domain = 'https://back-end-preprod.nispand.com/v2'

export const postCousreApi = (payload) => {
  return axios.post(`${domain}/course`, payload)
}

export const getAllSupportAdvisorApi = () => {
  return axios.get(`${domain}/supportAdvisor`)
}

export const getAllLessonApi = () => {
  return axios.get(`${domain}/lesson/?isDisabled=false&isPublished=true`)
}

export const getAllPublishedCourseApi = ({ pubType, page, limit }) => {
  return axios.get(`${domain}/course?isPublished=${pubType}&page=${page}&limit=${limit}`)
}

export const updateUnpublishCourseApi = (id) => {
  return axios.patch(`${domain}/course/unpublish/${id}`)
}

export const updatePublishCourseApi = (id) => {
  return axios.patch(`${domain}/course/publish/${id}`)
}

export const getCourseByIdApi = (courseId) => {
  return axios.get(`${domain}/course/byId/${courseId}`)
}

export const updateCourseApi = (id, payload) => {
  return axios.put(`${domain}/course/update/${id}`, payload)
}

export const getAllFaq = () => {
  return axios.get(`${domain}/faq/getAllFaq/COURSE`)
}

export const getAllLiveSessions = ({ page, limit }) => {
  return axios.get(`${domain}/liveSession?isLive=true&page=${page}&limit=${limit}`)
}

export const getAllLiveSessionsUnpublished = ({ page, limit }) => {
  return axios.get(`${domain}/liveSession?isLive=false&page=${page}&limit=${limit}`)
}

export const createLiveSession = (payload) => {
  return axios.post(`${domain}/liveSession`, payload)
}

export const getLiveSessionById = (liveSessionId) => {
  return axios.get(`${domain}/liveSession/${liveSessionId}`)
}

export const updateLiveSessionById = (liveSessionId, payload) => {
  return axios.put(`${domain}/liveSession/${liveSessionId}`, payload)
}

export const goLiveSession = (liveSessionId) => {
  return axios.put(`${domain}/liveSession/goLive/${liveSessionId}`)
}

export const endLiveSession = (liveSessionId) => {
  return axios.put(`${domain}/liveSession/endLive/${liveSessionId}`)
}
