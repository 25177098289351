import Login from "../Components/Login";
// import Register from "../Components/Register";
// import ForgotPassword from "../Components/ForgotPassword";

export const AuthRoutes = [
  {
    Component: Login,
    path: "/login",
    exact: true,
    id: "authLogin",
    routePath: "/auth/login",
  },
  // {
  //   Component: Register,
  //   path: "/register",
  //   exact: true,
  //   id: "authRegister",
  //   routePath: "/auth/register",
  // },
  // {
  //   Component: ForgotPassword,
  //   path: "/forgot-password",
  //   exact: true,
  //   id: "authForgotPassword",
  //   routePath: "/auth/forgot-password",
  // },
];
