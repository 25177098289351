import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";
// import { searchBlogsunPublished } from "../../Views/Dashboard/Components/Blogs/Blogs.Api";

const UnPublishBlogs = ({
  tableData = [],
  tableHeading = [],
  handleEditBlogs,
  handleViewBlogs,
  handleDeleteBlogs,
  handleUnpublishBlogs,
  handleTablePageChange,
  page,
  sizePerPage,
  isLoading,
  unpublishedTotalSize,
  setUnPublishedBlogs,
}) => {
  const searchQuery = useRef();
  const history = useHistory();
  const [rowData, setRowData] = useState([]);
  const [searchPost, setSearchPost] = useState([]);
  const onChange = (event) => {
    setSearchPost(event.target.value);
  };

  const actionsFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row?._id}>
        <i
          className="ri-play-list-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleViewBlogs(row?._id)}
        ></i>
        <i
          className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
          onClick={() => handleEditBlogs(row?._id)}
        ></i>
        <i
          className="ri-delete-bin-2-fill text-danger h4 cursor-pointer mr-2"
          onClick={() => handleDeleteBlogs(row?._id)}
        ></i>
      </div>
    );
  };
  const blogFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleUnpublishBlogs(row._id)}
        >
          Publish
        </Button>
      </div>
    );
  };

  useEffect(() => {
    tableHeading.push({
      text: "Actions",
      dataField: "actions",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: actionsFormatter,
    });
    tableHeading.push({
      text: "Publish Blog",
      dataField: "publish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: blogFormatter,
    });
    return () => {
      tableHeading.pop();
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = {
          ...el,
          name: el.title,
          description: el.excerpt?.replace(/<\/?p>/g, ""),
        };
        o.sNo = i + 1 + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
  }, [tableData]);

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">UnPublished Blogs</h3>
                  <div
                    className="page-title-right"
                    style={{ display: "flex", width: "20%", gap: "10px" }}
                  >
                    <Button
                      color="danger"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/Blogs/bin")}
                    >
                      Blogs Bin
                    </Button>
                    <Button
                      color="primary"
                      className="waves-effect waves-light"
                      style={{ width: "100%" }}
                      onClick={() => history.push("/dashboard/Blogs/create")}
                    >
                      Add blog
                    </Button>
                  </div>
                </div>
                {/* <div className="searchUnpublishedBlogs" 
                  style=  
                    {{ display: 'flex'  ,
                      padding:'5px' , width:'270px' , float:'right' }}>
                  <input ref={searchQuery} type='text' placeholder='Search Blogs' onKeyDown={handleKeyPress}
                    style={{ border:'1px solid aqua', outlineColor: 'transparent' , borderRadius:'10px' , width:'900px' ,
                      padding:'5px' }} />
                  
                </div>  */}
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={unpublishedTotalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UnPublishBlogs;
