export const PublishedBlogTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Blog Name',
    dataField: 'name',
  },
  // {
  //   text: 'Category',
  //   dataField: 'categoryId.name',
  // },
  {
    text: 'Blog Details',
    dataField: 'description',
  }
]
 

export const UnPublishedBlogTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Blog Name',
    dataField: 'name',
  },
  // {
  //   text: 'Category',
  //   dataField: 'categoryId.name',
  // },
  {
    text: 'Blog Details',
    dataField: 'description',
  }
]
export const searchedBlogTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Blog Name',
    dataField: 'name',
  },
  {
    text: 'Blog Details',
    dataField: 'description',
  }
]

export const binBlogTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
    
  },
  {
    text: 'Blog Name',
    dataField: 'name',
  },
  {
    text: 'Blog Details',
    dataField: 'description',
  },
]



export const creatingStates = {
  UPLOADING_IMAGE: 1,
  UPLOADING_MUSIC: 2,
  CREATING_MUSIC: 3,
  UPLOAD_COMPLETE: 4,
}