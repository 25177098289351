import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditAppointmentComp from "../../../../../../Components/EditAppointmentComp";
import { getAllSupportAdvisorApi } from "../../../MasterClass/Constants/api";
import {
  updateAppointmentApi,
  getUserAppointmentByID,
} from "../../Constant/api";
import SweetAlert from "react-bootstrap-sweetalert";

const EditAppointment = () => {
  const history = useHistory()
  const { appointmentId } = useParams();
  const [advisor, setAdvisor] = useState();
  const [adName, setAdName] = useState();
  const [appTime, setAppTime] = useState();
  const [appointmentData, setAppointmentData] = useState();
  const [appData, setAppData] = useState("");
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    advisorName: "",
    date: "",
    time: "",
  });
  const [allTime, setAllTime] = useState({});
  const [allTimeUpdated, setAllTimeUpdated] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const { firstName, advisorName, date, time } = formData;

  const formatMultiSelectData = (data) => {
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };
  const getUserAppointmentData = async () => {
    const { data } = await getUserAppointmentByID(appointmentId);
    setAppData(data?.data);
    setAppointmentData(data?.data);
    setAppTime(data?.data?.timeSlot?.time);

    var userName = data?.data?.userId?.firstName;

    const res = await getAllSupportAdvisorApi();
    setAdvisor(res?.data?.data);

    setFormData({
      ...formData,
      firstName: userName,
    });

    setSelectedDate(new Date(data?.data?.timeSlot?.date));

    setAdName({
      key: data?.data?.supportAdvisorId._id,
      label: data?.data?.supportAdvisorId?.name,
      value: data?.data?.supportAdvisorId?._id,
    });

    let dateUser = data?.data?.timeSlot?._id;

    setAllTime({
      key: dateUser,
      label: data?.data?.timeSlot?.time?.slice(11, 16),
      value: dateUser,
    });

    setAllTimeUpdated({
      key: dateUser,
      label: data?.data?.timeSlot?.time?.slice(11, 16),
      value: dateUser,
    });
  };



  const updateAppointment = async () => {


    let be = new Date(selectedDate);

    let beMonth =
      be.getUTCMonth() + 1 <= 9
        ? `0${be.getUTCMonth() + 1}`
        : be.getUTCMonth() + 1;

    let beDate =
      be.getUTCDate() + 1 <= 9 ? `0${be.getUTCDate()}` : be.getUTCDate();

    let newDate = `${be.getUTCFullYear()}-${beMonth}-${beDate}T${
      allTime?.label?.slice(0,2)
    }:00:00.000Z`; // for unchanged time

    let newDateUpdate = `${be.getUTCFullYear()}-${beMonth}-${beDate}T${
      allTime?.value
    }:00:00.000Z`; // for changed time

    try {
      setShowUnPublishAlert(true);
      if (allTime?.value === allTimeUpdated?.value) {
        await updateAppointmentApi(appointmentId, {
          timeSlot: {
            time: newDate,
            date: selectedDate,
          },
          supportAdvisorId: formatMultiSelectData(adName),
        });
      } else{
        await updateAppointmentApi(appointmentId, {
          timeSlot: {
            time: newDateUpdate,
            date: selectedDate,
          },
          supportAdvisorId: formatMultiSelectData(adName),
        });
      }
     
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error,'error');
    }

   
  };

  // const test = () => {
  //   let a = appointmentData;
  //   console.log(a, "hekiodicoio");
  //   if (
  //     appointmentData
  //       ?.map((el) => el?.timeSlot)
  //       ?.map((idx) => idx.time?.slice(11, 16)) <= "12:00"
  //   ) {
  //     console.log("<12");
  //   } else {
  //     console.log(">12");
  //   }
  // };

  useEffect(() => {
    getUserAppointmentData();
  }, []);
  // useEffect(() => {
  //   if (appointmentData) {
  //     test();
  //   }
  // }, [appointmentData]);

  return (
    <>
      <EditAppointmentComp
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        setAdName={setAdName}
        adName={adName}
        setAdvisor={setAdvisor}
        advisor={advisor}
        formData={formData}
        setFormData={setFormData}
        allTime={allTime}
        setAllTime={setAllTime}
        firstName={firstName}
        updateAppointment={updateAppointment}
        setShowUnPublishAlert={setShowUnPublishAlert}
      />
      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Edit it!"
          reverseButtons={true}
          onConfirm={() => {
            updateAppointment();
            setShowUnPublishAlert(false);
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Edited!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            history.push("/dashboard/user-appointment/all");
          }}
        >
          User Appointment has been edited
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditAppointment;
