export const userTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Name",
    dataField: "firstName",
  },
  {
    text: "Email",
    dataField: "email",
  },
  {
    text: "Phone No.",
    dataField: "phoneNumber",
  },
  {
    text: "Country",
    dataField: "country",
  },
];

