import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,

} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { uploadFile } from "../../Views/Dashboard/Components/Blogs/others/Uploads/upload.Helper";
import { creatingStates } from "../../Views/Dashboard/Components/Series/constant";
import { createMediaDropdownListApi, editApi } from "../EditMedia/EditTag.Api";
import FileUploader from "../../Components/FileUploader";
import { getMediaTags } from "../../Views/Dashboard/Components/MediaProperties/Tag.Apis";


const TagInfo = ({
  isTagInfoOpen,
  toggleTagInfoModal,
  name,
  description,
  objectType,
  setTagInfo,
  tagInfo,
  mapping,
  imgAsset,
  editid,
  setImgAsset,
  mediaDropdown,
  media
}) => {
  const handleClose = () => {
    toggleTagInfoModal();
  };

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;
  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Edited Sucessfully",
  });
  const { isAlert, alertType, alertText } = alert;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });

  const handelInput = (e) => {
    setTagInfo({
      ...tagInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [dropDown, setDropDown] = useState([]);
  // const [subDropDown, setSubDropDown] = useState([])
  const [subCat, setSubCat] =  useState([])
  // const [count, setCount] = useState('')
  const [subCount, setSubCount] = useState('')

  const getMediaDropdownData = async (params) => {
    try {
      const { data } = await createMediaDropdownListApi(params);
      setSubCount(data.count)
      
      setDropDown(data.data);
    } catch (error) {
      console.error(error);
    }
    
  };

  useEffect(() => {
    getMediaDropdownData({page:1,limit:subCount});
  }, [subCount]);

  const handleSelectMediaOneInput = (event) => {
    event.preventDefault();
    setTagInfo({ ...tagInfo, mediaIds:[ event.target.value, media[1] ] });
  };
  const handleSelectMediaTwoInput = (event) => {
    event.preventDefault();
    setTagInfo({ ...tagInfo, mediaIds:[media[0] , event.target.value] });
    console.log(event.target.value,'event.target.value');
  };

  const editCatApi = async () => {
    try {
      let imgAssetURL = null;
      if (imgAsset && typeof imgAsset !== "string") {
        setCreating({
          isCreating: true,
          creatingStage: creatingStates.UPLOADING_IMAGE,
        });
        imgAssetURL = await uploadFile(
          imgAsset,
          "CATEGORY-IMAGE",
          setThumbnailUploadPercentage,
          30
        );
      } else setThumbnailUploadPercentage(30);
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.CREATING_MUSIC,
      });
      await editApi(editid, {
        ...tagInfo,
        objectType: "SUBCATEGORY",
        mediaIds:media,
        imgAsset: imgAssetURL,
      });
      setAlert({
        isAlert: true,
        alertType: "success",
        alertText: "Edited Sucessfully",
      });

      setTimeout(() => {
        setAlert({ isAlert: false });
        toggleTagInfoModal()
      }, 1000);
    } catch (error) {
      console.log(error);
      setAlert({
        isAlert: true,
        alertType: "danger",
        alertText: "Unable to Edit",
      });
    }
  };

  return (
    <Modal size="lg" isOpen={isTagInfoOpen} backdrop="static">
      <AvForm className="needs-validation">
        <ModalHeader>Tags and Categories Information</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <AvField
                  name="name"
                  type="text"
                  className="form-control"
                  value={name}
                  id="name"
                  onChange={handelInput}
                  // disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  type="text"
                  className="form-control"
                  value={description}
                  id="description"
                  onChange={handelInput}
                  // disabled={true}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label htmlFor="objectType">Type</Label>
                <AvField
                  name="objectType"
                  type="text"
                  className="form-control"
                  value={objectType}
                  id="objectType"
                  disabled={true}
                />
              </FormGroup>
            </Col>
            {mapping === false && (
              <Col md="12">
                <FileUploader
                  file={imgAsset}
                  setFile={setImgAsset}
                  dragText="Drop Cover Image here"
                  heading="Select Cover Image"
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                />
              </Col>
            )}
             {mediaDropdown === true && (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mediaOne">
                      
                    </Label>
                    <Input
                      name="tag"
                      placeholder="Select Type"
                      type="select"
                      value={media[0]}
                      onChange={(e) => handleSelectMediaOneInput(e)}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value={"Select Media"}>Select Media One</option>
                      {dropDown?.map((item) => {
                        return (
                          <option
                            disabled={item._id === media[1]}
                            selected={item._id === media[0]}
                            key={item._id}
                            value={item._id}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="mediaTwo">
                     
                    </Label>
                    <Input
                      name="tag"
                      placeholder="Select Type"
                      type="select"
                      value={media[1]}
                      onChange={(e) => handleSelectMediaTwoInput(e)}
                      className="form-control"
                      id="languageSelect"
                    >
                      <option value={"Select Media"}>Select Media Two</option>
                      {dropDown?.map((item) => {
                        return (
                          <option
                            disabled={item._id === media[0]}
                            selected={item._id === media[1]}
                            key={item._id}
                            value={item._id}
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
        <Button
            type="button"
            onClick={editCatApi}
            color="light"
            className="waves-effect"
          >
            Edit
          </Button>
          <Button
            type="button"
            onClick={handleClose}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default TagInfo;
