import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import {
  createCouponReq,
  updateCouponReq,
} from "../../Views/Dashboard/Components/Coupon/Coupons.Apis";

const CouponForm = ({
  pageHeading,
  plansList,
  couponData,
  buttonText,
  update,
}) => {
  const [formData, setFormData] = useState({
    couponCode: "",
    expireTime: "",
    discount: "",
    plan_identifier: "",
    planId: "",
    isHidden: false,
    provider: "razorpay",
    countryCode: null
  });
  const history = useHistory();

  const [plansData, setPlansData] = useState([]);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: "success",
    alertText: "Created Sucessfully",
  });

  useEffect(() => {
    if (plansList.length > 0) {
      let selectPlanData = plansList.map((item) => {
        return {
          value: item.plan_identifier,
          label: item.plan_identifier,
          key: item._id,
        };
      });
      setPlansData(selectPlanData);
    }
  }, [plansList]);

  useEffect(() => {
    if (couponData) {
      const {
        couponCode,
        discount,
        expireTime,
        planId: { plan_identifier, _id },
        isHidden,
        countryCode
      } = couponData;
      setFormData({
        ...formData,
        couponCode: couponData.couponCode,
        discount: couponData.discount,
        expireTime: couponData.expireTime,
        planId: _id,
        isHidden: couponData.isHidden,
        countryCode: couponData.countryCode,
        plan_identifier: {
          value: plan_identifier,
          label: plan_identifier,
          key: _id,
        },
      });
    }
  }, [couponData]);


  const handelInput = (e) => {
    if(e.target.name === 'isHidden')
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePlanSelect = (plan) => {
    const { key } = plan;
    setFormData({ ...formData, plan_identifier: plan, planId: key });
  };

  const countryCodeData = [
    { key: "INTERNATIONAL", label: "INTERNATIONAL", value: "INTERNATIONAL" },
  { key: "NATIONAL", label: "NATIONAL", value: "NATIONAL" },
  ];


  const handleCountryCode = (select) => {
    setFormData({...formData, countryCode:select})
  };

  const handleSubmit = async () => {
    if (
      formData.couponCode === "" ||
      formData.planId === "" ||
      formData.discount === "" ||
      formData.expireTime === "" ||
      formData.countryCode.value === ""
    ) {
      setAlert({
        isAlert: true,
        alertType: "warning",
        alertText: "Required field empty",
      });
    } else {
      try {
        setIsDisabledBtn(true);
        if (update && couponData) {
          updateCouponReq(couponData._id, formData)
        } else {
          await createCouponReq({...formData,countryCode:formData.countryCode.value});
        }
        setAlert({
          isAlert: true,
          alertType: "success",
          alertText: `${update?"Updated":"Created"} Sucessfully`,
        });
        setTimeout(() => {
          history.push("/dashboard/coupon/all");
          setIsDisabledBtn(false);
        }, 1000);
      } catch (error) {
        setAlert({
          isAlert: true,
          alertType: "danger",
          alertText: "Unable to Create",
        });
        console.error(error.response.data, error);
      }
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
            </Row>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="title">Coupon Code</Label>
                    <AvField
                      name="couponCode"
                      placeholder="Enter Coupon Code"
                      value={formData.couponCode}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="plan_identifier"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="planSelect">Plan</Label>
                    <Select
                      value={formData.plan_identifier}
                      isMulti={false}
                      onChange={handlePlanSelect}
                      options={plansData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        plansData && plansData.length === 0
                          ? "Loading..."
                          : "Select Plan"
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Discount Price</Label>
                    <AvField
                      name="discount"
                      placeholder="Enter Discount Price"
                      value={formData.discount}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Discount Price is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="discount"
                    />
                  </FormGroup>
                </Col>
                <Col ls={4}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Expire Time</Label>
                    <AvField
                      name="expireTime"
                      placeholder="Enter Expire Time"
                      value={formData.expireTime}
                      onChange={handelInput}
                      type="date"
                      errorMessage="Expire Time is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="expireTime"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <FormGroup className="select2-container">
                    <Label className="control-label">Select Country Code</Label>
                    <Select
                      value={formData.countryCode}
                      isMulti={false}
                      onChange={handleCountryCode}
                      options={countryCodeData}
                      classNamePrefix="select2-selection"
                      placeholder="Select Country Code"
                    />
                  </FormGroup>
                </Col> 
                <Col className='d-flex align-items-center mt-3' xs={2}>
                  <div className='custom-control custom-switch mb-2 ' dir='ltr'>
                    <Input
                      type='checkbox'
                      className='custom-control-input'
                      name='isHidden'
                      checked={formData.isHidden}
                      onClick={handelInput}
                      id='isHidden'
                    />
                    <Label className='custom-control-label' htmlFor='isHidden'>
                       Hidden
                    </Label>
                  </div>
                </Col>
              </Row>
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {buttonText}
              </Button>
            </AvForm>
            <br />
            <Row>
              <Col>
                {alert.isAlert && (
                  <Alert color={alert.alertType} role="alert">
                    {alert.alertText}
                  </Alert>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CouponForm;
