export const colorsArray = [
  "#5664d2",
  "#1cbb8c",
  "#eeb902",
  "#003f5c",
  "#f46a6a",
  "#50a5f1",
];

export const getPlanAlalysisGraphConfig = ({ series, labels }) => {
  return {
    series, // array of percentage
    options: {
      labels, // array if labels
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: colorsArray, // array of colors
    },
  };
};

export const getPercentage = ({ total, amount }) => {
  return Number(
    Number((Number(amount | 0) * 100) / Number(total | 0)).toFixed(2)
  );
};

export const getFormattedData = (data) => {
  const series = [];
  const labels = [];
  const planCount = [];
  let totalNumber =
    data &&
    data.lenght !== 0 &&
    data.reduce((prev, curr) => {
      if (typeof prev.activeSubscribtion !== "undefined")
        return prev.activeSubscribtion + curr.activeSubscribtion;
      return prev + curr.activeSubscribtion;
    });
  data.forEach(({ activeSubscribtion, plan }) => {
    if (plan && plan[0].plan_identifier) {
      series.push(
        getPercentage({ total: totalNumber, amount: activeSubscribtion })
      );
      labels.push(plan ? plan[0].plan_identifier : "");
      planCount.push(activeSubscribtion);
    }
  });
  return {
    series,
    labels,
    planCount,
  };
};
