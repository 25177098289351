import React, { useState, useEffect } from 'react'
import SeriesForm from '../../../../../../Components/SeriesForm'
import { getCategoryAPI, getMediaAPI, SeriesCreateAPI, getAllTags } from '../../Api'
import { creatingStates } from '../../constant'
import { uploadFile } from '../../../Blogs/others/Uploads/upload.Helper'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from 'react-router-dom'



const CreateSeries = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    mediaType: '',
    media: []
  })

  const { title, description, mediaType, media } = formData;

  const [getAllMediaOptions, setGetAllMediaOptions] = useState([])
  const [getAllCategoryOptions, setGetAllCategoryOptions] = useState([])
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false)
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [seriesImage, setSeriesImage] = useState(null)
  const history = useHistory();


  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
    errorType: "warning",
  });
  const { isError, errorMessage, errorType } = error;

  const [creating, setCreating] = useState({
    isCreating: false,
    creatingStage: creatingStates.UPLOADING_IMAGE,
  });
  const { isCreating, creatingStage } = creating;

  const [thumbnailuploadPercentage, setThumbnailUploadPercentage] = useState(0);
  const [contentUploadPercentage, setContentUploadPercentage] = useState(0);
  const [mediaDataSelect,setMediaDataSelect]= useState([])
  const [selectedTags,setSelectedTags] = useState('');
  const [tagsData,setTagsData] = useState([])


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const MediaType = [
    { label: "Mind", value: "AUDIO" },
    { label: "Body", value: "VIDEO" }
  ]
  
  const handleDropdownChange = (e) => {
    setFormData({
      ...formData,
      category: e.target.value
    })
  }

  
  const formatMultiSelectData = (data) => {
    console.log(data, "asdfghjkl;sdfghjk");
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const getCreateSeries = async () => {
    try {
      if (!seriesImage)
        return setError({
          isError: true,
          errorMessage: 'Please select a cover image',
          errorType: 'warning',
        })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })

      const thumbnailUrl = await uploadFile(
        seriesImage,
        'SERIES-COVER',
        setThumbnailUploadPercentage,
        30
      )

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_MUSIC,

      })

      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      await SeriesCreateAPI({
        title: title,
        description: description,
        imgAsset: thumbnailUrl,
        type: 'SERIES',
        mediaType: 'AUDIO',
        mediaData: media,
        tags:formatMultiSelectData(selectedTags),
        categoryId: formData.category,
      })
      setShowUnPublishAlert(false)
      setShowUnPublishConfirm(true)
      setCreating({
        isCreating: true,
        creatingStage: creatingStates.UPLOAD_COMPLETE,
      })
    } catch (error) {
      setCreating({
        isCreating: false,
        creatingStage: creatingStates.UPLOADING_IMAGE,
      })
      setThumbnailUploadPercentage(0)
      setContentUploadPercentage(0)
      setError({
        isError: true,
        errorMessage: 'Unable to create Image please try again later',
        errorType: 'danger',
      })
    }
  }

  const handleSubmit = () => {
    setShowUnPublishAlert(true)
    setShowUnPublishConfirm(false)
  }


  const getCategoryData = async (queryParams) => {
    try {
      const { data } = await getCategoryAPI(queryParams);
      const category = data.data;
      setGetAllCategoryOptions(category);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTagsData = async () => {
    const {data} = await getAllTags();
    console.log(data?.data,'hurraaahhhh');
    setTagsData(data?.data)
  }

  const getMediaData = async (categoryId) => {
    try {
      const { data } = await getMediaAPI(categoryId);
      console.log(data.data.map((i)=>{
        return{
          key:i._id,
          value:i._id,
          label:i.title
        }
      }),'tempOne');
      setGetAllMediaOptions(data?.data?.map((i)=>{
        return{
          key:i._id,
          value:i._id,
          label:i.title
        }
      }))
     
    } catch (error) {
      console.error(error);
    }
  }
  const handleMediaSelect = (selectedOptions) => {
    console.log(selectedOptions, "rrrrrrrrrrrr");
    const mediaDataOption = selectedOptions&& selectedOptions.length>0&& selectedOptions.map((option) => option.key);
    setFormData((prevFormData) => ({
      ...prevFormData,
      media: mediaDataOption,
    }));
    setMediaDataSelect(selectedOptions);
  };

  useEffect(()=>{handleMediaSelect()},[])
  const selectMediaType = (e) =>{
    setFormData({
      ...formData,
      mediaType: e.target.value
    })
  }

  useEffect(() => {
    getCategoryData();
    if (formData.category) {
      getMediaData(formData.category);
    }
  }, [formData.category, formData.mediaType]);

   useEffect(() => {
    getAllTagsData();
  }, []);

  return (
    <div>
      <SeriesForm
        title={title}
        description={description}
        formData={formData}
        setFormData={setFormData}
        getAllMediaOptions={getAllMediaOptions}
        isDisabled={isDisabled}
        getAllCategoryOptions={getAllCategoryOptions}
        seriesImage={seriesImage}
        setSeriesImage={setSeriesImage}
        MediaType={MediaType}
        mediaType={mediaType}
        
        handleChange={handleChange}
        // handleInput={handleInput}
        handleSubmit={handleSubmit}
        handleDropdownChange={handleDropdownChange}
        setGetAllMediaOptions={setGetAllMediaOptions}
        selectMediaType={selectMediaType}
        handleMediaSelect={handleMediaSelect}
        setMediaDataSelect={setMediaDataSelect}
        mediaDataSelect={mediaDataSelect}
        tagsData={tagsData}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes,Create it!"
          reverseButtons={true}
          onConfirm={()=>{
            getCreateSeries()
            setShowUnPublishAlert()}}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert(false)
            setShowUnPublishConfirm(false)
            history.push('/dashboard/Series/all')
          }}
        >
          Series has been Created
        </SweetAlert>
      ) : null}
    </div>
  )
}

export default CreateSeries
