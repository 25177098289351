export const tagTypes = {
  TAG: "TAG",
  CATEGORY: "CATEGORY",
  SUBCATEGORY: "SUBCATEGORY",
};

export const tagsTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Tag Name",
    dataField: "name",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const categorieTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Categories Name",
    dataField: "name",
  },
  {
    text: "Description",
    dataField: "description",
  },
];

export const subCategoriesTableHeading = [
  {
    text: "S.No.",
    dataField: "sNo",
  },
  {
    text: "Sub Categories Name",
    dataField: "name",
  },
  {
    text: "Description",
    dataField: "description",
  },
];
