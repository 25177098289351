import React, { useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import FileUploader from "../FileUploader";
import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";

const CreateLessonForm = ({
  musicID,
  pageHeading,
  handelInput,
  title,
  objectType,
  description,
  setThumbnail,
  thumbnail,
  video,
  setVideo,
  handleSubmit,
  isError,
  errorMessage,
  errorType,
  showSelectAudio = true,
  isDisabledBtn = false,
  createBtnText = "Create Lesson",
  // setThumbnailColor,
  // thumbnailColor,

  useSameAudio,
  setUseSameAudio,
  setUseSameThumbnail,
  useSameThumbnail,
  prevThumbnail,
}) => {
  const [openColorBox, setopenColorBox] = useState(false);
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row style={{ alignItems: "center" }}>
              <Col xs={10}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
              {/* {pageHeading === "Edit Lesson" && (
                <Col>
                  <Link to={`/dashboard/media/alternate/${musicID}`}>
                    <Button color="primary">Create Alternate</Button>
                  </Link>
                </Col>
              )} */}
            </Row>

            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col >
                  <FormGroup>
                    <Label htmlFor="title">Title</Label>
                    <AvField
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      onChange={handelInput}
                      type="text"
                      errorMessage="Title is required"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <AvField
                  name="description"
                  placeholder="Enter description"
                  value={description}
                  onChange={handelInput}
                  type="textarea"
                  errorMessage="Description is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="description"
                />
              </FormGroup>
              <Row>
                <Col lg={showSelectAudio ? 6 : 12}>
                  {pageHeading === "Alternate Music" && (
                    <div
                      className="custom-control custom-switch mb-2 "
                      dir="ltr"
                      style={{
                        position: "absolute",
                        top: "18px",
                        left: "auto",
                        right: "20px",
                      }}
                    >
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        name="useSameThumbnail"
                        checked={useSameThumbnail}
                        onChange={(e) => setUseSameThumbnail(e.target.checked)}
                        id="useSameThumbnail"
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="useSameThumbnail"
                      >
                        Use Same Thumbnail
                      </Label>
                    </div>
                  )}
                  <FileUploader
                    file={useSameThumbnail ? prevThumbnail : thumbnail}
                    setFile={setThumbnail}
                    dragText="Drop media image here"
                    heading="Select Image"
                    accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
                    // setImageColor={setThumbnailColor}
                    imageColor={thumbnail}
                    isImage
                    isDisabled={useSameThumbnail}
                    imgRatioRangeLow={0.95}
                    imgRatioRangeHigh={1.05}
                    errMsg="Please select square image"
                  />
                </Col>
                {showSelectAudio && (
                  <Col lg={6}>
                    {/* {pageHeading === "Alternate Music" && (
                      <div 
                        className="custom-control custom-switch mb-2 "
                        dir="ltr"
                        style={{
                          position: "absolute",
                          top: "18px",
                          left: "auto",
                          right: "20px",
                        }}
                      >
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          name="useSameAudio"
                          checked={useSameAudio}
                          onChange={(e) => setUseSameAudio(e.target.checked)}
                          id="useSameAudio"
                        />
                        <Label
                          className="custom-control-label"
                          htmlFor="useSameAudio"
                        >
                          Use Same Audio
                        </Label>
                      </div>
                    )} */}

                    <FileUploader
                      file={video}
                      setFile={setVideo}
                      dragText="Drop video here"
                      heading="Select Video"
                      accept=".mp4,video/*"
                      isDisabled={useSameAudio}
                    />
                  </Col>
                )}
              </Row>
              {/* {thumbnailColor && (
                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      onClick={() => setopenColorBox(!openColorBox)}
                      value={thumbnailColor}
                      readOnly
                    />
                    {openColorBox && (
                      <SketchPicker
                        color="#fff"
                        value={thumbnailColor}
                        width="350px"
                        onChangeComplete={(e) => setThumbnailColor(e.hex)}
                      />
                    )}
                  </Col>
                </Row>
              )} */}
              {isError && (
                <Alert color={errorType} role="alert">
                  {errorMessage}
                </Alert>
              )}
              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateLessonForm;
