import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "../Table";
import { useState, useEffect } from "react";

const UserCard = ({
  pageHeading,
  tableHeading = [],
  tableData = [],
  handleAllUsers,
  handleActiveUsers,
  handleInActiveUsers,
  page,
  sizePerPage,
  totalSize,
  handlePageChange,
}) => {
  const [rowData, setRowData] = useState([]);
  
  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function (el, i) {
        var o = Object.assign({}, el);
        o.sNo = i + 1;
        // + page * 10 - 10;
        return o;
      });
      setRowData(result);
    }
    // eslint-disable-next-line
  }, [tableData]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row style={{ justifyContent: "flex-end" }}>
                <Col xs={12} lg={10}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{pageHeading}</h3>
                  </div>
                </Col>
                <Col lg={2}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">Users</Label>
                      <Input
                        name="users"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        onChange={(e) => {
                          e.target.value == "all"
                            ? handleAllUsers({ page: 1, limit: 10 })
                            : e.target.value === "active"
                            ? handleActiveUsers({
                                isDisabled: false,
                                page: 1,
                                limit: 10,
                              })
                            : handleInActiveUsers({
                                isDisabled: true,
                                page: 1,
                                limit: 10,
                              });
                        }}
                        validate={{ required: { value: true } }}
                        id="languageSelect"
                      >
                        <option value="all" selected>
                          All Users
                        </option>
                        <option value="active">Active Users</option>
                        <option value="deactive">Deactive Users</option>
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handlePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserCard;
