import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import NotificationsCard from "../../../../../../Components/NotificationsCard";

import {
  getNotificationBin,
  restoreNotificationReq,
} from "../../Notifications.Apis";

import { notificationHeading } from "../../Notifications.Constants";

const NotificationsBin = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreID, setRestoreID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 20,
  });
  const { currentPage, totalSize } = paginationValue;

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);

  const getData = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getNotificationBin(queryParams);
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setNotifications(data.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getData({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };

  const handleRestoreClick = (id) => {
    setRestoreID(id);
    setShowRestoreAlert(true);
  };

  const restoreNotiAction = async () => {
    await restoreNotificationReq(restoreID);
    const filteredNotifications = notifications.filter(
      ({ _id }) => _id !== restoreID
    );
    setNotifications(filteredNotifications);
    setRestoreID("");
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  return (
    <>
      <NotificationsCard
        openModel={toggleCreateModel}
        tableData={notifications}
        tableHeading={notificationHeading}
        setNotifications={setNotifications}
        handleRestoreNoti={handleRestoreClick}
        allNoti={false}
        handleTablePageChange={handleTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore it!"
          reverseButtons={true}
          onConfirm={restoreNotiAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
          }}
        >
          Notification has been Restored
        </SweetAlert>
      ) : null}
    </>
  );
};

export default NotificationsBin;
