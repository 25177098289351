import axios from "axios";
const domain = "https://back-end-preprod.nispand.com/v2";

export const createDiscoverApi = (payload) => {
  return axios.post(`${domain}/Discover`, payload);
};

export const getAllSeriesPlaylistApi = ({ type, page, limit }) => {
  return axios.get(
    `${domain}/modules/get?type=${type}&page=${page}&limit=${limit}`
  );
};

export const getAllMediaApi = ({ page, limit }) => {
  return axios.get(
    `${domain}/media/publishedMusic?page=${page}&limit=${limit}`
  );
};

export const getPublishedDiscoverNispandAPI = ({ page, limit }) => {
  return axios.get(
    `${domain}/Discover?isDisabled=false&page=${page}&limit=${limit}`
  );
};
export const getUnPublishedDiscoverNispandAPI = ({ page, limit }) => {
  return axios.get(
    `${domain}/Discover?isDisabled=true&page=${page}&limit=${limit}`
  );
};
export const UpdateUnPublishDiscoverNispandAPI = (id) => {
  return axios.delete(`${domain}/Discover/disableDiscover/${id}`);
};
export const UpdatePublishDiscoverNispandAPI = (id) => {
  return axios.delete(`${domain}/Discover/enableDiscover/${id}`);
};

export const getDiscoverNispandByIdApi = (id) => {
  return axios.get(`${domain}/Discover/${id.id}/${id.userRefPath}`);
};
export const updateDiscoverNispandApi = (id, payload) => {
  return axios.put(
    `${domain}/Discover/${id}`,
    payload
  );
};
