import axios from "axios";

import { baseDomain, secureStsCredApi } from "../../../../Constants/apiRoutes";

export const getPublishedLessonsAPI = ({ page, limit }) => {
  // publishedApi
  return axios.get(
    `${baseDomain}/v2/lesson?isDisabled=false&isPublished=true&page=${page}&limit=${limit}`
  );
};
export const getUnPublishedLessonsAPI = ({ page, limit }) => {
  // uppublishedApi

  return axios.get(
    `${baseDomain}/v2/lesson?isDisabled=false&isPublished=false&page=${page}&limit=${limit}`
  );
};
export const UpdateUnPublishLessonAPI = (id) => {
  console.log(id, "6666666666");
  return axios.put(`${baseDomain}/v2/lesson/unpublish/${id}`);
};
export const UpdatePublishLessonAPI = (id) => {
  return axios.put(`${baseDomain}/v2/lesson/publish/${id}`);
};
export const createLessonApi = (payload) => {

  return axios.post(`${baseDomain}/v2/lesson`, payload);
};
export const getLessionbyIdApi = (id) => {
  return axios.get(`${baseDomain}/v2/lesson/${id}`);
};
export const updateLessonApi = (payload) => {

  console.log(payload,"@@@@@@@@@@@@@")
  return axios.put(
    `${baseDomain}/v2/lesson/${payload.idval}`,
    payload.uploadData
  );
};

export const getSecureStsCred = (params) => {
  return axios.get(`${baseDomain}/v2${secureStsCredApi}`, {
    params,
  });
};

export const transCodingApi = (payload) =>{
  return axios.post(`${baseDomain}/v2/media/videoUpload`, payload)
}
