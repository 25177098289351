
import DeletedBLogs from "../../../../../../Components/DeletedBlogs"
import { binBlogTableHeading } from '../../Constants'
import { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from 'react-router-dom'
import { blogBin, restoreBlogreq } from '../../Api'

const BlogsBin = () => {
  const [blogs, setBlogs] = useState([])
  const [restoreAlertBlog, setRestoreALertBlogs] = useState(false)
  const [restoreConfirmBlog, setRestoreConfirmBlog] = useState(false)
  const [restoreBlogID, setRestoreBLogID] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [blogPagination, setBlogPaginaiton] = useState({
    currentPage: 1,
    totalSize: 10,
  })
  const { currentPage, totalSize } = blogPagination
  const history = useHistory()

  const getBlogsBinData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await blogBin(queryParams)
      setBlogPaginaiton({
        currentPage: queryParams.page,
        totalSize: data.count,
      })
      setBlogs(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getBlogsBinData({ currentPage: 1, totalSize: 10 })
  }, [])

  const handleViewBlogClick=(blogID)=>{
    history.push('/dashboard/Blogs/all' + blogID)
  }

  const handleRestoreBlogClick = (blogID) => {
    setRestoreBLogID(blogID)
    setRestoreALertBlogs(true)
  }

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getBlogsBinData({ page, totalSize: sizePerPage })
    setBlogPaginaiton({
      ...blogPagination,
      currentPage: page,
    })
  }

  const restoreBlogAction = async() => {
    await restoreBlogreq(restoreBlogID)
    const filteredBlogs = blogs.filter(({ _id }) => _id !== restoreBlogID)
    setBlogs(filteredBlogs)
    setRestoreBLogID('')
    setRestoreALertBlogs(false)
    setRestoreConfirmBlog(true)
  }

  return(
    <div>
      <DeletedBLogs
        tableHeading={binBlogTableHeading}
        tableData={blogs}
        handleViewBlogs={handleViewBlogClick}
        handleRestoreBlogs={handleRestoreBlogClick}
        handleTablePageChange={handleTablePageChange}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
      />
      {restoreAlertBlog ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Restore Blogs!"
          reverseButtons={true}
          onConfirm={restoreBlogAction}
          onCancel={() => setRestoreALertBlogs(false)}
        ></SweetAlert>
      ) : null}

      {restoreConfirmBlog ? (
        <SweetAlert
          success
          title="Blogs Restored!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setRestoreALertBlogs(false)
            setRestoreConfirmBlog(false)
          }}
        >
          Blogs has been Restored.
        </SweetAlert>
      ) : null}
    </div>
  )

}

export default BlogsBin
