import AllAccounts from "../Components/AllAccounts";
// import ApprovedBusinessUsers from "../Components/ApprovedBusiness";
import CreateUser from "../Components/CreateAccount";
import EditUserAccount from "../Components/EditUserAccount";
// import CreatePlans from "../Components/CreateAccount";
// import PendingBusinessUsers from "../Components/PendingBusiness";
// import RejectedBusinessUsers from "../Components/RejectedBusiness";

export const BusinessRoutes =[
  {
    Component: CreateUser,
    path: "/create-account",
    exact: true,
    id: "create-account",
    routePath: "/dashboard/business/create-account",
    name: "Create Account",
  },
  // {
  //   Component: PendingBusinessUsers,
  //   path: "/all",
  //   exact: true,
  //   id: "dashboardBusinessAll",
  //   routePath: "/dashboard/business/all",
  //   name: "Pending",
  // },
  // {
  //   Component: ApprovedBusinessUsers,
  //   path: "/approved",
  //   exact: true,
  //   id: "dashboardBusinessApproved",
  //   routePath: "/dashboard/business/approved",
  //   name: "Approved",
  // },
  // {
  //   Component: RejectedBusinessUsers,
  //   path: "/rejected",
  //   exact: true,
  //   id: "dashboardBusinessRejected",
  //   routePath: "/dashboard/business/rejected",
  //   name: "Rejected",
  // },
     {
      Component: AllAccounts,
      path: '/accountAll',
      exact: true,
      id: 'dashboardAccountsAll',
      routePath: '/dashboard/business/accountAll',
      name: 'All Accounts'
     },

     {
      Component: EditUserAccount,
      path: '/edit/:userId',
      exact: true,
      id: 'dashboardEditUser',
      routePath: '/dashboard/business/:userId',
      
     }


  
  
  
]