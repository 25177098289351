import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { createNotification } from './CreateNotifications.Api';
import { searchMusic } from '../../Views/Dashboard/Components/Music/Music.Apis';

const CreateNotifications = ({ isOpen, toggleModal, populateNotification }) => {
  const [formData, setFormData] = useState({
    title: '',
    language: 'en',
    description: '',
    audienceType: 'GENERIC',
    objectType: false,
    audioId: '',
  });
  const { title, description, objectType, language, audienceType, audioId } =
    formData;

  const [isCreating, setIsCreating] = useState(false);

  const [search, setSearch] = useState('');
  const [selectedAudio, setSelectedAudio] = useState('');
  const [show, setShow] = useState(false);
  const [currentSearch, setCurrentSearch] = useState([]);

  const select_music = {
    marginTop: '10px',
    position: 'absolute',
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px',
    width: '100%',
    height: '240px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    zIndex: '9',
  };

  const select_search_item = {
    fontSize: '0.9rem',
    fontWeight: '500',
    lineHeight: '1.23',
    textAlign: 'left',
    color: '#505050',
    padding: '1rem 0',
    borderBottom: '1px solid #d3d3d3',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };

  const selector1 = {
    padding: '7.5px',
    boxSizing: 'border-box',
    backgroundColor: '#1cbb8c',
    borderRadius: '50%',
    marginRight: '1rem',
  };

  const selector2 = {
    padding: '7.5px',
    boxSizing: 'border-box',
    backgroundColor: '#fcb92c',
    borderRadius: '50%',
    marginRight: '1rem',
  };

  const selector3 = {
    padding: '7.5px',
    boxSizing: 'border-box',
    backgroundColor: 'red',
    borderRadius: '50%',
    marginRight: '1rem',
  };

  const getSearchData = async (text) => {
    try {
      const { data } = await searchMusic(text);
      setCurrentSearch(data.data);
    } catch (error) {}
  };

  const handleChange = (e) => {
    setSelectedAudio('')
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData(searchBar);
  };

  const handleViewMusicClick = (music) => {
    setFormData({ ...formData, audioId: music._id });
    setSelectedAudio(music.title);
  };

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch('');
    }, 1000);
  };

  const [alert, setAlert] = useState({
    isAlert: false,
    alertType: 'success',
    alertText: 'Created Sucessfully',
  });
  const { isAlert, alertType, alertText } = alert;

  const handelInput = (e) => {
    if (e.target.name === 'objectType')
      return setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setFormData({
      title: '',
      description: '',
      select: '',
      language: 'en',
    });
    setAlert({
      isAlert: false,
      alertType: 'success',
      alertText: 'Created Sucessfully',
    });
    toggleModal();
  };

  const handelCreateNotification = async () => {
    setIsCreating(true);
    try {
      const { data } = await createNotification({
        title,
        language,
        description,
        audienceType,
        objectType: objectType ? 'AUDIO_BASED' : 'TEXT_BASED',
        audioId: audioId,
      });
      setAlert({
        isAlert: true,
        alertType: 'success',
        alertText: 'Created Sucessfully',
      });
      populateNotification(data.data);
      setTimeout(() => {
        setAlert({ isAlert: false });
        setFormData({
          title: '',
          description: '',
          audienceType: 'GENERIC',
          objectType: false,
          audioId: '',
        });
        toggleModal();
        setIsCreating(false);
      }, 1000);
    } catch (error) {
      setAlert({
        isAlert: true,
        alertType: 'danger',
        alertText:
          (error && error.response.data && error.response.data.error) ||
          'Unable to Create',
      });
      setIsCreating(false);
    }
  };

  useEffect(() => {
    search !== '' ? setShow(true) : setShow(false);
  }, [search]);

  return (
    <Modal size='lg' isOpen={isOpen} backdrop='static'>
      <AvForm
        className='needs-validation'
        onValidSubmit={handelCreateNotification}
      >
        <ModalHeader>Create Notification</ModalHeader>
        <ModalBody>
          <Row>
            <Col md='12'>
              <Row>
                <Col md='9'>
                  <FormGroup>
                    <Label htmlFor='title'>Enter Title</Label>
                    <AvField
                      name='title'
                      placeholder='Enter a title'
                      type='text'
                      errorMessage='Please enter a title'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      value={title}
                      onChange={handelInput}
                      id='title'
                      disabled={isCreating}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <div className='custom-control custom-switch' dir='ltr'>
                      <Label htmlFor='notificationSelect'>Language</Label>
                      <Input
                        name='language'
                        placeholder='Select Type'
                        type='select'
                        className='form-control'
                        onChange={handelInput}
                        value={language}
                        validate={{ required: { value: true } }}
                        id='languageSelect'
                        disabled={isCreating}
                      >
                        <option value='en'>ENGLISH</option>
                        <option value='hi'>HINDI</option>
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md='12'>
              <FormGroup>
                <Label htmlFor='description'>Enter Description</Label>
                <AvField
                  name='description'
                  placeholder='Enter a description'
                  type='text'
                  errorMessage='Please enter description'
                  className='form-control'
                  value={description}
                  onChange={handelInput}
                  validate={{ required: { value: true } }}
                  id='description'
                  disabled={isCreating}
                />
              </FormGroup>
            </Col>
            <Col md='12'>
              <FormGroup>
                <Label htmlFor='notificationSelect'>Notification Type</Label>
                <Input
                  name='audienceType'
                  placeholder='Select Type'
                  type='select'
                  className='form-control'
                  value={audienceType}
                  onChange={handelInput}
                  validate={{ required: { value: true } }}
                  id='notificationSelect'
                  disabled={isCreating}
                >
                  <option>GENERIC</option>
                  <option>FREE_TRIAL</option>
                  <option>SUBSCRIPTION_BASED</option>
                  <option>NON_SUBSCRIBED</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md='12'>
              <div className='custom-control custom-switch mb-2 ' dir='ltr'>
                <Input
                  type='checkbox'
                  className='custom-control-input'
                  name='objectType'
                  checked={objectType}
                  onClick={handelInput}
                  id='objectType'
                />
                <Label className='custom-control-label' htmlFor='objectType'>
                  Is Audio Based
                </Label>
              </div>
            </Col>
            {objectType ? (
              <Col md='12'>
                <FormGroup>
                  <Label htmlFor='search'>Select Audio</Label>
                  <div style={{ position: 'relative' }}>
                    <input
                      name='search'
                      value={ selectedAudio ==='' ? search : selectedAudio  }
                      placeholder='Search Media'
                      style={{
                        width: '300px',
                        backgroundColor: 'transparent',
                        outline: 'none',
                        border: 'none',
                        borderBottom: '1px solid black',
                        padding: '5px',
                      }}
                      onChange={handleChange}
                      onBlur={() => hideComponent()}
                      autoComplete='off'
                    />
                    {show === true && (
                      <>
                        <div style={select_music}>
                          {currentSearch && currentSearch.length === 0 ? (
                            <div>Not found</div>
                          ) : (
                            currentSearch &&
                            currentSearch.map((item, i) => {
                              return (
                                <div
                                  style={select_search_item}
                                  key={i}
                                  onClick={() => handleViewMusicClick(item)}
                                >
                                  {item &&
                                    item.isDisabled === false &&
                                    item.isPublished === true && (
                                      <div style={selector1}></div>
                                    )}
                                  {item &&
                                    item.isDisabled === false &&
                                    item.isPublished === false && (
                                      <div style={selector2}></div>
                                    )}
                                  {item &&
                                    item.isDisabled === true &&
                                    item.isPublished === false && (
                                      <div style={selector3}></div>
                                    )}
                                  <div>{item ? item.title : '-'}</div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </FormGroup>
              </Col>
            ) : null}
          </Row>
          {isAlert && (
            <Alert color={alertType} role='alert'>
              {alertText}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type='button'
            onClick={handleClose}
            color='light'
            className='waves-effect'
          >
            Close
          </Button>
          <Button color='primary' type='submit' disabled={isCreating}>
            Add Notification
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default CreateNotifications;
