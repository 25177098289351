import axios from "axios";
import baseDomain, {getAllVideos,createVideo, editVideo, updateVideo} from '../../../../Constants/apiRoutes'

export const getAllYoutubeVideos = () => {
  return axios.get(`${baseDomain}${getAllVideos}`);
}
export const createYoutubeVideos = (data) => {
  return axios.post(`${baseDomain}${createVideo}`,(data));
}
export const editYoutubeVideos = (userID) => {
  return axios.get(`${baseDomain}${editVideo}/${userID}`);
}
export const updateYoutubeVideos = (data,userID) => {
  return axios.put(`${baseDomain}${updateVideo}/${userID}`,(data));
}


