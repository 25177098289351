import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MediaTagsCard from "../../../../../../Components/MediaTagsCard";
import EditMedia from "../../../../../../Modal/EditMedia";
import MediaInfo from "../../../../../../Modal/MediaInfo";
import CreateMediaTag from "../../../../../../Modal/CreateMediaTag";
import {
  tagTypes,
  MappingList,
  RestoreTableHeadingForMapping,
} from "../../Tag.Constants";
import {
  getMediaTags,
  searchMediaTag,
  getTagsUnPublished,
  publishMedia,
  unPublishMedia,
} from "../../Tag.Apis";

const Mapping = () => {

  const [subCount, setSubCount] = useState()
  const [subData, setSubData] =  useState()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTagInfoOpen, setIsTagInfoOpen] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [currentTag, setCurrentTag] = useState(tagTypes.MAPPING);
  const [editSettings, setEditSettings] = useState({
    tagID: "",
    tagArray: [],
  });
  const [deleteSettings, setDeleteSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });

  const [dataTagsPublished, setDataTagsPublished] = useState([]);
  const [dataTagsUnPublished, setDataTagsUnPublished] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([])

  const [pageData, setPageData] = useState({ mappingData: dataTagsPublished });

  const [isLoading, setIsLoading] = useState(false);
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  });
  const [paginationValueUnPublished, setPaginationValueUnPublished] = useState({
    currentPageUnPublished: 1,
    totalSizeUnPublished: 20,
  });

  const { currentPageTags, totalSizeTags } = paginationValueTags;
  const { currentPageUnPublished, totalSizeUnPublished } =
    paginationValueUnPublished;

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentSearch, setCurrentSearch] = useState([]);
  const [tagInfo, setTagInfo] = useState({
    name: "",
    subCategories: "",
    objectType: "",
  });
  useEffect(() => {
    setPageData({
      ...pageData,
      mappingData: dataTagsPublished,
    });
    // eslint-disable-next-line
  }, [dataTagsPublished]);
  useEffect(() => {
    setPageData({
      ...pageData,
      tagsData: dataTagsUnPublished,
    });
    // eslint-disable-next-line
  }, [dataTagsUnPublished]);

  const { mappingData } = pageData;

  const toggleCreateModel = () => setIsCreateModalOpen(!isCreateModalOpen);
  const toggleEditModel = () => setIsEditModalOpen(!isEditModalOpen);
  const toggleTagInfo = () => setIsTagInfoOpen(!isTagInfoOpen);

  // Edit Tags
  const handleEditTagClick = async (id) => {
    setEditSettings({
      tagID: id,
      tagArray: 'mappingData',
    });
    setCurrentTag(tagTypes.MAPPING);
    setIsEditModalOpen(true);
  };

  // Delete Tags
  const handleDeleteTagClick = (id) => {
    setDeleteSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setShowDeleteAlert(true);
  };

  const deleteTagAction = async () => {
    await unPublishMedia(deleteSettings.tagID);
    const filteredTags = pageData[deleteSettings.tagArray].filter(
      ({ _id }) => _id !== deleteSettings.tagID
    );
    setPageData({
      ...pageData,
      [deleteSettings.tagArray]: filteredTags,
    });
    deleteSettings.tagArray === "tagsData" &&
      setDataTagsUnPublished(filteredTags);
    setShowDeleteAlert(false);
    setShowDeleteConfirm(true);
  };

  // Get initial  data
 
  // const[filterCategory, setFilterCategory] = useState([])

  const getSubCatData = async(queryParams) =>{
   try {
    const {data} = await getMediaTags(queryParams)
    setSubData(data?.data)
    setSubCount(data.count)
   } catch (error) {
    console.log(error,'error temp')
   }
  }

  const getMappingData = async ( queryParams )=>{
   setIsLoading(true)
   const { data } = await getMediaTags({...queryParams,objectType:"CATEGORY"});
  //  const res = await getMediaTags({...queryParams,objectType:"SUBCATEGORY", page:1, limit:200});
   setPaginationValueTags({
    currentPageTags: queryParams.page,
    totalSizeTags: data.count,
  })
  let temp = data.data.map(item=>{
    return {
      _id:item._id,
      name:item.name,
      subCategories:item.refIds.map(ele=>{
      return subData?.find(sub=>sub._id===ele)
      
    }
    ),
    // filterData: res.data.data.filter(ele=>item.refIds.includes(ele._id))
   
  }
  
  })
  // setFilterCategory(res.data.data.filter(ele=>item.refIds.includes(ele._id)))
  setDataTagsPublished(temp);
  setIsLoading(false)
  }
  useEffect(() => {
    if (subData) {
      getMappingData({ page: 1, limit: 10 });
    }
    // eslint-disable-next-line
  }, [subData]);

  useEffect(()=>{
    getSubCatData({objectType:"SUBCATEGORY", page:1, limit:subCount})
  },[ subCount])

  const handleTablePageChangePublishedTags = (type, { page, sizePerPage }) => {
    getMappingData({ objectType: "CATEGORY", page, limit: sizePerPage });
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageTags: page,
    });
  };
  // -------------------------------------------
  // getTagsUnPublishedData
  const getTagsUnPublishedData = async (queryParams) => {
    setIsLoading(true)
   const { data } = await getTagsUnPublished({...queryParams,objectType:"CATEGORY"});
   const res1 = await getMediaTags({...queryParams,objectType:"SUBCATEGORY" ,page:1, limit:200});
   const res2 = await getTagsUnPublished({...queryParams,objectType:"SUBCATEGORY",page:1, limit:73});

   const res = [...res1.data.data, ...res2.data.data]
   setPaginationValueUnPublished({
        currentPageUnPublished: queryParams.page,
        totalSizeUnPublished: data.count,
      });

  let temp = data.data.map(item=>{
    return {
      _id:item?._id,
      name:item?.name,
      subCategories:item?.refIds.map(ele=>{
     
       return res?.find(sub=> sub._id===ele)
    })}
  })
  setDataTagsUnPublished(temp);
  setIsLoading(false)
  };
  
 
  useEffect(() => {
    getTagsUnPublishedData({ page:1,limit:10 });
    // eslint-disable-next-line
  }, []);

  const handleTablePageChangeUnPublishedTags = (
    type,
    { page, sizePerPage }
  ) => {
    getTagsUnPublishedData({ objectType: "CATEGORY", page, limit: sizePerPage });
    setPaginationValueUnPublished({
      ...paginationValueUnPublished,
      currentPageUnPublished: page,
    });
  };

  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreSettings, setRestoreSettings] = useState({
    tagID: "",
    tagArray: "tagsData",
  });
  const handleRestoreTagClick = (id) => {
    setRestoreSettings({
      tagID: id,
      tagArray: "tagsData",
    });
    setShowRestoreAlert(true);
  };

  const restoreTagAction = async () => {
    await publishMedia(restoreSettings.tagID);
    const filteredTags = pageData[restoreSettings.tagArray].filter(
      ({ _id }) => _id !== restoreSettings.tagID
    );
    setPageData({
      ...pageData,
      [restoreSettings.tagArray]: filteredTags,
    });
    restoreSettings.tagArray === "tagsData" &&
      setDataTagsPublished(filteredTags);
    setShowRestoreAlert(false);
    setShowRestoreConfirm(true);
  };

  // Get initial  data

  const getSearchData = async (text) => {
    try {
      const { data } = await searchMediaTag(text);
      data.data ? setCurrentSearch(data.data) : setCurrentSearch([]);
    } catch (error) {
      setCurrentSearch([]);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    let searchBar = e.target.value;
    getSearchData({ objectType: "CATEGORY", searchBar });
  };

  useEffect(() => {
    search !== "" ? setShow(true) : setShow(false);
  }, [search]);

  const hideComponent = () => {
    setTimeout(() => {
      setShow(false);
      setSearch("");
    }, 1000);
  };

  const handleTagInfo = (item) => {
    setTagInfo({
      name: item.name,
      subCategories: item.refIds,
      // subCategories:filterCategory.map((item)=>{return item.name}).join(", "),
      objectType: item.objectType,
    });
    toggleTagInfo();
  };

  // Populate created Media
  const populateCreatedTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.MAPPING:
        setPageData({
          ...pageData,
          mappingData: [tag, ...mappingData],
        });
        setDataTagsPublished([tag, ...mappingData]);
        break;
      default:
        break;
    }
  };

  // Populate created Media
  const populateUpdatededTag = ({ tag, tagType }) => {
    switch (tagType) {
      case tagTypes.MAPPING:
        setPageData({
          ...pageData,
          mappingData: [tag, ...mappingData.filter(({ _id }) => _id !== tag._id)],
        });
        setDataTagsPublished([
          tag,
          ...mappingData.filter(({ _id }) => _id !== tag._id),
        ]);
        break;
      default:
        break;
    }
  };

  // Handle Models
  const openCreateTagModel = () => {
    setCurrentTag(tagTypes.MAPPING);
    toggleCreateModel();
  };
  const select_tag = {
    marginTop: "10px",
    position: "absolute",
    backgroundColor: "#fff",
    padding: "0px 20px 20px 20px",
    width: "100%",
    height: "240px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    zIndex: "9",
  };

  const select_search_item = {
    fontSize: "0.9rem",
    fontWeight: "500",
    lineHeight: "1.23",
    textAlign: "left",
    color: "#505050",
    padding: "1rem 0",
    borderBottom: "1px solid #d3d3d3",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  };


  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Mapping Data</h2>
        <div style={{ position: "relative" }}>
          <input
            name="search"
            value={search}
            placeholder="Search"
            style={{
              width: "300px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "1px solid black",
              padding: "5px",
            }}
            onChange={handleChange}
            onBlur={hideComponent}
            autoComplete="off"
          />
          {show === true && (
            <>
              <div style={select_tag}>
                {currentSearch && currentSearch.length === 0 ? (
                  <div>Not found</div>
                ) : (
                  currentSearch &&
                  currentSearch.map((item, i) => {
                    return (
                      <div
                        style={select_search_item}
                        key={i}
                        onClick={() => handleTagInfo(item)}
                      >
                        <div>{item ? item.name : "-"}</div>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <MediaTagsCard
        heading="Published Mapping"
        openModel={openCreateTagModel}
        modelBtnText="Create Mapping"
        tableData={dataTagsPublished}
        tableHeading={MappingList}
        handleEditTag={handleEditTagClick}
        handleDeleteTag={handleDeleteTagClick}
        handleTablePageChange={handleTablePageChangePublishedTags}
        page={currentPageTags}
        sizePerPage={10}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        allTags={true}
        allMapping={true}
      />
      <MediaTagsCard
        heading="Unpublished Mapping"
        tableData={dataTagsUnPublished}
        tableHeading={RestoreTableHeadingForMapping}
        handleRestoreTag={handleRestoreTagClick}
        handleTablePageChange={handleTablePageChangeUnPublishedTags}
        page={currentPageUnPublished}
        sizePerPage={10}
        totalSize={totalSizeUnPublished}
        isLoading={isLoading}
        allTags={false}
      />

      <MediaInfo
        name={tagInfo.name}
        description={tagInfo.subCategories}
        objectType={tagInfo.objectType}
        isTagInfoOpen={isTagInfoOpen}
        toggleTagInfoModal={toggleTagInfo}
        mapping={true}
      />

      <CreateMediaTag
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        currentTag={currentTag}
        populateTag={populateCreatedTag}
        mediaDropdown={false}
      />

      <EditMedia
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModel}
        currentTag={currentTag}
        currentTagID={editSettings.tagID}
        currentArray={pageData[editSettings.tagArray]}
        populateTag={populateUpdatededTag}
        setIsEditModalOpen={setIsEditModalOpen}
        isEditModalOpen={isEditModalOpen}
        mediaDropdown={false}
        mappingDropDown={true}
      />

      {showDeleteAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Unpublish it!"
          reverseButtons={true}
          onConfirm={deleteTagAction}
          onCancel={() => setShowDeleteAlert(false)}
        ></SweetAlert>
      ) : null}

      {showDeleteConfirm ? (
        <SweetAlert
          success
          title="Unpublished!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowDeleteAlert(false);
            setShowDeleteConfirm(false);
            window.location.reload();
          }}

        >
          File has been Unpublished
        </SweetAlert>
      ) : null}

      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Publish it!"
          reverseButtons={true}
          onConfirm={restoreTagAction}
          onCancel={() => setShowRestoreAlert(false)}
        ></SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Published!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
            window.location.reload();
          }}
        >
          File has been Published
        </SweetAlert>
      ) : null}
    </>
  );
};

export default Mapping;
