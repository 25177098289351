import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
  Alert,
  Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";

import FileUploader from "../FileUploader";
import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";
import { getMediaMapping } from "../../Views/Dashboard/Components/MediaProperties/Tag.Apis";

const MediaMappingForm = ({
  musicID,
  pageHeading = "Create Media Mapping",
  handelInputCategory,
  language,
  subCategorySelect,
  subCategoriesData,
  handleSubCategorySelect,
  handleSubmit,
  isError,
  errorMessage,
  errorType,
  isDisabledBtn = false,
  createBtnText = "Create Media",
  setThumbnailColor,
  thumbnailColor,
  categoryData,
  setCreated
}) => {
  const [openColorBox, setopenColorBox] = useState(false);


  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row style={{ alignItems: "center" }}>
              <Col xs={10}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{pageHeading}</h3>
                </div>
              </Col>
              {pageHeading === "Edit Media" && (
                <Col>
                  <Link to={`/dashboard/media/alternate/${musicID}`}>
                    <Button color="primary">Create Alternate</Button>
                  </Link>
                </Col>
              )}
            </Row>

            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col ls={4}>
                  <FormGroup>
                    <div dir="ltr">
                      <Label htmlFor="notificationSelect">Category</Label>
                      <Input
                        name="Category"
                        placeholder="Select Type"
                        type="select"
                        className="form-control"
                        value={language}
                        onChange={handelInputCategory}
                        validate={{ required: { value: true } }}
                        id="categorySelect"
                      >
                        <option value="" disabled selected>
                          Select Category
                        </option>
                        {categoryData.map((items, index) => (
                          <option key={index} value={items._id}>
                            {items.name}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </FormGroup>
                </Col>

                <Col ls={4}>
                  <FormGroup>
                    <Label htmlFor="bpmSelect">SubCategory</Label>
                    <Select
                      style={{height: "500px"}}
                      value={subCategorySelect}
                      isMulti={true}
                      onChange={handleSubCategorySelect}
                      options={subCategoriesData}
                      classNamePrefix="select2-selection"
                      placeholder={
                        subCategoriesData && subCategoriesData.length === 0
                          ? "Loading..."
                          : "Select SubCategory"
                      }
                      isDisabled={pageHeading === "Alternate Media"}
                    />
                  </FormGroup>
                 
                </Col>
              </Row>

              {thumbnailColor && (
                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      onClick={() => setopenColorBox(!openColorBox)}
                      value={thumbnailColor}
                      readOnly
                    />
                    {openColorBox && (
                      <SketchPicker
                        color="#fff"
                        value={thumbnailColor}
                        width="350px"
                        onChangeComplete={(e) => setThumbnailColor(e.hex)}
                      />
                    )}
                  </Col>
                </Row>
              )}

              {isError && (
                <Alert color={errorType} role="alert">
                  {errorMessage}
                </Alert>
              )}

              <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={isDisabledBtn}
                onClick={()=>{setCreated(true)}}
              >
                {createBtnText}
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MediaMappingForm;
