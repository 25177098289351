import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { AdvisorsRoutes } from './Constants/routes';

const AdvisorsAll = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
      {AdvisorsRoutes.map(({ path, Component, exact, id }) => (
           <Route
             path={`${match.url}${path}`}
             exact={exact}
             component={Component}
             key={id}
           />
         ))}
         <Redirect to="/dashboard/advisor" />
      </Switch>
    </>
  )
}

export default AdvisorsAll;