import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from 'reactstrap';
import DeletedPlans from '../../../../../Components/DeletedPlans';
import { restorePlan } from '../../../../../Modal/ViewPlan/ViewPlan.Api';
import { planBinReq } from '../Plans.Apis';
import { PlanBinTableHeading } from '../Plans.Constants';

const PlansBin = () => {

  const [binData, setBinData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [showRestoreAlert, setShowRestoreAlert] = useState(false);
  const [showRestoreConfirm, setShowRestoreConfirm] = useState(false);
  const [restoreID, setRestoreID] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
  });
  const { currentPage, totalSize } = paginationValue;

  const getPlanBin = async (queryParams) => {
    setIsLoading(true)
    const { data } = await planBinReq(queryParams);
    setPaginationValue({
      currentPage: queryParams.page,
      totalSize: data.count,
    });
    setBinData(data.data)
    setIsLoading(false)
  }

  useEffect(() => {
    getPlanBin({ page: 1, limit: 10 })
    // eslint-disable-next-line
  }, [])

  const handleRestorePlan = (id) => {
    setRestoreID(id);
    setShowRestoreAlert(true);
  }
  const restoreTagAction = async () => {
    try {
      await restorePlan(restoreID)
      setRestoreID("");
      const filteredPlans = binData.filter(({ _id }) => _id !== restoreID);
      setBinData(filteredPlans);
      setShowRestoreAlert(false);
      setShowRestoreConfirm(true);
    } catch (error) { }
  }

  const restorePlanFormatter = (cell, row) => {
    return (

      <div style={{ textAlign: "center" }} key={row._id}>
        <Button
          color="success"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => handleRestorePlan(row._id)}>Activate</Button>
      </div>
    );
  };

  const handlePlanBinTablePageChange = (type, { page, sizePerPage }) => {
    getPlanBin({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };


  useEffect(() => {
    PlanBinTableHeading.push({
      text: "Activate Plans",
      dataField: "_id",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: restorePlanFormatter,
    });
    return () => {
      PlanBinTableHeading.pop();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DeletedPlans
        tableData={binData}
        tableHeading={PlanBinTableHeading}
        handleTablePageChange={handlePlanBinTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />
      {showRestoreAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Activate it!"
          reverseButtons={true}
          onConfirm={restoreTagAction}
          onCancel={() => setShowRestoreAlert(false)}
        >

        </SweetAlert>
      ) : null}

      {showRestoreConfirm ? (
        <SweetAlert
          success
          title="Activated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowRestoreAlert(false);
            setShowRestoreConfirm(false);
          }}
        >
          Plan has been Restored
        </SweetAlert>
      ) : null}
    </>
  )
}

export default PlansBin;