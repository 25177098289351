import axios from "axios";

import baseDomain, {
  userApi,
  userDisableAPI,
  userEnableAPI,
} from "../../Constants/apiRoutes";

export const getUserReq = (userID) => {
  return axios.get(`${baseDomain}${userApi}/${userID}`);
};

export const EnableUserReq = (userID) => {
  return axios.put(`${baseDomain}${userEnableAPI}/${userID}`);
};

export const DisableUserReq = (userID) => {
  return axios.put(`${baseDomain}${userDisableAPI}/${userID}`);
};
