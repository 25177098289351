import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";

import AuthContext from "../../context/auth/authContext";
import Loader from "../../Components/Loader";
import { getUserReq, EnableUserReq, DisableUserReq } from "./UserInfo.Apis";

const UserInfo = ({ isUserInfoOpen, toggleUserInfoModal, userId }) => {
  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [userSubscriptionData, setUserSubscriptionData] = useState(null);

  const handleDisableUser = async () => {
    try {
      if (userId === authContext.user._id) return;
      if (userData.isDisabled) {
        await EnableUserReq(userId);
      } else {
        await DisableUserReq(userId);
      }
      setUserData({
        ...userData,
        isDisabled: !userData.isDisabled,
      });
    } catch (error) {
      console.error("Problem While user action");
    }
  };

  const fetchUserDate = async (userId) => {
    try {
      setIsLoading(true);
      const { data } = await getUserReq(userId);
      setUserData(data.data.user);
      setUserSubscriptionData(data.data.subscription);
      setIsLoading(false);
    } catch (error) {
      setUserData({});
      setUserSubscriptionData({});
    }
  };

  useEffect(() => {
    fetchUserDate(userId);
  }, [userId]);

  return (
    <Modal size="xl" isOpen={isUserInfoOpen} backdrop="static">
      <ModalHeader onClose={toggleUserInfoModal}>User Information</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Is Subscribed</Label>
                  <Input
                    type="text"
                    value={userSubscriptionData ? "Yes" : "No"}
                    disabled={true}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup
                  style={{ height: "100%" }}
                  className="d-flex align-items-center justify-content-between pt-1"
                >
                  <Label className="p-0 m-0">Toggle User</Label>
                  <div className="custom-control custom-switch ml-3" dir="ltr">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      checked={userData.isDisabled}
                      id="toggleUser"
                      onClick={handleDisableUser}
                    ></Input>
                    <Label
                      className="custom-control-label"
                      htmlFor="toggleUser"
                    ></Label>
                  </div>
                </FormGroup>
              </Col>
              {userData.lastActiveDate && (
                <Col md="4">
                  <FormGroup>
                    <Label>Last Active</Label>
                    <Input
                      type="text"
                      value={
                        moment(userData.lastActiveDate).format("DD/MM/YYYY") ||
                        "-"
                      }
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col className="pt-3 pb-2" md="12">
                <h5>User Data</h5>
              </Col>

              {userData.firstName && (
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="firstName">Firstname</Label>
                    <Input
                      name="firstName"
                      type="text"
                      value={userData.firstName || "-"}
                      id="firstName"
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.lastName && (
                <Col md="6">
                  <FormGroup>
                    <Label>Lastname</Label>
                    <Input
                      type="text"
                      value={userData.lastName || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.email && (
                <Col md="6">
                  <FormGroup>
                    <Label>Email Id</Label>
                    <Input
                      type="text"
                      value={userData.email || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.phoneNumber && (
                <Col md="6">
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input
                      type="number"
                      value={userData.phoneNumber || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.role && (
                <Col md="4">
                  <FormGroup>
                    <Label>Role</Label>
                    <Input
                      type="text"
                      value={userData.role || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.maritalStatus && (
                <Col md="4">
                  <FormGroup>
                    <Label>Marital Status</Label>
                    <Input
                      type="text"
                      value={userData.maritalStatus || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.country && (
                <Col md="4">
                  <FormGroup>
                    <Label>Country</Label>
                    <Input
                      type="text"
                      value={userData.country}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.BPM && (
                <Col md="4">
                  <FormGroup>
                    <Label>BPM</Label>
                    <Input
                      type="number"
                      value={userData.BPM || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.stressLevel && (
                <Col md="4">
                  <FormGroup>
                    <Label>Stress Level</Label>
                    <Input
                      type="number"
                      value={userData.stressLevel || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.weight && (
                <Col md="4">
                  <FormGroup>
                    <Label>Weight</Label>
                    <Input
                      type="number"
                      value={userData.weight || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData && userData.age > 0 && (
                <Col md="4">
                  <FormGroup>
                    <Label>Age</Label>
                    <Input
                      type="number"
                      value={userData.age || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {userData.dob && (
                <Col md="4">
                  <FormGroup>
                    <Label>Date of Birth</Label>
                    <Input
                      type="text"
                      value={moment(userData.dob).format("DD/MM/YYYY") || "-"}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>

            {userSubscriptionData && (
              <div className="py-3">
                <h5>Subscription Data</h5>
                <Row className="py-2">
                  <Col md="4">
                    <FormGroup>
                      <Label>Is Free</Label>
                      <Input
                        type="text"
                        value={userSubscriptionData.amountPaid ? "No" : "Yes"}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  {userSubscriptionData.plan_description && (
                    <Col md="4">
                      <FormGroup>
                        <Label>Plan Name</Label>
                        <Input
                          type="text"
                          value={userSubscriptionData.plan_description}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {userSubscriptionData.plan_duration_indays && (
                    <Col md="4">
                      <FormGroup>
                        <Label>Duration (Days)</Label>
                        <Input
                          type="text"
                          value={userSubscriptionData.plan_duration_indays}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {
                    <Col md="4">
                      <FormGroup>
                        <Label>Amount Paid</Label>
                        <Input
                          type="text"
                          value={userSubscriptionData.amountPaid || "0"}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                  }

                  <Col md="4">
                    <FormGroup>
                      <Label>Pack Amount</Label>
                      <Input
                        type="text"
                        value={userSubscriptionData.packAmount || "0"}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <Label>Gst Amount</Label>
                      <Input
                        type="text"
                        value={userSubscriptionData.gstAmount || "0"}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={toggleUserInfoModal}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserInfo;
