import axios from "axios";
import { stories, baseDomain, stage } from "../../../../Constants/apiRoutes";
import { publishMusicAPI,unpublishMusicAPI } from "../../../../Constants/apiRoutes";


export const createStoryReq = (payload) => {
  return axios.post(`${baseDomain}${stage}${stories}`, payload);
};

export const getPublishedStoryApi = ({ page, limit }) => {
  return axios.get(`${baseDomain}${stage}${stories}?isPublished=true&page=${page}&limit=${limit}`);
};

export const getUnPublishedStoryApi = ({ page, limit }) => {
  return axios.get(`${baseDomain}${stage}${stories}?isPublished=false&page=${page}&limit=${limit}`);
};

export const getStoryById = (id) => {
  return axios.get(`${baseDomain}${stage}${stories}/${id}`);
};

export const updateStory = (storyId, payload) => {
  return axios.put(`${baseDomain}${stage}${stories}/${storyId}`, payload);
};

export const publishStory = (storyId) => {
  return axios.put(`${baseDomain}${stage}${publishMusicAPI}/${storyId}`);
};

export const unpublishStory = (storyId) => {
  return axios.put(`${baseDomain}${stage}${unpublishMusicAPI}/${storyId}`);
};
