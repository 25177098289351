import React from "react";

const Loader = () => {
  return (
    <div className="spinner spinner-dashboard p-4">
      <i className="ri-loader-line spin-icon"></i>
    </div>
  );
};

export default Loader;
